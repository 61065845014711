import React from 'react';
import './styles.css';

import Header from './Header';
import ActivityGrid from './ActivityGrid';
import Members from './Members';
import CounterWithContactForm from 'components/common/CountersWithContactForm';
import DumpThroughYears from './DumpThroughYears';

const About = () => {
  return (
    <div>
      <Header />
      <DumpThroughYears />
      <ActivityGrid />
      <Members />
      <CounterWithContactForm />
    </div>
  );
};

export default About;

import styled from 'styled-components';

export default styled.div`
  display: flex;
  position: relative;
  margin: 50px 0;
  min-height: 10px;
  width: 100%;

  & .control-section {
    display: none;
  }

  &:hover .control-section {
    display: block;
  }

  .project-section__title {
    padding: 30px 0;
  }

  & > .project-section__text h2 {
    font-size: 42px;
    margin: 0 0 18px 0;
    color: #282d30;

    @media screen and (max-width: 400px) {
      font-size: 38px;
    }

    @media screen and (max-width: 350px) {
      font-size: 34px;
    }
  }

  & > .project-section__text p {
    font-size: 16px;
    margin: 4px 0;
    line-height: 24px;
    color: #a6b0b1;
  }

  & > .project-section__text a {
    color: #5cc2a0;
  }

  & > .project-section__text ul {
    font-size: 16px;
    line-height: 32px;
    color: #a6b0b1;
  }

  & img {
    width: 100%;
  }

  ${({ visibility }) => visibility && `visibility: ${visibility};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ isExpanded, isEditor }) => !isExpanded && isEditor && `justify-content: center;`}

  ${({ isSingleTextEditor, justifyContent }) =>
    isSingleTextEditor &&
    `& > .project-section__text {
      max-width: 66%;
      ${justifyContent === 'flex-end' ? 'text-align: right;' : ''}

      @media screen and (max-width: 500px) {
        max-width: 90%;
        margin: 0 auto;
      }

      @media screen and (min-width: 1000px) {
        max-width: 40%;
      }
    }
  `}

  ${({ justifyContent, isTripleTextEditor }) =>
    // OnlyImageCenter text
    justifyContent === 'center' &&
    !isTripleTextEditor &&
    `
    & > div:last-child p,
    & > .project-section__text h2 {
      text-align: center;
    }
  `}

  ${({ isTripleTextEditor }) =>
    // Section with multiple text editors
    isTripleTextEditor &&
    `
    & > .project-section__text,
    & > .editor__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }

    & > .project-section__text .project-section__text__wrapper,
    & > .editor__wrapper .project-section__text__wrapper {
      width: calc(33% - 80px);
      margin: 0 40px;

      @media screen and (max-width: 1200px) {
        width: calc(50% - 80px);
      }

      @media screen and (max-width: 800px) {
        width: calc(100% - 160px);
        margin: 0 80px;
      }

      @media screen and (max-width: 600px) {
        width: calc(100% - 80px);
        margin: 0 40px;
      }

      @media screen and (max-width: 500px) {
        width: calc(100% - 40px);
        margin: 0 20px;
      }
    }
  `}

  ${({ hasImageAndText, flexDirection }) =>
    // ImageLeftTextRight && ImageRightTextLeft text margins
    hasImageAndText &&
    `
    ${
      flexDirection === 'row-reverse'
        ? `
        
        justify-content: space-between;

        & .project-section__text {
        margin-left: 100px;

          @media screen and (max-width: 1200px) {
            margin-left: 50px; 
          }

          @media screen and (max-width: 1000px) {
            margin-left: 25px; 
          }

          @media screen and (max-width: 768px) {
            margin-left: 0; 
          }

          @media screen and (max-width: 500px) {
            max-width: 90%;
            margin: 0 auto;
          }

          @media screen and (min-width: 1440px) {
            max-width: 60%
          }
        }`
        : `
        
        justify-content: space-between;
        
        & .project-section__text {
          margin-right: 100px;

          @media screen and (max-width: 1200px) {
            margin-right: 50px; 
          }

          @media screen and (max-width: 1000px) {
            margin-right: 25px; 
          }

          @media screen and (max-width: 768px) {
            margin-right: 0; 
          }

          @media screen and (max-width: 500px) {
            max-width: 90%;
            margin: 0 auto;
          }

          @media screen and (min-width: 1440px) {
            max-width: 60%
          }
        }`
    }
  `}

  ${({ hasImageAndText }) =>
    hasImageAndText &&
    `
    align-items: center;

    & .project-section__text,
    & .project-section__image__wrapper {
      width: fit-content;
    }

    & .project-section__text__wrapper {
      margin: 0 0 30px 0;
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;

      & .project-section__text,
      & .project-section__image__wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  `}

  ${({ isImageOnly }) =>
    isImageOnly &&
    `width: 100%;
  
    & .project-section__image__wrapper {
      width: 100%;
    }`}

  ${({ isImageGallery }) =>
    isImageGallery &&
    `
    & .project-section__image__wrapper {
      margin-right: 12px;
      width: auto;
      display: flex;
      align-items: center;
    }

    & div:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;

      & .project-section__image__wrapper {
        margin-right: auto;
        margin-left: auto;
      }

      & div:last-of-type {
        margin-right: auto;
      }
    }`
  }
  
  ${({ isEditor }) =>
    isEditor &&
    `
      width: 100%;
      margin: 10px 0;
    `}

  ${({ isSorting }) => {
    // Styles for sorting while dragging -- only in editor mode
    if (isSorting)
      return `
      & > button {
        opacity: 0;
      }

      & > div:not(.droppable-section) { 
        opacity: 0;
      }

      & > .droppable-section {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        justify-content: space-between;
        align-items: center;
        font-size: 19px;
      }
      
      & > .droppable-section > div {
        height: 50%;
        color: #D8D8D8;
        width: 100%;
        border: 2px dashed #D8D8D8;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          border: 2px dashed #000;
          color: #000;
        }
      }
    `;
    
    return `& > .droppable-section {
        display: none;
      }`;
  }}
`;

import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  margin: 0 112px;
  padding-top: 46px;
  padding-bottom: 312px;
  justify-content: space-between;

  @media screen and (max-width: 1475px) {
    margin: 0 132px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 200px;
  }

  @media screen and (max-width: 767px) {
    margin: 0 24px;
  }
`;

export const StyledTextContainer = styled.div`
  max-width: 592px;
  margin-top: 32px;
  margin-right: 32px;
  margin-left: 104px;

  @media screen and (max-width: 1475px) {
    margin-left: auto;
  }

  @media screen and (max-width: 1350px) {
    margin-left: auto;
    max-width: 500px;
  }

  @media screen and (max-width: 1260px) {
    max-width: 400px;
  }

  @media screen and (max-width: 1050px) {
    max-width: 360px;
  }

  @media screen and (max-width: 900px) {
    max-width: 400px;
    margin-right: auto;
    margin-bottom: 88px;
  }
`;

export const StyledTitle = styled.h1`
  font-family: 'Fuse V.2 Display';
  color: #282d30;
  font-size: 112px;
  line-height: 112px;
  margin-top: 0;
  margin-bottom: 24px;

  @media screen and (max-width: 1350px) {
    font-size: 96px;
    line-height: 104px;
  }

  @media screen and (max-width: 1260px) {
    font-size: 72px;
    line-height: 80px;
  }

  @media screen and (max-width: 1050px) {
    font-size: 64px;
    line-height: 72px;
  }

  @media screen and (max-width: 900px) {
    font-size: 56px;
    line-height: 60px;
  }

  @media screen and (max-width: 330px) {
    font-size: 46px;
    line-height: 50px;
  }
`;

export const StyledSubtitle = styled.p`
  font-family: 'Fuse V.2 Display';
  color: #282d30;
  font-size: 16px;
  line-height: 24px;
  max-width: 384px;
  margin-top: 0;
  margin-bottom: 40px;

  @media screen and (max-width: 900px) {
    max-width: unset;
    margin-bottom: 36px;
  }
`;

export const StyledButton = styled.button`
  background-color: #44c79b;
  color: white;
  padding: 14px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: 'Fuse V.2 Display';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;

  @media screen and (max-width: 900px) {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

export const StyledImage = styled.img`
  @media screen and (max-width: 1260px) {
    width: 388px;
    height: 388px;
  }

  @media screen and (max-width: 1050px) {
    width: 50%;
    height: auto;
    flex-shrink: 0;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

import React from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import App from './App';
import './globalStyles.css';
import BrowserHistoryWrapper from './utils/BrowserHistoryWrapper';

import { configureAxios } from 'services/common';

configureAxios();

ReactDOM.render(
  <BrowserHistoryWrapper>
    <DndProvider backend={Backend}>
      <App />
    </DndProvider>
  </BrowserHistoryWrapper>,
  document.getElementById('root'),
);

import React from 'react';

import {
  StyledContainer,
  StyledTitle,
  StyledSubtitle,
  StyledTitleWrapper,
  StyledSupportersWrapper,
  StyledLogoLink,
  StyledLogo,
  StyledLogoWithMarginTop,
} from './index.styled';
import { StyledSpacing } from '../index.styled';

import blank from 'assets/visuals/internship/supporters/blank.png';
import dobartek from 'assets/visuals/internship/supporters/dobartek.png';
import five from 'assets/visuals/internship/supporters/five.png';
import netmedia from 'assets/visuals/internship/supporters/netmedia.png';
import extensionengine from 'assets/visuals/internship/supporters/extensionengine.png';
import profico from 'assets/visuals/internship/supporters/profico.png';
import fundamento from 'assets/visuals/internship/supporters/fundamento.png';
import seekAndHit from 'assets/visuals/internship/supporters/seek-and-hit.png';
import FESB from 'assets/visuals/internship/supporters/FESB.png';
import universityOfSplit from 'assets/visuals/internship/supporters/sveuciliste-u-splitu.png';
import SZST from 'assets/visuals/internship/supporters/SZST.png';
import mkc from 'assets/visuals/internship/supporters/mkc.png';

const ReasonsToBecomeAMember = () => {
  return (
    <StyledSpacing>
      <StyledContainer>
        <StyledTitleWrapper>
          <StyledTitle>Podržavaju nas</StyledTitle>
          <StyledSubtitle>
            Kroz gostujuća predavanja, radionice i job shadowing imaš priliku zaviriti u poslovni svijet te se
            informirati o tome koje tvrtke djeluju u lokalnoj IT zajednici, na kakvim projektima rade i koji su uvjeti
            za zaposlenje kod njih.
          </StyledSubtitle>
        </StyledTitleWrapper>
        <StyledSupportersWrapper>
          <StyledLogoLink target="_blank" href="https://blankhq.co/">
            <StyledLogo src={blank} alt="Blank" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://www.dobartek.hr/">
            <StyledLogo src={dobartek} alt="dobartek.hr" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://extensionengine.com/">
            <StyledLogo src={extensionengine} alt="Extension Engine" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://fundamen.to/">
            <StyledLogo src={fundamento} alt="Fundamento" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://netmedia.hr/">
            <StyledLogo src={netmedia} alt="Netmedia" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://five.agency/">
            <StyledLogo src={five} alt="FIVE" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://profi.co/">
            <StyledLogo src={profico} alt="Profico" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://seekandhit.com/hr/">
            <StyledLogo src={seekAndHit} alt="Seek and Hit" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://www.fesb.unist.hr/">
            <StyledLogoWithMarginTop margin="38px" src={FESB} alt="FESB" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="https://www.unist.hr/">
            <StyledLogoWithMarginTop margin="28px" src={universityOfSplit} alt="Sveučilište u Splitu" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="http://www.szst.unist.hr/">
            <StyledLogoWithMarginTop margin="38px" src={SZST} alt="Studentski zbor sveučilišta u Splitu" />
          </StyledLogoLink>
          <StyledLogoLink target="_blank" href="http://mkcsplit.hr/">
            <StyledLogoWithMarginTop src={mkc} alt="Dom Mladih Split" />
           </StyledLogoLink>
        </StyledSupportersWrapper>
      </StyledContainer>
    </StyledSpacing>
  );
};

export default ReasonsToBecomeAMember;

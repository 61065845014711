import React from 'react';
import styled from 'styled-components';
import TextUnderline from 'components/styled/TextUnderline';

import shapeGreenSmall from 'assets/shapes/shape_green-small.png';
import shapeBlueSmall from 'assets/shapes/shape_blue-small.png';

const ProjectsInfoContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 140px;

  @media screen and (max-width: 1200px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: 100px;
  }
`;

const ProjectsInfo = () => {
  return (
    <ProjectsInfoContainer>
      <h2 className="heading--tiny marginless">Projekti</h2>
      <h2 className="heading--normal projects__heading">
        Radimo na&nbsp;
        <TextUnderline color="#5b6cc2" underlineColor="#5b6cc233" lineHeight="0.85">
          cool
        </TextUnderline>
        &nbsp;projektima.
      </h2>
      <p className="text__description project-info__description marginless">
        Mi smo udruga pokrenuta iz ljubavi prema tehnologiji. Najviše nas veseli rad s cutting edge tehnologijama,
        modernim hardverom i raznovrsnim industrijskim alatima.
      </p>
      <img className="shape__top-left" src={shapeGreenSmall} alt="Small green shape illustration" />
      <img className="shape__bottom-right" src={shapeBlueSmall} alt="Green shape illustration" />
    </ProjectsInfoContainer>
  );
};

export default ProjectsInfo;

import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { StyledCardWrapper, StyledLabel } from './index.styled';

import useIntersect from 'utils/intersect';

const intersectingThresholdPercentages = [0.4];

const Card = ({ onClick, label, image, hoverImage, index }) => {
  const [timeout, setTimeout] = useState(0);
  const [setElement, entry, unSubscribe] = useIntersect({ threshold: intersectingThresholdPercentages });
  const { isIntersecting } = entry;

  if (isIntersecting) {
    unSubscribe();
  }

  useEffect(() => {
    let newIndex = index;
    if (window.innerWidth <= 1024 && index > 1) {
      newIndex -= 2;
    }

    setTimeout(100 + newIndex * 200);
  }, [index]);

  return (
    <CSSTransition in={isIntersecting} timeout={timeout}>
      <StyledCardWrapper onClick={onClick} ref={setElement} image={image} hoverImage={hoverImage}>
        {/* fixes loading images on hover
        https://html.spec.whatwg.org/multipage/links.html#link-type-preload */}
        <link rel="preload" href={hoverImage} as="image" />

        <StyledLabel>{label}</StyledLabel>
      </StyledCardWrapper>
    </CSSTransition>
  );
};

export default Card;

import React, { useState, useEffect } from 'react';
import './styles.css';
import CounterWithContactForm from 'components/common/CountersWithContactForm';
import ProjectList from 'components/common/ProjectList';
import Flex from 'components/styled/Flex';
import Loading from 'components/styled/Loading';

import { shortenNumber } from 'utils/number';
import { deserializeProject } from 'services/projectLogic';
import { postConfigurationWithValue } from 'services/configuration';
import { getPublished } from 'services/admin';

import chevronDown from 'assets/icons/chevron-down.svg';
import styled from 'styled-components';

const ProjectsDescription = styled.div`
  margin: auto;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
`;

const amount = 5;

const calculateDescriptionMargin = firstProjectMarginTop => {
  return 130 - (firstProjectMarginTop < 0 && firstProjectMarginTop * -10);
};

const Projects = () => {
  const [allProjects, setAllProjects] = useState([]);
  const [numberConfigurations, setNumberConfigurations] = useState();
  const [numberOfAttendees, setNumberOfAttendees] = useState('');
  const [numberOfCycles, setNumberOfCycles] = useState('');
  const [numberOfWorkshops, setNumberOfWorkshops] = useState('');
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadMoreProjects = () => {
    setIsLoading(true);
    getPublished({ start: allProjects.length, amount }).then(({ data }) => {
      const deserializedProjects = data.expandoObjects.map(project => {
        deserializeProject(project);
        return project;
      });
      setCanLoadMore(data.canLoadMore);
      setAllProjects([...allProjects, ...deserializedProjects]);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getPublished({ start: 0, amount: 5 }).then(({ data }) => {
      const deserializedProjects = data.expandoObjects.map(project => {
        deserializeProject(project);
        return project;
      });
      setAllProjects(deserializedProjects);
      setCanLoadMore(data.canLoadMore);
    });
    postConfigurationWithValue({
      key: 'from-list',
      keys: ['NumberOfAttendees', 'NumberOfCycles', 'NumberOfWorkshops'],
    })
      .then(res => {
        setNumberConfigurations(res && res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (numberConfigurations) {
      checkIfNullOrUndefined(numberConfigurations['numberOfAttendees']) &&
        setNumberOfAttendees(numberConfigurations['numberOfAttendees'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfCycles']) &&
        setNumberOfCycles(numberConfigurations['numberOfCycles'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfWorkshops']) &&
        setNumberOfWorkshops(numberConfigurations['numberOfWorkshops'].value);
    }
  }, [numberConfigurations]);

  const checkIfNullOrUndefined = itemToCheck => {
    return itemToCheck !== null && itemToCheck !== undefined;
  };

  return (
    <div>
      <ProjectsDescription
        className="projects__description__container"
        marginBottom={calculateDescriptionMargin(
          allProjects &&
            allProjects[0] &&
            allProjects[0].backgroundImageOffset &&
            allProjects[0].backgroundImageOffset.marginTop,
        )}
      >
        <Flex className="projects__description">
          <Flex direction="column" className="projects__description__main" alignSelf="start">
            <h2 className="heading--normal projects__description__heading">Projekti</h2>
            <p className="text__description marginless projects__description__text">
              Radili smo na raznim projektima. Saznaj nešto više o njima!
            </p>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" className="projects__description__attributes">
            {numberOfAttendees && numberOfAttendees !== '' && (
              <Flex direction="column">
                <h2 className="heading--normal marginless projects__description__attribute__heading">
                  {shortenNumber(numberOfAttendees)}
                </h2>
                <p className="text__description marginless projects__description__attribute">posjetitelja</p>
              </Flex>
            )}
            {numberOfCycles && numberOfCycles !== '' && (
              <Flex direction="column">
                <h2 className="heading--normal marginless projects__description__attribute__heading">
                  {numberOfCycles}
                </h2>
                <p className="text__description marginless projects__description__attribute">ciklusa</p>
              </Flex>
            )}
            {numberOfWorkshops && numberOfWorkshops !== '' && (
              <Flex direction="column">
                <h2 className="heading--normal marginless projects__description__attribute__heading">
                  {numberOfWorkshops}+
                </h2>
                <p className="text__description marginless projects__description__attribute">radionica</p>
              </Flex>
            )}
          </Flex>
        </Flex>
      </ProjectsDescription>
      <ProjectList projects={allProjects} />
      {isLoading && <Loading />}
      {canLoadMore && !isLoading && (
        <Flex alignItems="center" justifyContent="center">
          <button className="projects__load-more" onClick={loadMoreProjects}>
            <p className="marginless">Učitaj još projekata</p>
            <img src={chevronDown} alt="Load more" />
          </button>
        </Flex>
      )}
      <CounterWithContactForm />
    </div>
  );
};

export default Projects;

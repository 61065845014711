import React, { useState } from 'react';

import ImagePicker from 'components/common/ImagePicker';
import Flex from 'components/styled/Flex';
import FileBox from 'components/common/FileBox';

import { FileType } from 'constants/fileType';
import { uploadZip, deleteZip, deleteFileOnEventSectionById } from 'services/admin';
import MultiSelect from 'react-multi-select-component';

const EventSectionEditor = ({
  eventSection,
  eventSections,
  setEventSections,
  setEditingIndex,
  findEventSection,
  members,
}) => {
  if (eventSection.text == null) eventSection.text = '';

  const memberOptions = members
  .sort((a, b) => {
    if (a.lastName[0] > b.lastName[0])
     return 1;
    return -1;
  })
  .map(member => {
    return {
      label: `${member.firstName} ${member.lastName}`,
      value: member,
    };
  });
  const { section, index } = findEventSection(`${eventSection.sectionOrder}`);
  const [membersOnEventSection, setMembersOnEventSection] = useState(
    eventSection.eventSectionMembers.map(esm => {
      const member = memberOptions.find(mo => mo.value.id === esm.memberId);
      return member;
    }),
  );
  const [isLoadingFileUpload, setIsLoadingFileUpload] = useState(false);
  const [isFileError, setIsFileError] = useState(false);
  const oldEventSection = eventSection;

  const updateEventSectionBySplice = (propertyName, propertyValue) => {
    const eventSectionsNew = eventSections;
    eventSectionsNew.splice(index, 1);
    eventSectionsNew.splice(index, 0, { ...section, [propertyName]: propertyValue });
    setEventSections([...eventSectionsNew]);
  };

  const setEventSectionProperty = ({ target: { name, value } }) => {
    updateEventSectionBySplice(name, value);
  };

  const setImagePath = imagePath => {
    updateEventSectionBySplice('imagePath', imagePath);
  };

  const setEventSectionMembers = selectedMembers => {
    const eventSectionMembers = selectedMembers.map(selectedMember => {
      return {
        eventSectionId: section.id,
        memberId: selectedMember.value.id,
        member: selectedMember.value,
      };
    });
    updateEventSectionBySplice('eventSectionMembers', eventSectionMembers);
    setMembersOnEventSection(selectedMembers);
  };

  const onFilePicked = file => {
    setIsLoadingFileUpload(true);
    uploadZip(file)
      .then(({ data }) => {
        updateEventSectionBySplice('fileName', data);
        setIsFileError(false);
        setIsLoadingFileUpload(false);
      })
      .catch(() => {
        setIsFileError(true);
        setIsLoadingFileUpload(false);
      });
  };

  const handleFileDelete = () => {
    if (!window.confirm('This action will be executed immediately and you cannot reverse it.')) return;
    if (eventSection.id !== 0) {
      deleteFileOnEventSectionById({ eventSectionId: eventSection.id, fileName: eventSection.fileName }).then(() => {
        oldEventSection.fileName = '';
        updateEventSectionBySplice('fileName', '');
      });
    } else {
      deleteZip({ fileName: eventSection.fileName }).then(() => {
        updateEventSectionBySplice('fileName', '');
      });
    }
  };

  const onOk = () => {
    const newEventSection = eventSections[index];
    if (newEventSection.fileName !== oldEventSection.fileName) deleteZip({ fileName: oldEventSection.fileName });
    setEditingIndex(undefined);
  };

  const onCancel = () => {
    const eventSectionsNew = eventSections;
    eventSectionsNew.splice(index, 1);
    eventSectionsNew.splice(index, 0, { ...oldEventSection });
    setEventSections([...eventSectionsNew]);
    const newEventSection = eventSections[index];
    if (newEventSection.fileName !== oldEventSection.fileName) deleteZip({ fileName: newEventSection.fileName });
    setEditingIndex(undefined);
  };

  return (
    <Flex direction="column" alignItems="center">
      <div className="form__add__header">Event section editor</div>
      {eventSection && (
        <form
          className="form__add"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <label className="label">Title</label>
          <input
            name="title"
            className="input"
            type="text"
            value={eventSection.title}
            onChange={setEventSectionProperty}
          />
          <label className="label">Text</label>
          <textarea
            name="text"
            className="textarea"
            type="text"
            value={eventSection.text}
            onChange={setEventSectionProperty}
          />
          <label className="label">Footage link</label>
          <input
            name="footageLink"
            className="input"
            type="text"
            value={eventSection.footageLink}
            onChange={setEventSectionProperty}
          />
          <label className="label">Time and Location</label>
          <input
            name="timeAndLocation"
            className="input"
            type="text"
            value={eventSection.timeAndLocation}
            onChange={setEventSectionProperty}
          />
          <label className="label">Members</label>
          <MultiSelect
            options={memberOptions}
            value={membersOnEventSection}
            onChange={setEventSectionMembers}
            labelledBy={'Select member'}
          />
          <label className="label">Zip file</label>
          {eventSection.fileName.length === 0 && (
            <>
              {isFileError && <label className="label form__error">File needs to be type of zip</label>}
              {!isLoadingFileUpload && <FileBox fileType={FileType.zip} onDrop={onFilePicked} />}
              {isLoadingFileUpload && <span>Uploading...</span>}
            </>
          )}
          {eventSection.fileName.length !== 0 && (
            <div className="file__preview__wrapper">
              <span>{eventSection.fileName}</span>
              <button className="button-admin cancel-button" onClick={handleFileDelete}>
                Delete file
              </button>
            </div>
          )}

          <label className="label">Event section image</label>
          <ImagePicker
            className="image-picker-input"
            imagePath={eventSection.imagePath}
            imagePathChangedCallback={setImagePath}
            imagePathFallback={{  
              folder: 'Default',
              image: 'event-section-default.jpg',
            }}
          />
          <div className="form__add__buttons">
            <button className="button submit-button" onClick={onOk}>
              Ok
            </button>
            <button className="button-admin cancel-button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </form>
      )}
    </Flex>
  );
};

export default EventSectionEditor;

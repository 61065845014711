import React, { useState, useEffect, useRef } from 'react';
import Log from './Log';

import { debounce } from 'utils/debounce';
import { throttle } from 'utils/throttle';
import { getUserLogs, getUserActionTypes } from 'services/admin';
import Loading from 'components/styled/Loading';

const initialParams = {
  start: 0,
  amount: 50,
  content: '',
  userActionType: '',
  email: '',
  day: 0,
  month: 0,
  year: 0,
};

const UserLogs = () => {
  const infiniteLoadRef = useRef(null);
  const [userLogs, setUserLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [actionTypes, setActionTypes] = useState([]);
  const [observer, setObserver] = useState(undefined);
  const [isLastResultEmpty, setIsLastResultEmpty] = useState(false);
  const [searchParams, _setSearchParams] = useState(initialParams);

  const setSearchParam = ({ target: { value, name } }) => {
    setIsLastResultEmpty(false);
    _setSearchParams({ ...searchParams, [name]: value });
    debounceUserLogs();
  };

  const setUserActionType = ({ target: { value } }) => {
    setIsLastResultEmpty(false);
    let param = { ...searchParams, userActionType: value, start: 0 };
    _setSearchParams(prev => param);
    loadUserLogs(param, false);
  };

  const loadUserLogs = (param, isThrottle) => {
    setIsLoading(true);
    return getUserLogs(param).then(({ data }) => {
      setIsLastResultEmpty(data.length < param.amount);
      if (isThrottle) {
        setUserLogs(prevUserLogs => [...prevUserLogs, ...data]);
      } else {
        setUserLogs([...data]);
      }
      setIsLoading(false);
    });
  };

  const throttleUserLogs = throttle(() => {
    let shouldSkip;
    setIsLastResultEmpty(prev => {
      shouldSkip = prev;
      return prev;
    });

    if (shouldSkip) return;
    let param;
    _setSearchParams(prevParams => {
      param = {
        ...prevParams,
        start: prevParams.start + prevParams.amount,
      };
      return param;
    });
    loadUserLogs(param, true);
  }, 300);

  const debounceUserLogs = debounce(() => {
    let param;
    _setSearchParams(prevParams => {
      param = {
        ...prevParams,
        start: 0,
      };
      return param;
    });
    loadUserLogs(param);
  }, 300);

  useEffect(() => {
    setIsLoading(true);
    getUserActionTypes().then(({ data }) => setActionTypes(data));
    getUserLogs(initialParams).then(({ data }) => {
      setIsLoading(false);
      setUserLogs(data);
    });
  }, []);

  if (infiniteLoadRef.current && observer === undefined) {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio === 1) {
          throttleUserLogs();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );
    setObserver(observer);
    observer.observe(infiniteLoadRef.current);
  }

  return (
    <>
      <table className="user-logs__table">
        <thead>
          <tr>
            <th className="table__id">Id</th>
            <th className="table__content">Content</th>
            <th className="table__action-type">Action type</th>
            <th className="table__email">Email</th>
            <th className="table__time-stamp">Time Stamp</th>
          </tr>
          <tr>
            <th></th>
            <th>
              <input
                type="text"
                className="input"
                name="content"
                value={searchParams.content}
                onChange={setSearchParam}
                placeholder="Content"
              />
            </th>
            <th>
              <select name="userActionType" value={searchParams.userActionType} onChange={setUserActionType}>
                {actionTypes.map((actionType, index) => {
                  return (
                    <option key={index} value={actionType}>
                      {actionType}
                    </option>
                  );
                })}
              </select>
            </th>
            <th>
              <input
                type="text"
                className="input"
                name="email"
                value={searchParams.email}
                onChange={setSearchParam}
                placeholder="Email"
              />
            </th>
            <th className="user-logs__filters-date">
              <input
                type="number"
                className="input"
                name="day"
                value={searchParams.day}
                onChange={setSearchParam}
                placeholder="Day"
              />
              <input
                type="number"
                className="input"
                name="month"
                value={searchParams.month}
                onChange={setSearchParam}
                placeholder="Month"
              />
              <input
                type="number"
                className="input"
                name="year"
                value={searchParams.year}
                onChange={setSearchParam}
                placeholder="Year"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {userLogs.map((log, index) => {
            return <Log key={index} log={log} />;
          })}
        </tbody>
      </table>
      <div ref={infiniteLoadRef} className="row__info">
        {isLoading && <Loading />}
        {isLastResultEmpty && <span>No more results</span>}
      </div>
    </>
  );
};

export default UserLogs;

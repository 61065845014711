import { endpoints } from './common';
import axios from 'axios';

export const allTrustedPartners = () => {
  return axios.get(endpoints.admin.trustedPartner.getAll);
};

export const addTrustedPartner = ({ name, linkToWebsite }) => {
  return axios.post(endpoints.admin.trustedPartner.add, { name, linkToWebsite });
};

export const editTrustedPartner = ({ id, name, linkToWebsite }) => {
  return axios.post(endpoints.admin.trustedPartner.edit, { id, name, linkToWebsite });
};

export const deleteTrustedPartner = ({ id }) => {
  return axios.delete(endpoints.admin.trustedPartner.delete + '/' + id);
};

import React, { useMemo, useState, useCallback } from 'react';

import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';

import { withLinks } from './Controls/LinkButton';
import { withColor } from './Controls/ColorButton';
import Element from 'components/common/TextElement/Element';
import Leaf from 'components/common/TextElement/Leaf';
import Toolbar from './Toolbar';
import { debounce } from 'utils/debounce';

const sumTextCharacters = values => {
  let sum = 0;

  if (!Array.isArray(values)) {
    return sum;
  }

  values.forEach(value => {
    if (!!value.text) {
      sum += value.text.replace(/\s/g, '').length;
    }

    if (!!value.children) {
      sum += sumTextCharacters(value.children);
    }
  });

  return sum;
};

const RichTextEditor = ({
  text,
  setValueCallback,
  index,
  isEmail,
  libraryAttachments,
  fileAttachments,
  isLinkDisabled,
  maxCharCount,
}) => {
  const [value, _setValue] = useState(text);
  const [charCount, setCharCount] = useState(sumTextCharacters(text));
  const editor = useMemo(() => withColor(withLinks(withHistory(withReact(createEditor())))), []);
  const renderElement = useCallback(props => <Element {...props}></Element>, []);
  const renderLeaf = useCallback(props => <Leaf {...props}></Leaf>, []);
  const sumCharactersDebounced = useCallback(
    debounce(value => {
      const sum = sumTextCharacters(value);
      setCharCount(sum);
    }, 300),
    [],
  );

  const setValue = value => {
    const editorValue = value.map(element => {
      return { ...element, editorIndex: index };
    });
    setValueCallback(editorValue);
    sumCharactersDebounced(value);
    _setValue(editorValue);
  };

  return (
    <Slate editor={editor} value={value} onChange={setValue}>
      <Toolbar
        isEmail={isEmail}
        libraryAttachments={libraryAttachments}
        fileAttachments={fileAttachments}
        isLinkDisabled={isLinkDisabled}
        charCount={charCount}
        maxCharCount={maxCharCount}
      />
      <Editable
        className="editor"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter rich text..."
      />
    </Slate>
  );
};

export default RichTextEditor;

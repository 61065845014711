import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from 'react-transition-group';

import { StyledAnswer, StyledArrow, StyledFaq, StyledQuestion } from './index.styled';

import ArrowUpIcon from 'assets/icons/arrow_up.svg';
import useIntersect from 'utils/intersect';

const intersectingThresholdPercentages = [0.6];

const Question = ({ question, answer }) => {
  const [setElement, entry, unSubscribe] = useIntersect({ threshold: intersectingThresholdPercentages });
  const { isIntersecting } = entry;
  const [isActive, setIsActive] = useState(false);

  if (isIntersecting) {
    unSubscribe();
  }

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <CSSTransition in={isIntersecting} timeout={100}>
      <StyledFaq ref={setElement} onClick={() => toggleActive()}>
        <StyledQuestion>
          {question} <StyledArrow src={ArrowUpIcon} isActive={isActive} />
        </StyledQuestion>

        <AnimateHeight duration={200} height={!isActive ? 0 : 'auto'}>
          <StyledAnswer>{answer}</StyledAnswer>
        </AnimateHeight>
      </StyledFaq>
    </CSSTransition>
  );
};

export default Question;

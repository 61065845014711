import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import arrow from 'assets/icons/arrow.svg';

import Images from './Images';
import Text from './Text';

const Post = ({ post, previousClickHandler, nextClickHandler, buttonsDisabled }) => {
  return (
    <figure className="dump-through-years__post">
      <TransitionGroup component={React.Fragment}>
        <CSSTransition key={post.id} timeout={750} classNames="fade_image">
          <Images post={post} />
        </CSSTransition>
      </TransitionGroup>

      <figcaption>
        <div className="post__buttons">
          <button className="buttons__backward" onClick={previousClickHandler} disabled={buttonsDisabled}>
            <img src={arrow} alt="Backward arrow" />
          </button>
          <button className="buttons__forward" onClick={nextClickHandler} disabled={buttonsDisabled}>
            <img src={arrow} alt="Forward arrow" />
          </button>
        </div>
        <TransitionGroup component={React.Fragment}>
          <CSSTransition key={post.id} timeout={750} classNames="fade_text">
            <Text post={post} />
          </CSSTransition>
        </TransitionGroup>
      </figcaption>
    </figure>
  );
};

export default Post;

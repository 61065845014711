import React, { useState, useEffect } from 'react';
import './styles.css';
import PreviewContactQuestions from './PreviewContactQuestions';
import { getContactQuestions, getContactQuestionInfos } from 'services/admin';
import Loading from 'components/styled/Loading';

const ContactQuestions = () => {
  const [contactQuestions, _setContactQuestions] = useState([]);
  const [contactQuestionInfos, setContactQuestionInfos] = useState([]);
  const [selectedDropdownInfo, setSelectedDropdownInfo] = useState(0);
  const [selectedDropdownNewsletter, setSelectedDropdownNewsletter] = useState('');
  const [loading, setLoading] = useState(false);
  const [infiniteScrollStep, setInfiniteScrollStep] = useState(0);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);

  const setContactQuestions = contactQuestionsToSet => {
    if (contactQuestionsToSet.length) {
      const sortedContactQuestions = contactQuestionsToSet.sort((firstQuestion, secondQuestion) =>
        firstQuestion.id < secondQuestion.id ? 1 : -1,
      );
      _setContactQuestions(sortedContactQuestions);
    } else {
      _setContactQuestions([]);
      setIsLoadMoreDisabled(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    getContactQuestions(0, '', infiniteScrollStep)
      .then(({ data }) => {
        setContactQuestions(data);
      })
      .then(() => setInfiniteScrollStep(infiniteScrollStep + 1))
      .finally(() => setLoading(false));
    getContactQuestionInfos().then(({ data }) => {
      setContactQuestionInfos(data);
    });
    //eslint-disable-next-line
  }, []);

  const loadMore = () => {
    getContactQuestions(selectedDropdownInfo, selectedDropdownNewsletter, infiniteScrollStep).then(data => {
      setContactQuestions([...contactQuestions, ...data.data]);
      data.data.length ? setIsLoadMoreDisabled(false) : setIsLoadMoreDisabled(true);
      setInfiniteScrollStep(infiniteScrollStep + 1);
    });
  };

  const filterContactQuestions = (contactQuestionInfoId, newsletterAgreed) => {
    setContactQuestions([]);
    setSelectedDropdownInfo(contactQuestionInfoId);
    setSelectedDropdownNewsletter(newsletterAgreed);
    getContactQuestions(contactQuestionInfoId, newsletterAgreed, 0).then(({ data }) => setContactQuestions(data));
    setInfiniteScrollStep(1);
  };

  return (
    <div className="admin__contact-question">
      <div className="contact-question__filters">
        <select
          value={selectedDropdownInfo}
          onChange={e => filterContactQuestions(e.target.value, selectedDropdownNewsletter)}
        >
          <option value={0} defaultValue>
            Sve teme
          </option>
          {contactQuestionInfos.map((dropdownOption, key) => {
            return (
              <option key={key} value={dropdownOption.id}>
                {dropdownOption.displayText}
              </option>
            );
          })}
        </select>
        <select
          value={selectedDropdownNewsletter}
          onChange={e => filterContactQuestions(selectedDropdownInfo, e.target.value)}
        >
          <option value="" defaultValue>
            Sve newsletter opcije
          </option>
          <option value="true">Prihvatili newsletter</option>
          <option value="false">Nisu prihvatili newsletter</option>
        </select>
      </div>
      <div className="contact-question__header">
        <span className="contact-question__header__display-date">Date posted</span>
        <span className="contact-question__header__display-mail">Sender mail</span>
        <span className="contact-question__header__display-name">Sender name</span>
        <span className="contact-question__header__display-info">Question category</span>
        <span className="contact-question__header__display-text">Question</span>
        <span className="contact-question__header__display-newsletter">Newsletter agreed</span>
      </div>
      {loading && <Loading />}
      {!loading &&
        contactQuestions.map(contactQuestion => (
          <PreviewContactQuestions
            key={contactQuestion.id}
            contactQuestion={contactQuestion}
            contactQuestions={contactQuestions}
            setContactQuestions={setContactQuestions}
          />
        ))}
      {!isLoadMoreDisabled && (
        <span className="button load-more" onClick={loadMore}>
          Load more
        </span>
      )}
    </div>
  );
};

export default ContactQuestions;

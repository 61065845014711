import React from 'react';
import Icon from 'components/common/Icon';
import bookIcon from 'assets/icons/internship/book.svg';
import heartIcon from 'assets/icons/internship/heart.svg';
import knowledgeIcon from 'assets/icons/internship/knowledge.svg';
import peopleIcon from 'assets/icons/internship/people.svg';

import {
  StyledContainer,
  StyledTextContainer,
  StyledTitle,
  StyledSubtitle,
  StyledDescription,
  StyledIconsContainer,
  StyledIconContainer,
  StyledIconTitle,
  StyledIconDescription,
  StyledBackgroundContainer,
} from './index.styled';
import { StyledSpacing } from '../index.styled';

const WhatIsInternship = () => {
  return (
    <StyledContainer>
      <StyledSpacing>
        <StyledTextContainer>
          <StyledTitle>
            Što je DUMP
            <br />
            <StyledSubtitle>Internship?</StyledSubtitle>
          </StyledTitle>
          <StyledDescription>
            To je besplatan šestomjesečni program kroz koji podučavamo buduće generacije dumpovaca.
          </StyledDescription>
        </StyledTextContainer>
      </StyledSpacing>
      <StyledSpacing>
        <StyledIconsContainer>
          <StyledIconContainer>
            <Icon image={knowledgeIcon} size={48} alt="6 mjeseci" />
            <StyledIconTitle color="#6DA5E3">6 mjeseci</StyledIconTitle>
            <StyledIconDescription>
              Počinjemo u studenom i družimo se na predavanjima i radionicama sve do svibnja.
            </StyledIconDescription>
          </StyledIconContainer>
          <StyledIconContainer>
            <Icon image={heartIcon} size={48} alt="Besplatno" />
            <StyledIconTitle color="#44C79B">Besplatno</StyledIconTitle>
            <StyledIconDescription>
              Zajedno učimo i volonterski širimo znanje za sve koji žele činiti isto.
            </StyledIconDescription>
          </StyledIconContainer>
          <StyledIconContainer>
            <Icon image={peopleIcon} size={48} alt="16 do 24 godine" />
            <StyledIconTitle color="#B087ED">16 do 24 godine</StyledIconTitle>
            <StyledIconDescription>
              Nismo prestrogi, ali svi sudionici moraju biti srednjoškolske i studentske dobi.
            </StyledIconDescription>
          </StyledIconContainer>
          <StyledIconContainer>
            <Icon image={bookIcon} size={48} alt="Sve razine znanja" />
            <StyledIconTitle color="#AE2536">Sve razine znanja</StyledIconTitle>
            <StyledIconDescription>
              Krećemo od samih osnova pa su početnici više nego dobrodošli, kao i oni iskusniji.
            </StyledIconDescription>
          </StyledIconContainer>
        </StyledIconsContainer>
      </StyledSpacing>
      <StyledBackgroundContainer />
    </StyledContainer>
  );
};

export default WhatIsInternship;

import React, { useCallback, useState, useEffect } from 'react';
import '../styles.css';

import { EventSectionLogic } from 'services/eventSectionLogic';
import { getMembers } from 'services/member';

import EventSectionCreator from './EventSectionCreator';
import EventSectionsBuildingContainer from './EventSectionsBuildingContainer';
import Flex from 'components/styled/Flex';

const EventSectionBuilder = ({ event, setEvent, setIsEditingEventSections }) => {
  const [eventSections, _setEventSections] = useState([...event.eventSections]);
  const [hasChanges, setHasChanges] = useState(false);
  const [members, setMembers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(undefined);
  const [isSorting, setIsSorting] = useState(false);

  const setEventSections = useCallback(value => {
    setHasChanges(true);
    _setEventSections(value);
  }, []);

  const itemAddedCallback = useCallback(() => {
    const currentOrder = eventSections.length;
    const newEvent = new EventSectionLogic(event.id, currentOrder);
    setEventSections([...eventSections, newEvent]);
  }, [eventSections, setEventSections, event]);

  useEffect(() => {
    getMembers().then(({ data }) => {
      setMembers(data);
    });
  }, []);

  const runSave = () => {
    const anyEventSectionsNotValid = eventSections.filter(
      eventSection =>
        eventSection.title.length === 0 &&
        eventSection.timeAndLocation.length === 0 &&
        eventSection.eventSectionMembers.length === 0,
    )[0];
    if (anyEventSectionsNotValid) {
      window.alert('Make sure all event sections have set: titles, time and locaiton and members');
      return;
    }
    const sectionsWithMappedSectionOrder = [...eventSections].map((section, index) => {
      section.sectionOrder = index;
      return section;
    });

    setEvent({ ...event, eventSections: sectionsWithMappedSectionOrder });
    setIsEditingEventSections(false);
  };

  const cancelEventSectionEditing = () => {
    if (hasChanges && !window.confirm('Event sections have changes are you sure you want to cancel your progress?')) return;
    setIsEditingEventSections(false);
  }

  return (
    <div>
      {editingIndex === undefined && (
        <Flex justifyContent="space-around" wrap="wrap" margin="10px 0">
          {!isSorting && (
            <>
              <button className="button-admin submit-button" onClick={runSave}>
                Save
              </button>
              <button className="button-admin submit-button" onClick={() => setIsSorting(true)}>
                Start sorting
              </button>
              <button className="button-admin cancel-button" onClick={cancelEventSectionEditing}>Back to event</button>
            </>
          )}
          {isSorting && (
            <button className="button-admin submit-button" onClick={() => setIsSorting(false)}>
              Stop sorting
            </button>
          )}
        </Flex>
      )}
      <div>
        <EventSectionsBuildingContainer
          eventSections={eventSections}
          setEventSections={setEventSections}
          members={members}
          editingIndex={editingIndex}
          setEditingIndex={setEditingIndex}
          isSorting={isSorting}
        />
        {editingIndex === undefined && <EventSectionCreator itemAddedCallback={itemAddedCallback} />}
      </div>
    </div>
  );
};

export default EventSectionBuilder;

import React from 'react';

import Question from './Question';
import { StyledWrapper, StyledTitle, StyledFlexSection } from './index.styled';
import { StyledSpacing } from '../index.styled';

import { faqs } from './const';

const FrequentlyAskedQuestions = () => {
  return (
    <StyledFlexSection position="relative">
      <StyledSpacing>
        <StyledWrapper>
          <StyledTitle>Faq</StyledTitle>
          {faqs.map(({ question, answer }, index) => (
            <Question
              key={index}
              question={question}
              answer={answer}
            />
          ))}
        </StyledWrapper>
      </StyledSpacing>
    </StyledFlexSection>
  );
};

export default FrequentlyAskedQuestions;

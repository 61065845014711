import React, { useContext } from 'react';
import { MenuStateContext } from 'services/providers/MenuProvider';

const CookieNotice = () => {
  const {
    state: { didCheckCookieNotice },
    setDidCheckCookieNotice,
  } = useContext(MenuStateContext);

  if (didCheckCookieNotice) {
    return null;
  }

  return (
    <div className="cookie-notice">
      <p className="cookie-notice__text">
        Pregledavanjem ove stranice prihvaćate naše kolačiće. Osim što su slatki, korisni su nam za analitiku upotrebe
        stranice i omogućavaju nam da što bolje prilagodimo sadržaj stranice.
      </p>
      <button className="cookie-notice__button" onClick={() => setDidCheckCookieNotice(true)}>
        U redu
      </button>
    </div>
  );
};

export default CookieNotice;

import React from 'react';
import TextUnderline from 'components/styled/TextUnderline';
import ImageLoader from 'components/common/ImageLoader';
import AttendanceInfo from './AttendanceInfo';
import EventHeader from 'assets/shapes/eventHeader.png';

const Header = () => {
  return (
    <section className="events__header">
      <h1>
        Doprinosimo zajednici kroz{' '}
        <TextUnderline color="#282d30" underlineColor="#5cc2a0cc">
          događaje.
        </TextUnderline>
      </h1>

      <AttendanceInfo eventAttendance="Overall" margin="83px 0 222px 0" />

      <ImageLoader src={EventHeader} alt="Header" />
    </section>
  );
};

export default Header;

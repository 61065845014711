import React from 'react';
import styled from 'styled-components';
import '../styles.css';

const EventSectionCreatorStyled = styled.div`
  display: flex;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100px;
  border: 2px dashed #d8d8d8;
  background-color: #e1efe6;
  cursor: pointer;
  font-weight: bold;
  color: #3282b6;
  transition: background-color 200ms ease-in;

  &:hover {
    background-color: #dee2e2;
  }

  .event-section__button {
    font-size: 18px;
    text-transform: uppercase;
  }
`;

const EventSectionCreator = ({ itemAddedCallback }) => {
  return (
    <EventSectionCreatorStyled onClick={itemAddedCallback}>
      <span className="event-section__button">Add event section</span>
    </EventSectionCreatorStyled>
  );
}

export default EventSectionCreator;
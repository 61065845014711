import styled from 'styled-components';

export const StyledContainer = styled.section`
  margin: 216px 120px 276px;

  @media (max-width: 1280px) {
    margin: 216px 50px 276px;
  }

  @media (max-width: 767px) {
    margin: 216px 24px 172px;
  }
`;

export const StyledTitleWrapper = styled.div`
  max-width: 600px;
  margin-left: 96px;
  margin-top: 0;

  @media (max-width: 1024px) {
    margin-left: 48px;
  }

  @media (max-width: 767px) {
    margin-left: 24px;
  }

  @media (max-width: 550px) {
    margin-left: 0px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-size: 112px;
  font-weight: 800;
  line-height: 112px;
  color: #111111;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    font-size: 56px;
    line-height: 60px;
  }

  @media (max-width: 360px) {
    font-size: 52px;
  }
`;

export const StyledSubtitle = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  max-width: 488px;
  color: #282d30;
`;

export const StyledSupportersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 80px 9.333%;
  margin-top: 115px;

  @media (max-width: 1280px) {
    gap: 80px 6.66%;
  }

  @media (max-width: 1280px) {
    gap: 80px 5%;
  }

  @media (max-width: 767px) {
    gap: 80px 6%;
    margin-left: 6px;
  }
`;

export const StyledLogoLink = styled.a`
  width: 18%;
  height: 100%;

  @media (max-width: 1280px) {
    width: 20%;
  }

  @media (max-width: 1024px) {
    width: 30%;
  }

  @media (max-width: 767px) {
    width: 47%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const StyledLogo = styled.img`
  display: block;
  margin: auto;
  width: auto;
`;

export const StyledLogoWithMarginTop = styled.img`
  display: block;
  margin: auto;
  width: auto;
  margin-top: ${props => props.margin};

  @media (max-width: 500px) {
    margin-top: 100px;
  }
`;

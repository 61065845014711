import React, { useState, useEffect } from 'react';
import { getMembers } from 'services/member';
import { getFilteredMembers, constructMemberComparers, getSortedMemberTypes } from "./utils";

import MemberFilter, { initialState } from "./MemberFilter";
import PreviewMember from './PreviewMember';
import AddEditMember from './AddEditMember';
import Loading from 'components/styled/Loading';

import Flex from 'components/styled/Flex';

const Members = () => {
  const [members, _setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idOfEditingMember, setIdOfEditingMember] = useState(-1);
  const [filter, setFilter] = useState(initialState.filter);
  const [memberTypes, setMemberTypes] = useState([]);

  const memberComparers = constructMemberComparers(memberTypes);

  const updateTable = () => {
    const filteredMembers = getFilteredMembers(members, filter);
    filteredMembers.sort(memberComparers[filter.sortBy]);

    setFilteredMembers(filteredMembers);
  }

  useEffect(() => {
    setLoading(true);
    setMembers();

    (async () => setMemberTypes(await getSortedMemberTypes()))();
  }, []);

  useEffect(updateTable, [members, filter, memberTypes]);

  const setMembers = () => {
    getMembers().then(({ data }) => {
      _setMembers(data);
      setIdOfEditingMember(-1);
      setLoading(false);
    });
  };

  return (
    <section className="members">
      <Flex direction="column">
        <AddEditMember members={members} setMembers={setMembers} isAdd />
        {loading && <Loading />}
        <MemberFilter filter={filter} setFilter={setFilter} memberTypes={memberTypes}/>
        <div className="members__list">
          {!loading &&
            filteredMembers.map((member, index) => {
              if (member.id === idOfEditingMember)
                return (
                  <AddEditMember
                    key={index}
                    selectedMember={member}
                    members={members}
                    setMembers={setMembers}
                    setEditingId={setIdOfEditingMember}
                  />
                );
              return (
                <PreviewMember key={index} member={member} setMembers={setMembers} setEditingId={setIdOfEditingMember} />
              );
            })}
        </div>
      </Flex>
    </section>
  );
};

export default Members;

import React from 'react';
import teambuildings from 'assets/icons/teambuildings.svg';
import travels from 'assets/icons/travels.svg';
import competitions from 'assets/icons/competitions.svg';
import Flex from 'components/styled/Flex';

const ActivityGrid = () => {
  return (
    <Flex justifyContet="center" alignItems="center">
      <div className="about__activities">
        <div className="grid__item grid__teambuilding">
          <img src={teambuildings} alt="Teambuildings" className="grid__icon" />
          <p className="grid__text">Teambuildinzi</p>
        </div>
        <div className="grid__item grid__travels">
          <img src={travels} alt="Travels" className="grid__icon" />
          <p className="grid__text">Putovanja</p>
        </div>
        <div className="grid__item grid__competitions">
          <img src={competitions} alt="Competitions" className="grid__icon" />
          <p className="grid__text">Natjecanja</p>
        </div>
        <div className="grid__title"> Zabavljamo se dobro.</div>
        <div className="grid__image" />
        <div className="grid__image-2" />
        <div className="grid__image-3" />
        <div className="grid__image-4" />
        <div className="grid__image-5" />
      </div>
    </Flex>
  );
};

export default ActivityGrid;

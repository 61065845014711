import React, { useState, useRef } from 'react';
import Slider from 'infinite-react-carousel';
import { S3_BUCKET_BASE_URL as base } from 'services/common';
import { getDateYear } from 'utils/date';
import { getYearsFromEvents } from 'utils/getYearsFromEvents';
import { normalizeForUrl } from 'utils/normalizeForUrl';
import { Link } from 'react-router-dom';
import ImageLoader from 'components/common/ImageLoader';
import useFallbackImage from 'hooks/useFallbackImage';

const YearSeparator = ({ year }) => {
  return <span className="event__list--year--separator">{year}.</span>;
};

const EventPreview = ({ event, isFirst, isSliding }) => {
  const { setFallbackImageSource } = useFallbackImage('');
  const eventYear = new Date(event.dateOfBeginning).getFullYear();

  return (
    <>
      {isFirst && <YearSeparator year={eventYear} />}
      <Link rel="noopener" to={`/events/${event.id}-${normalizeForUrl(event.name)}`} className={`${isSliding ? 'grabbing' : ''}`}>
        <figure>
          <ImageLoader
            onError={setFallbackImageSource}
            src={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`}
            alt="poster"
          />
          <link rel="preload" href={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`} as="image" />
          <h3>{eventYear}</h3>
          <figcaption>{event.name}</figcaption>
        </figure>
      </Link>
    </>
  );
};

const AllEvents = ({ allEvents }) => {
  const [selectedYear, setSelectedYear] = useState(getDateYear(''));
  const [isSliding, setIsSliding] = useState(false);
  const sliderRef = useRef();

  const carouselSettings = {
    arrows: false,
    arrowsBlock: false,
    autoplay: true,
    centerPadding: 10,
    duration: 300,
    shift: 10,
    wheel: false,
    className: 'events-all__carousel',
    pauseOnHover: false,
  };

  if (!allEvents || allEvents.length === 0) {
    return null;
  }

  const listOfYears = getYearsFromEvents(allEvents);

  const eventsSorted = allEvents
    .sort((left, right) => getDateYear(right.dateOfBeginning) - getDateYear(left.dateOfBeginning))
    .map((event, index, arr) => {
      return {
        ...event,
        isFirst: !arr[index - 1] || getDateYear(event.dateOfBeginning) !== getDateYear(arr[index - 1].dateOfBeginning),
      };
    });

  const mockScroll = () => {
    document.dispatchEvent(new CustomEvent('scroll'));
  };

  const handleSelectYear = year => {
    const index = eventsSorted.findIndex(event => year === getDateYear(event.dateOfBeginning));

    sliderRef.current.slickGoTo(index);

    setSelectedYear(year);
    mockScroll();
  };

  const handleEventChange = index => {
    const currentEvent = eventsSorted[index];
    setSelectedYear(getDateYear(currentEvent.dateOfBeginning));
  };

  const handlePointerDown = () => {
    sliderRef.current && sliderRef.current.slickPause();
    setIsSliding(true);
  };

  const handlePointerUp = () => {
    sliderRef.current && sliderRef.current.slickPlay();
    setIsSliding(false);
  };

  return (
    <section className="events__list">
      <h2 className="events__title">Svi događaji</h2>

      <div className="events__years">
        {listOfYears.map((year, index) => (
          <span
            key={index}
            className={selectedYear === year ? 'selected--year' : ''}
            onClick={() => handleSelectYear(year)}
          >
            {year}.
          </span>
        ))}
      </div>

      <section className="events__list--preview" onPointerDown={handlePointerDown} onPointerUp={handlePointerUp}>
        <Slider {...carouselSettings} afterChange={handleEventChange} beforeChange={mockScroll} ref={sliderRef}>
          {eventsSorted.flat().map((event, index) => {
            return (
              <div key={index} className={`event__list__item ${event.isFirst ? 'event__list__item--spaced' : ''}`}>
                <EventPreview event={event} isFirst={event.isFirst} isSliding={isSliding} />
              </div>
            );
          })}
        </Slider>
      </section>
    </section>
  );
};

export default AllEvents;

import React, { useState } from 'react';
import { editConfiguration } from 'services/configuration';
import Flex from 'components/styled/Flex';

const ConfigurationRow = ({ configuration, trigger }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');

  const handleSubmit = () => {
    editConfiguration({ key: configuration.key, value: editValue })
      .then(() => trigger(prev => !prev))
      .catch(() => alert(`Oops, an error occurred while updating '${configuration.key}'`));
  };

  const toggleEdit = () => {
    setEditValue('');
    setIsEditing(!isEditing);
  };

  return (
    <div className="user__preview">
      <Flex>
        <p className="user__preview__email tag-transparent">{configuration.key}</p>
        <p className="user__preview__email">&nbsp;: {configuration.value}</p>
      </Flex>
      {isEditing && (
        <Flex alignSelf="center" alignItems="center" direction="column">
          <Flex>
            <input
              placeholder="value"
              className="input"
              type="text"
              value={editValue}
              onChange={e => setEditValue(e.target.value)}
            />
            <button className="button submit-button" onClick={handleSubmit}>
              Submit
            </button>
          </Flex>
        </Flex>
      )}
      <button className="button edit-button" onClick={toggleEdit}>
        {isEditing ? 'Cancel' : 'Edit'}
      </button>
    </div>
  );
};

export default ConfigurationRow;

import styled from 'styled-components';

const Loading = styled.span`
  background-color: #282828;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: relative;
  display: block;
  margin: auto;

  ::after,
  ::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin: center center;
    animation: pulse 2s infinite;
  }

  ::before {
    background-color: #282828;
    opacity: 0.6;
    transform: scale(2.5);
  }

  ::after {
    background-color: #282828;
    opacity: 0.3;
    transform: scale(3.5);
  }
`;

export default Loading;

import { ImagePathLogic } from './imagePathLogic';

export class ProjectObject extends ImagePathLogic {
  id;
  name;
  title;
  shortDescription;
  backgroundImageOffset;
  ctaTextCoordinates;
  ctaTextAlignment;
  projectSkillsBackgroundColor;
  priority;
  isPublished;
  projectSkills;

  constructor(id, name, shortDescription, backgroundImageOffset, ctaTextCoordinates, priority, isPublished, imagePath) {
    super(imagePath);
    this.id = id ? id : undefined;
    this.name = name ? name : '';
    this.title = [
      {
        type: 'h2',
        children: [{ text: '' }],
      },
    ];
    this.shortDescription = shortDescription
      ? shortDescription
      : [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ];
    this.backgroundImageOffset = backgroundImageOffset
      ? backgroundImageOffset
      : {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          width: 100,
        };
    this.ctaTextCoordinates = ctaTextCoordinates
      ? ctaTextCoordinates
      : {
          top: 0,
          left: 0,
        };
    this.ctaTextAlignment = 'flex-start';
    this.projectSkillsBackgroundColor = '#C0C0C0';
    this.priority = priority ? priority : 999;
    this.isPublished = isPublished ? isPublished : false;
    this.projectSkills = [];
  }
}

export const serializeProject = project => {
  project.title = JSON.stringify(project.title);
  project.shortDescription = JSON.stringify(project.shortDescription);
  project.ctaTextCoordinates = JSON.stringify(project.ctaTextCoordinates);
  project.backgroundImageOffset = JSON.stringify(project.backgroundImageOffset);
};

export const deserializeProject = project => {
  project.title = JSON.parse(project.title);
  project.shortDescription = JSON.parse(project.shortDescription);
  project.ctaTextCoordinates = JSON.parse(project.ctaTextCoordinates);
  project.backgroundImageOffset = JSON.parse(project.backgroundImageOffset);
};

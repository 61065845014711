import React from 'react';
import { Transforms, Editor } from 'slate';
import { useSlate } from 'slate-react';

import ToolbarMenuItemStyled from '../ToolbarMenuItemStyled';
import { ElementType } from 'constants/elementType';

const isH2ACtive = editor => {
  const [h2] = Editor.nodes(editor, {
    match: n => n.type === ElementType.h2,
  });

  return !!h2;
};

const toggleH2 = editor => {
  const isActive = isH2ACtive(editor);

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : ElementType.h2
  });
};

const H2Button = () => {
  const editor = useSlate();
  
  return (
    <ToolbarMenuItemStyled
      active={isH2ACtive(editor)}
      onMouseDown={event => {
        event.preventDefault();
        toggleH2(editor);
      }}
    >
      <span>H2</span>
    </ToolbarMenuItemStyled>
  );
};

export default H2Button;

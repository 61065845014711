import React, { useState, useEffect } from 'react';
import { getAllPosts } from 'services/post';
import { getMembers } from 'services/member';

import PreviewPost from './PreviewPost';
import AddEditPost from './AddEditPost';
import Loading from 'components/styled/Loading';

const TimelinePosts = () => {
  const [posts, _setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idOfEditingPost, setIdOfEditingPost] = useState(-1);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    setLoading(true);
    getMembers().then(({ data }) => {
      const dataSorted = data.sort((firstMember, secondMember) => {
        if (firstMember.email < secondMember.email) {
          return -1;
        }
        if (firstMember.email > secondMember.email) {
          return 1;
        }
        return 0;
      });
      setMembers(dataSorted);
    }).then(() => {
      setPosts();
    })
  }, []);

  const setPosts = () => {
    getAllPosts().then(({ data }) => {
      _setPosts(data);
      setLoading(false);
    });
  };

  return (
    <section className="posts">
      <div className="posts__list">
        <AddEditPost posts={posts} setPosts={setPosts} members={members} />
        {loading && <Loading />}
        {!loading &&
          posts.map((post, index) => {
            if (post.id === idOfEditingPost)
              return (
                <AddEditPost
                  key={index}
                  selectedPost={post}
                  posts={posts}
                  setPosts={setPosts}
                  setEditingId={setIdOfEditingPost}
                  members={members}
                />
              );
            return <PreviewPost key={index} post={post} setPosts={setPosts} setEditingId={setIdOfEditingPost} />;
          })}
      </div>
    </section>
  );
};

export default TimelinePosts;

import React from 'react';
import styled, { css } from 'styled-components';

const Img = styled.img.attrs(() => ({
  loading: 'lazy',
}))`
  ${({ relative }) => relative && 'position: relative;'}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ height }) =>
    height &&
    css`
      width: ${height};
    `}
  transition: opacity 0.2s ease-in-out, transform 0.15s ease-in-out;
`;

const ImageLoader = ({ src, alt, onClick, className, onError, width, height, relative }) => {
  return (
    <Img
      className={className}
      src={src}
      alt={alt}
      onClick={onClick}
      onError={onError}
      relative={relative}
      width={width}
      height={height}
    />
  );
};

export default ImageLoader;

import React, { useEffect } from 'react';
import './styles.css';
import { Switch, Route, Redirect } from 'react-router-dom';

import Navigation from './Navigation';
import Footer from './Footer';
import CookieNotice from './CookieNotice';
import Admin from '../Admin';
import Landing from '../Landing';
import Events from '../Events';
import Projects from '../Projects';
import ProjectDetails from '../ProjectDetails';
import About from '../About';
import Internship from '../Internship';
import NewsletterPreferences from '../NewsletterPreferences';
import NotFound from '../NotFound';
import Flex from 'components/styled/Flex';
import NextEvent from '../Events/NextEvent';
import EventDetails from '../EventDetails';

import ContactModalProvider from 'services/providers/ContactModalProvider';
import MenuProvider from 'services/providers/MenuProvider';

const AppLayoutScene = ({ children, screenOnly, canOpenContactForm }) => {
  const currentPathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPathname]);

  return (
    <Flex direction="column">
      <MenuProvider>
        <NextEvent />
        <CookieNotice />

        <ContactModalProvider>
          {!screenOnly && <Navigation />}
          {canOpenContactForm && children}
        </ContactModalProvider>
      </MenuProvider>

      {!canOpenContactForm && children}
      {!screenOnly && <Footer />}
    </Flex>
  );
};

const AppLayout = () => {
  return (
    <Switch>
      <Route exact path="/">
        <AppLayoutScene canOpenContactForm>
          <Landing />
        </AppLayoutScene>
      </Route>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/events/:eventId">
        <AppLayoutScene>
          <EventDetails />
        </AppLayoutScene>
      </Route>
      <Route path="/events">
        <AppLayoutScene>
          <Events />
        </AppLayoutScene>
      </Route>
      <Route path="/projects/:projectId">
        <AppLayoutScene>
          <ProjectDetails />
        </AppLayoutScene>
      </Route>
      <Route path="/projects">
        <AppLayoutScene>
          <Projects />
        </AppLayoutScene>
      </Route>
      <Route path="/about">
        <AppLayoutScene>
          <About />
        </AppLayoutScene>
      </Route>
      <Route path="/internship">
        <AppLayoutScene>
          <Internship />
        </AppLayoutScene>
      </Route>
      <Route path="/newsletter-preferences/:token">
        <AppLayoutScene screenOnly>
          <NewsletterPreferences />
        </AppLayoutScene>
      </Route>
      <Route path="/not-found">
        <AppLayoutScene screenOnly>
          <NotFound />
        </AppLayoutScene>
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default AppLayout;

import React from 'react';
import TeamImage from 'assets/visuals/internship/team.png';

import {
  StyledContainer,
  StyledTextContainer,
  StyledTitle,
  StyledDescription,
  StyledImage,
  StyledSelfPraiseContainer,
  StyledSelfPraiseItem,
  StyledSelfPraiseItemTitle,
  StyledSelfPraiseItemText,
} from './index.styled';
import { StyledSpacing } from '../index.styled';

const ForWhoIsIt = () => {
  return (
    <StyledSpacing>
      <StyledContainer>
        <StyledTextContainer>
          <StyledTitle>
            Kome je Internship
            <br />
            namijenjen?
          </StyledTitle>
          <StyledDescription>
            Naša Udruga svoje dugotrajno postojanje pripisuje volji i entuzijazmu mladih ljudi motiviranih željom za
            usvajanjem i širenjem znanja. Upravo je DUMP Internship proces kojim ciljamo pronaći još takvih ljudi u
            čijim će rukama budućnost Udruge biti sigurna i još uspješnija.
          </StyledDescription>
        </StyledTextContainer>
        <StyledImage src={TeamImage} alt="Dump je tim" />
        <StyledSelfPraiseContainer>
          <StyledSelfPraiseItem>
            <StyledSelfPraiseItemTitle>Prije svega smo tim</StyledSelfPraiseItemTitle>
            <StyledSelfPraiseItemText>
              Jer bez timskog rada i međusobne podrške ne bi postigli sve što smo do sad uspjeli.
            </StyledSelfPraiseItemText>
          </StyledSelfPraiseItem>
          <StyledSelfPraiseItem>
            <StyledSelfPraiseItemTitle>Uvijek korak dalje</StyledSelfPraiseItemTitle>
            <StyledSelfPraiseItemText>
              Radimo ono što volimo. To nas inspirira da naši projekti uvijek budu upečatljivi.
            </StyledSelfPraiseItemText>
          </StyledSelfPraiseItem>
          <StyledSelfPraiseItem>
            <StyledSelfPraiseItemTitle>Rastemo učeći druge</StyledSelfPraiseItemTitle>
            <StyledSelfPraiseItemText>
              Najviše nas veseli kada vidimo da smo svojim radom i podučavanjem inspirirali buduće generacije.
            </StyledSelfPraiseItemText>
          </StyledSelfPraiseItem>
          <StyledSelfPraiseItem>
            <StyledSelfPraiseItemTitle>Gladni znanja</StyledSelfPraiseItemTitle>
            <StyledSelfPraiseItemText>
              Da bismo mogli podučavati druge, prvo moramo i sami neke stvari naučiti!
            </StyledSelfPraiseItemText>
          </StyledSelfPraiseItem>
          <StyledSelfPraiseItem>
            <StyledSelfPraiseItemTitle>Vremenski investirani</StyledSelfPraiseItemTitle>
            <StyledSelfPraiseItemText>
              Ulaganje vremena i truda je osnovni uvjet za napredak u onome čime se baviš.
            </StyledSelfPraiseItemText>
          </StyledSelfPraiseItem>
        </StyledSelfPraiseContainer>
      </StyledContainer>
    </StyledSpacing>
  );
};

export default ForWhoIsIt;

import React, { useRef, useEffect, useState } from 'react';

import AnimatedStack, { getAnimation } from 'components/styled/AnimatedStack';

const AnimatedCarousel = ({ margin = 0, duration, direction = 'right', children, StyledStack }) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const firstChild = ref.current.childNodes?.[0];

      setWidth(firstChild.clientWidth);
    }
  }, [ref]);

  const widthWithMargin = width + margin;
  const firstStackTravelDistance = widthWithMargin * 2;

  const getFirstStackAnimation = () => {
    const endPosition = direction === 'right' ? firstStackTravelDistance : 0 - firstStackTravelDistance;

    return getAnimation(0, endPosition);
  };
  const firstStackOffset = direction === 'right' ? 0 - firstStackTravelDistance : firstStackTravelDistance;

  const getMainStackAnimation = () => {
    return direction === 'right'
      ? getAnimation(0 - widthWithMargin, widthWithMargin)
      : getAnimation(widthWithMargin, 0 - widthWithMargin);
  };

  const lastStackDelay = duration / 2;
  const lastStackOffset = direction === 'right' ? 0 - widthWithMargin : widthWithMargin;

  const Stack = props => {
    if (StyledStack) {
      return <StyledStack {...props} />;
    }

    return <AnimatedStack {...props} />;
  };

  return (
    <div ref={ref}>
      <Stack animation={getFirstStackAnimation()} duration={duration} offset={firstStackOffset} animateOnce>
        {children}
      </Stack>
      <Stack animation={getMainStackAnimation()} duration={duration}>
        {children}
      </Stack>
      <Stack animation={getMainStackAnimation()} duration={duration} delay={lastStackDelay} offset={lastStackOffset}>
        {children}
      </Stack>
    </div>
  );
};

export default AnimatedCarousel;

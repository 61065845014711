import styled from 'styled-components';

const ToolbarMenuItemStyledSvg = styled.div`
  outline: 0;
  border: none;
  background-color: unset;
  margin-left: 5px;
  display: flex;
  align-self: center;
  border-radius: 2px;
  position: relative;
  font-weight: bold;
  
  ${({ active }) => active && `background-color: #F3F6F8`}
`;

export default ToolbarMenuItemStyledSvg;

import React, { useState, useEffect } from 'react';
import { getAllPosts } from 'services/post';
import './styles.css';

import Post from './Post';

const DumpThroughYears = () => {
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState({});
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  useEffect(() => {
    getAllPosts().then(({ data }) => {
      setPost(data[0]);
      setPosts(data);
    });
  }, []);

  const handleNextClick = () => {
    const selectedIndex = posts.indexOf(post);
    let nextPost = posts[selectedIndex + 1];
    !nextPost && (nextPost = posts[0]);
    setPost(nextPost);
    disableButtons(750);
  };

  const handlePreviousClick = () => {
    const selectedIndex = posts.indexOf(post);
    let previousPost = posts[selectedIndex - 1];
    !previousPost && (previousPost = posts[posts.length - 1]);
    setPost(previousPost);
    disableButtons(750);
  };

  const disableButtons = miliseconds => {
    setButtonsDisabled(true);
    setTimeout(() => {
      setButtonsDisabled(false);
    }, miliseconds);
  };

  return (
    <>
      {posts.length > 0 && (
        <section className="dump-through-years">
          <h2 className="dump-through-years__title">DUMP kroz godine</h2>
          <Post
            post={post}
            nextClickHandler={handleNextClick}
            previousClickHandler={handlePreviousClick}
            buttonsDisabled={buttonsDisabled}
          />
        </section>
      )}
    </>
  );
};

export default DumpThroughYears;

import { endpoints } from './common.js';
import axios from 'axios';

export const getPost = id => {
  return axios.get(`${endpoints.post.get}/${id}`);
};

export const getAllPosts = () => {
  return axios.get(endpoints.post.getAll);
};

export const addPost = ({
  title,
  subtitle,
  year,
  quote,
  quoteMemberId,
  quoteMember,
  mainImagePath,
  secondaryImagePath,
}) => {
  return axios.post(endpoints.post.add, {
    title,
    subtitle,
    year,
    quote,
    quoteMemberId,
    quoteMember,
    mainImagePath,
    secondaryImagePath,
  });
};

export const editPost = ({
  id,
  title,
  subtitle,
  year,
  quote,
  quoteMemberId,
  quoteMember,
  mainImagePath,
  secondaryImagePath,
  initialMainImagePath,
  initialSecondaryImagePath,
}) => {
  return axios.post(endpoints.post.edit, {
    id,
    title,
    subtitle,
    year,
    quote,
    quoteMemberId,
    quoteMember,
    mainImagePath,
    secondaryImagePath,
    initialMainImagePath,
    initialSecondaryImagePath,
  });
};

export const deletePost = id => {
  return axios.delete(`${endpoints.post.delete}/${id}`);
};

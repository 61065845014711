import React, { useState, useEffect, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import '../styles.css';

import { ProjectObject, deserializeProject, serializeProject } from 'services/projectLogic';
import { getAllProjects, editProjectSortingOrder, getFieldOfWorkTypes } from 'services/admin';

import ProjectEditor from './ProjectEditor';
import Flex from 'components/styled/Flex';
import ProjectList from 'components/common/ProjectList';
import ProjectDraggable from './ProjectDraggable';
import { DraggableTypes } from 'constants/sectionType';

const ProjectBuilder = () => {
  const [isAdd, setAdd] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [editingIndex, setEditingIndex] = useState(undefined);
  const [fieldOfWorkTypes, setFieldOfWorkTypes] = useState([]);

  const [{ isOver, canDrop }, setDropContainer] = useDrop({
    accept: DraggableTypes.section,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const shouldListAll = useCallback(
    () => !(editingIndex !== undefined || isAdd || isSorting) && allProjects.length !== 0,
    [editingIndex, isAdd, allProjects, isSorting],
  );

  const refreshProjects = useCallback(() => {
    getAllProjects().then(({ data }) => {
      const deserializedProjects = data.map(project => {
        deserializeProject(project);
        return project;
      });
      setAllProjects(deserializedProjects);
    });
  }, []);

  const findProject = useCallback(
    priority => {
      const project = allProjects.filter(item => `${item.priority}` === priority)[0];
      return {
        project,
        index: allProjects.indexOf(project),
      };
    },
    [allProjects],
  );

  const moveProject = useCallback(
    (atIndex, itemIndexOriginal) => {
      if (atIndex === itemIndexOriginal) return;
      const allProjectsNew = allProjects;
      const { project, index } = findProject(itemIndexOriginal);
      allProjectsNew.splice(index, 1);
      allProjectsNew.splice(atIndex, 0, project);
      setAllProjects([...allProjectsNew]);
    },
    [allProjects, findProject],
  );

  const updateProjectOrder = () => {
    const projectOrdered = [...allProjects].map((project, index) => {
      project.priority = index;
      serializeProject(project);
      return project;
    });
    editProjectSortingOrder(projectOrdered).then(() => {
      refreshProjects();
      setIsSorting(false);
    });
  };

  useEffect(() => {
    refreshProjects();
    getFieldOfWorkTypes().then(({ data }) => {
      setFieldOfWorkTypes(data);
    });
  }, [refreshProjects]);

  if (isSorting) {
    const isActive = isOver && canDrop;
    return (
      <Flex justifyContent="center" direction="column" alignItems="center" margin="10px 0" ref={setDropContainer}>
        <Flex justifyContent="center" margin="10px">
          <button className="button-admin submit-button" onClick={() => updateProjectOrder()}>
            Save sorted
          </button>
          <button className="button-admin cancel-button" onClick={() => setIsSorting(false)}>
            Cancel
          </button>
        </Flex>
        {allProjects.map((project, index) => (
          <ProjectDraggable
            key={index}
            index={`${project.priority}`}
            findProject={findProject}
            moveProject={moveProject}
            project={project}
            isActive={isActive}
            isSorting
          />
        ))}
      </Flex>
    );
  }

  return (
    <div>
      <Flex justifyContent="space-around" wrap="wrap" margin="10px 0">
        {!isAdd && (
          <>
            <button className="button-admin submit-button" onClick={() => setAdd(true)}>
              Add new project
            </button>
            <button className="button-admin submit-button" onClick={() => setIsSorting(true)}>
              Start sorting
            </button>
          </>
        )}
      </Flex>
      {isAdd && (
        <ProjectEditor
          setAdd={setAdd}
          project={new ProjectObject()}
          refreshProjects={refreshProjects}
          lastIndex={allProjects.length}
          fieldOfWorkTypes={fieldOfWorkTypes}
        />
      )}
      {editingIndex !== undefined && (
        <ProjectEditor
          allProjects={allProjects}
          project={allProjects[editingIndex]}
          setEditingIndex={setEditingIndex}
          refreshProjects={refreshProjects}
          fieldOfWorkTypes={fieldOfWorkTypes}
          isEdit
        />
      )}
      {shouldListAll() && (
        <ProjectList
          projects={allProjects}
          setEditingIndex={setEditingIndex}
          refreshProjects={refreshProjects}
          isEdit
        />
      )}
    </div>
  );
};

export default ProjectBuilder;

import styled from 'styled-components';
import Flex from 'components/styled/Flex';
import AnimatedStack from 'components/styled/AnimatedStack';

export const InternshipInfoContainer = styled(Flex)`
  margin-top: 225px;

  @media screen and (max-width: 900px) {
    margin-top: 90px;
    flex-direction: column-reverse;
  }
`;

export const InternshipDescriptionFlex = styled(Flex)`
  margin: 125px 200px 0 50px;

  @media screen and (max-width: 1320px) {
    margin: 125px 50px 0 20px;
  }

  @media screen and (max-width: 1200px) {
    margin-right: 0;
  }

  @media screen and (max-width: 900px) {
    margin: 0 0 40px 200px;
  }

  @media screen and (max-width: 700px) {
    margin-left: 100px;
  }

  @media screen and (max-width: 600px) {
    margin-left: 50px;
  }

  @media screen and (max-width: 400px) {
    margin: 0 20px 40px;
  }
`;

export const FieldsStack = styled(AnimatedStack)`
  bottom: 130px;
  left: -180px;
  z-index: 0;

  @media screen and (max-width: 600px) {
    bottom: 100px;
  }

  @media screen and (max-width: 530px) {
    bottom: 100px;
  }
`;

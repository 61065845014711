import React, { forwardRef, useState } from 'react';
import '../styles.css';
import ImagePicker from 'components/common/ImagePicker';
import ProjectSectionStyled from 'components/styled/ProjectSectionStyled';
import { getTextEditorValue } from 'components/common/ProjectDetailsDisplay';
import RichTextEditor from 'components/common/RichTextEditor';

import {
  getStyledComponentProps,
  isImageOnly,
  hasImage,
  isTripleTextEditor,
  isSingleTextEditor,
  isImageGallery,
} from 'services/projectSectionLogic';

const TEXT_EDITOR_MAX_CHARACTERS = 350;

const ProjectSectionEditor = forwardRef(({ item, setItems, projectSections, findItem }, ref) => {
  const { section, index } = findItem(`${item.sectionOrder}`);
  const [inputTitle, _setInputTitle] = useState(item.title);
  const setValue = value => {
    const projectSectionsNew = projectSections;
    const otherTextValues = item.text.filter(element => element.editorIndex !== value[0].editorIndex);

    const newTextValue = [...otherTextValues, ...value];

    projectSectionsNew.splice(index, 1);
    projectSectionsNew.splice(index, 0, { ...section, text: newTextValue });
    setItems([...projectSectionsNew]);
  };

  const setItemImagePath = imagePath => {
    const projectSectionsNew = projectSections;
    projectSectionsNew.splice(index, 1);
    projectSectionsNew.splice(index, 0, { ...section, imagePath });
    setItems([...projectSectionsNew]);
  };

  const setInputTitle = ({ target: { value } }) => {
    _setInputTitle(value);
    const projectSectionsNew = projectSections;
    projectSectionsNew.splice(index, 1);
    projectSectionsNew.splice(index, 0, { ...section, title: value });
    setItems([...projectSectionsNew]);
  };

  const setTextImagePath = (imagePickerIndex, imagePath) => {
    const projectSectionsNew = projectSections;
    const section = projectSectionsNew[index];
    projectSectionsNew.splice(index, 1);
    section.text[imagePickerIndex] = imagePath;
    projectSectionsNew.splice(index, 0, { ...section });
    setItems([...projectSectionsNew]);
  };

  const removeTextImage = textImageIndex => {
    const projectSectionsNew = projectSections;
    projectSectionsNew[index].text.splice(textImageIndex, 1);
    setItems([...projectSectionsNew]);
  };

  const addTextImage = () => {
    const defaultImageText = {
      folder: 'Default',
      image: 'placeholder.png',
    };

    const projectSectionsNew = projectSections;
    projectSectionsNew[index].text.push(defaultImageText);
    setItems([...projectSectionsNew]);
  };

  return (
    <>
      <div className="project__section__title">
        <label className="label">Title</label>
        <input className="input" type="text" value={inputTitle} onChange={setInputTitle} autoFocus />
      </div>
      <ProjectSectionStyled
        ref={ref}
        {...getStyledComponentProps(item)}
        isTripleTextEditor={isTripleTextEditor(item)}
        isSingleTextEditor={isSingleTextEditor(item)}
        isImageGallery={isImageGallery(item)}
      >
        {!isImageOnly(item) && (
          <div className="editor__wrapper">
            <div className="project-section__text__wrapper">
              <RichTextEditor
                setValueCallback={setValue}
                text={getTextEditorValue(item, 0)}
                index={0}
                maxCharCount={TEXT_EDITOR_MAX_CHARACTERS}
              />
            </div>
            {isTripleTextEditor(item) && (
              <>
                <div className="project-section__text__wrapper">
                  <RichTextEditor
                    setValueCallback={setValue}
                    text={getTextEditorValue(item, 1)}
                    index={1}
                    maxCharCount={TEXT_EDITOR_MAX_CHARACTERS}
                  />
                </div>

                <div className="project-section__text__wrapper">
                  <RichTextEditor
                    setValueCallback={setValue}
                    text={getTextEditorValue(item, 2)}
                    index={2}
                    maxCharCount={TEXT_EDITOR_MAX_CHARACTERS}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {hasImage(item) && (
          <>
            <ImagePicker imagePath={item.imagePath} imagePathChangedCallback={setItemImagePath} />
            {isImageGallery(item) && (
              <>
                {item.text.map((image, index) => {
                  return (
                    <div key={index}>
                      <button className="button-admin cancel-button" onClick={() => removeTextImage(index)}>
                        Delete image
                      </button>
                      <ImagePicker
                        imagePath={image}
                        imagePathChangedCallback={imagePath => setTextImagePath(index, imagePath)}
                        isTextImage
                      />
                    </div>
                  );
                })}
                <button className="button submit-button" onClick={addTextImage}>
                  Add photo
                </button>
              </>
            )}
          </>
        )}
      </ProjectSectionStyled>
    </>
  );
});
export default ProjectSectionEditor;

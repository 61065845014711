import { endpoints } from './common.js';
import axios from 'axios';

export const getMember = id => {
  return axios.get(`${endpoints.member.get}/${id}`);
};

export const getMembers = (memberType = -1) => {
  return axios.get(`${endpoints.member.getAll}/${memberType}`);
};

export const addMember = ({
  firstName,
  lastName,
  dateOfBirth,
  memberType,
  description,
  joinDate,
  exitDate,
  email,
  activeImagePath,
  hoverImagePath,
  alumniImagePath,
  isDeceased,
}) => {
  return axios.post(endpoints.member.add, {
    firstName,
    lastName,
    dateOfBirth,
    memberType,
    description,
    joinDate,
    exitDate,
    email,
    activeImagePath,
    hoverImagePath,
    alumniImagePath,
    isDeceased,
  });
};

export const editMember = ({
  id,
  firstName,
  lastName,
  dateOfBirth,
  memberType,
  description,
  joinDate,
  exitDate,
  email,
  activeImagePath,
  hoverImagePath,
  alumniImagePath,
  initialActiveImagePath,
  initialAlumniImagePath,
  isDeceased,
}) => {
  return axios.post(endpoints.member.edit, {
    id,
    firstName,
    lastName,
    dateOfBirth,
    memberType,
    description,
    joinDate,
    exitDate,
    email,
    activeImagePath,
    hoverImagePath,
    alumniImagePath,
    initialActiveImagePath,
    initialAlumniImagePath,
    isDeceased,
  });
};

export const deleteMember = id => {
  return axios.delete(`${endpoints.member.delete}/${id}`);
};

export const getMemberTypeEnum = async () => {
  const memberTypeEnum = await axios.get(endpoints.member.getMemberTypeEnum);
  const initialValue = {};
  return memberTypeEnum.data.reduce((obj, name, index) => {
    return { ...obj, [name]: index };
  }, initialValue);
};

import styled from 'styled-components';

export const StyledContainer = styled.section`
  margin: 0 112px;

  @media (max-width: 767px) {
    margin: 0 24px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 112px;
  line-height: 112px;
  color: #282d30;
  margin-bottom: 100px;
  margin-top: 232px;

  @media (max-width: 1366px) {
    font-size: 180px;
  }

  @media (max-width: 1280px) {
    font-size: 160px;
  }

  @media (max-width: 500px) {
    font-size: 80px;
  }
`;

export const StyledCardsWrapper = styled.section`
  --card-width: 268px;
  --card-height: 592px;
  --card-spacing: 48px;
  --cards-per-row: 4;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: calc(var(--card-width) * var(--cards-per-row) + var(--card-spacing) * (var(--cards-per-row) - 1));

  & > article {
    width: var(--card-width);
    height: var(--card-height);
  }

  @media (max-width: 1439px) {
    --cards-per-row: 2;
  }

  @media (max-width: 640px) {
    --card-width: 168px;
    --card-height: 371px;
    --card-spacing: 20px;
  }

  @media (max-width: 480px) {
    --card-width: 148px;
    --card-height: 320px;
  }

  @media (max-width: 370px) {
    --card-width: 113px;
    --card-height: 244px;
  }
`;

export const StyledCardWrapper = styled.article`
  border-radius: 16px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20px;
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  margin-bottom: 16px;

  &.enter-done {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s cubic-bezier(0.01, 0.72, 0.4, 1.01);
  }

  &:hover {
    transition: transform 200ms ease;
    transform: scale(1.05);
    cursor: pointer;
    background-image: url(${({ hoverImage }) => hoverImage});
  }

  @media (max-width: 400px) {
    transform: translate3d(-50px, 0, 0);
  }
`;

export const StyledLabel = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 50px;
  color: #ffffff;
  font-size: 20px;
  text-align: center;

  @media (max-width: 580px) {
    font-size: 12px;
  }
`;

import React, { useState, useEffect } from 'react';

import LibraryAdd from './LibraryAdd';
import ImagePreview from './ImagePreview';
import { getAllFileNamesFromLibrary } from 'services/admin';
import Loading from 'components/styled/Loading';

const Library = () => {
  const [allImages, setAllImages] = useState([]);
  const [imageSearch, setImageSearch] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAllFileNamesFromLibrary().then(({ data }) => {
      setAllImages(data);
      setLoading(false);
    });
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="library-screen">
      <LibraryAdd updateImages={setAllImages} images={allImages} />
      <input
        type="text"
        className="input"
        placeholder="Enter your image search here"
        value={imageSearch}
        onChange={e => setImageSearch(e.target.value)}
      />
      <div className="image-preview__container ">
        {allImages
          .filter(image => image.includes(imageSearch))
          .map((imageName, index) => (
            <ImagePreview key={index} name={imageName} images={allImages} setAllImages={setAllImages} />
          ))}
      </div>
    </div>
  );
};

export default Library;

import React, { useState, useEffect, createRef } from 'react';
import { Editor, Transforms, Range } from 'slate';
import { useSlate } from 'slate-react';

import DropdownStyled from '../DropdownStyled';
import color from 'assets/icons/color.svg';
import { ElementType } from 'constants/elementType';
import ToolbarMenuItemStyledSvg from '../ToolbarMenuItemStyled';

export const withColor = editor => {
  const { isInline } = editor;

  editor.isInline = element => (element.type === ElementType.color ? true : isInline(element));
  return editor;
};

const isColorActive = editor => {
  const [color] = Editor.nodes(editor, {
    match: n => n.type === ElementType.color,
  });

  return !!color;
};

const wrapColor = (editor, colorHex, selection) => {
  if (isColorActive(editor)) {
    Transforms.unwrapNodes(editor, { match: n => n.Type === ElementType.color });
    return;
  }

  if (Range.isCollapsed(selection)) return;

  const colorBlock = {
    type: ElementType.color,
    color: colorHex,
    children: [],
  };

  editor.selection = selection;

  Transforms.wrapNodes(editor, colorBlock, {
    at: selection,
    split: true
  });
  Transforms.collapse(editor, { edge: 'end' });
};

const ColorDropdown = ({ editor, setIsColorPickerActive, currentSelection }) => {
  const [selection] = useState(currentSelection);
  const [colorHex, setColorHex] = useState('');
  const ref = createRef(null);

  const handleOutsideClick = ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      setIsColorPickerActive(false);
      setColorHex('');
    }
  };

  const onSubmit = () => {
    wrapColor(editor, colorHex, selection);
    setColorHex('');
    setIsColorPickerActive(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  return (
    <DropdownStyled ref={ref}>
      <label className="label">Color:</label>
      <input autoFocus className="input" type="text" value={colorHex} onChange={e => setColorHex(e.target.value)} />
      <button className="button submit-button" onClick={onSubmit}>
        Submit
      </button>
    </DropdownStyled>
  );
};

const ColorButton = () => {
  const [isColorPickerActive, setIsColorPickerActive] = useState(false);
  const editor = useSlate();

  return (
    <ToolbarMenuItemStyledSvg
      active={isColorActive(editor)}
      onMouseDown={event => {
        event.preventDefault();
        setIsColorPickerActive(true);
      }}
    >
      <img src={color} alt="color picker" />
      {isColorPickerActive && (
        <ColorDropdown
          editor={editor}
          setIsColorPickerActive={setIsColorPickerActive}
          currentSelection={editor.selection}
        />
      )}
    </ToolbarMenuItemStyledSvg>
  );
};

export default ColorButton;

import styled, { keyframes } from 'styled-components';

export const getAnimation = (start, end) => {
  return keyframes`
    0% {
      transform: translateX(${start ? start : 0}px);
    }
    100% {
      transform: translateX(${end ? end : 0}px);
    }
  `;
};

const AnimatedStack = styled.div`
  animation: ${({ animation }) => animation} ${({ duration }) => duration}s linear
    ${({ animateOnce }) => (animateOnce ? '1' : 'infinite')};
  ${({ delay }) => delay && `animation-delay: ${delay}s;`}
  ${({ offset }) => offset && `transform: translateX(${offset}px);`}

  position: absolute;
  display: flex;
  justify-content: flex-start;
`;

export default AnimatedStack;

import React from 'react';
import quote from 'assets/icons/quote.svg';
import useFallbackImage from 'hooks/useFallbackImage';

import { S3_BUCKET_BASE_URL as base } from 'services/common';

const Text = ({ post }) => {
  
  const { setFallbackImageSource } = useFallbackImage("./images/posts/fallback.png");
  return (
    <div>
      <p className="post__year">{post.year}</p>
      <p className="post__title">{post.title}</p>
      <p className="post__description">{post.subtitle} </p>
      <div className="post__quote">
        <img className="quote__icon" src={quote} alt="Quote icon" />
        <p className="quote__text">{post.quote}</p>
      </div>
      <figure className="post__member">
        <img
          className="member__image"
          src={`${base}/images/${post.quoteMember.alumniImagePath.folder}/${post.quoteMember.alumniImagePath.image}`}
          onError={setFallbackImageSource}
          alt={`${post.quoteMember.firstName} ${post.quoteMember.lastName}`}
        />
        <figcaption>
          <span className="member__fullname">
            {post.quoteMember.firstName} {post.quoteMember.lastName}
          </span>
          <br />
          <span className="member__status">
            {post.quoteMember.memberType === 'Active' ? 'aktivni član' : 'bivši član'}
          </span>
        </figcaption>
      </figure>
    </div>
  );
};

export default Text;

import React from 'react';

import AlumnusMemberDetails from './AlumnusMemberDetails';
import { S3_BUCKET_BASE_URL as base } from 'services/common';
import ImageLoader from 'components/common/ImageLoader';
import useFallbackImage from 'hooks/useFallbackImage';

const AlumnusMember = ({ member }) => {
  const { setFallbackImageSource } = useFallbackImage('./images/members/alumni/fallback.png');

  return (
    <div className='list__alumnus-wrapper'>
      <div className={`list__alumnus ${member.isDeceased && 'list__alumnus--deceased'}`}>
        <ImageLoader
          src={`${base}/images/${member.alumniImagePath.folder}/${member.alumniImagePath.image}`}
          onError={setFallbackImageSource}
          className='list__alumnus-image'
          alt={`${member.firstName} ${member.lastName}`}
        />
      </div>
      <AlumnusMemberDetails member={member} />
    </div>
  );
};

export default AlumnusMember;

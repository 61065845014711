import React, { useState, useEffect } from 'react';

import DevelopmentModal from '../Modals/Development';
import MarketingModal from '../Modals/Marketing';
import DesignModal from '../Modals/Design';
import MultimediaModal from '../Modals/Multimedia';
import Card from './Card';
import { StyledContainer, StyledTitle, StyledCardsWrapper } from './index.styled';
import { StyledSpacing } from '../index.styled';

import { Discipline } from '../consts';
import { disciplineCardsConfig } from './const';

const DisciplineCards = ({ internshipUrl, preactivatedDiscipline }) => {
  const [activeDisciplineModal, setActiveDisciplineModal] = useState(null);

  const closeDisciplineModal = () => {
    setActiveDisciplineModal(null);
  };

  useEffect(() => {
    if (activeDisciplineModal !== null) {
      document.body.style.overflowY = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflowY = 'initial';
      document.body.style.touchAction = 'auto';
    }

    return () => {
      document.body.style.overflowY = 'initial';
      document.body.style.touchAction = 'auto';
    };
  }, [activeDisciplineModal]);

  useEffect(() => {
    if (!preactivatedDiscipline) {
      return;
    }
    
    setActiveDisciplineModal(preactivatedDiscipline)
  }, [preactivatedDiscipline]);

  return (
    <StyledSpacing>
      <StyledContainer>
        <StyledTitle>
          Koji si đir?
        </StyledTitle>

        <StyledCardsWrapper>
          {Object.keys(disciplineCardsConfig).map((key, index) => {
            const { label, image, hoverImage } = disciplineCardsConfig[key];

            return (
              <Card
                onClick={() => setActiveDisciplineModal(key)}
                key={key}
                label={label}
                image={image}
                hoverImage={hoverImage}
                index={index}
              />
            );
          })}
        </StyledCardsWrapper>
      </StyledContainer>
      <DevelopmentModal
        internshipUrl={internshipUrl}
        isOpen={activeDisciplineModal === Discipline.development}
        close={closeDisciplineModal}
        setActiveDisciplineModal={setActiveDisciplineModal}
      />
      <MarketingModal
        internshipUrl={internshipUrl}
        isOpen={activeDisciplineModal === Discipline.marketing}
        close={closeDisciplineModal}
        setActiveDisciplineModal={setActiveDisciplineModal}
      />
      <DesignModal
        internshipUrl={internshipUrl}
        isOpen={activeDisciplineModal === Discipline.design}
        close={closeDisciplineModal}
        setActiveDisciplineModal={setActiveDisciplineModal}
      />
      <MultimediaModal
        internshipUrl={internshipUrl}
        isOpen={activeDisciplineModal === Discipline.multimedia}
        close={closeDisciplineModal}
        setActiveDisciplineModal={setActiveDisciplineModal}
      />
    </StyledSpacing>
  );
};

export default DisciplineCards;

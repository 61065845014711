import React from 'react';
import at from 'assets/icons/at.png';

import Flex from 'components/styled/Flex';

const ActiveMemberDetails = ({ member }) => {
  return (
    <div className={`active__details`} onClick={e => e.stopPropagation()}>
      <p className="details__fullname--active">
        <span className="details__fullname--bold">{member.firstName}</span> {member.lastName}
      </p>
      <Flex className="details__email-container" alignItems="center">
        <img className="email-container__icon" src={at} alt="email icon" />
        <a className="email-container__email" href={`mailto:${member.email}`}>
          {member.email}
        </a>
      </Flex>
      <p className="details__description">{member.description}</p>
    </div>
  );
};

export default ActiveMemberDetails;

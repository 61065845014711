import ImageLoader from 'components/common/ImageLoader';
import React, { forwardRef, useState } from 'react';
import { S3_BUCKET_BASE_URL as base } from 'services/common';
import ActiveMemberDetails from './ActiveMemberDetails';
import useFallbackImage from 'hooks/useFallbackImage';
import useHoverFallbackImage from 'hooks/useHoverFallbackImage';

const ActiveMember = forwardRef(({ member, idOfSelectedMember, memberClickHandler }, memberRef) => {
  const isSelected = member.id === idOfSelectedMember;

  const { folder: activeImgFolder, image: activeImg } = member.activeImagePath;
  const { folder: hoverImgFolder, image: hoverImg } = member.hoverImagePath;

  const baseUrl = `${base}/images`;
  const hoverImgUrl = `${baseUrl}/${hoverImgFolder}/${hoverImg}`;
  const activeImgUrl = `${baseUrl}/${activeImgFolder}/${activeImg}`;

  const { setFallbackImageSource } = useFallbackImage('./images/members/active/fallback.png');
  const { setHoverFallbackImageSource } = useHoverFallbackImage(activeImgUrl);

  return (
    <div className="list__active" onClick={e => memberClickHandler(member.id, e)} ref={isSelected ? memberRef : null}>
      <ImageLoader
        src={activeImgUrl}
        onError={setFallbackImageSource}
        className={`active__image
           ${hoverImg && 'active__image--opacity'} 
           ${isSelected && hoverImg && 'remove-image'} 
           ${isSelected && !hoverImg && 'active__image--scaled'}`}
        alt="active-image"
      />
      <ImageLoader
        src={hoverImgUrl}
        onError={setHoverFallbackImageSource}
        className={`hover__image ${isSelected && 'active__image--scaled'}`}
        alt="hover-image"
      />
      {isSelected && <ActiveMemberDetails member={member} />}
    </div>
  );
});

export default ActiveMember;

import React from 'react';
import styled from 'styled-components';

const LeafStyled = styled.span`
  ${({ bold }) => bold && `font-weight: bold`}
  ${({ italic }) => italic && `font-style: italic`}
  ${({ underline }) => underline && `text-decoration: underline`}
`;

const Leaf = ({ children, attributes, leaf }) => {
  return (
    <LeafStyled {...leaf} {...attributes}>
      {leaf.code && <code>{children}</code>}
      {!leaf.code && <>{children}</>}
    </LeafStyled>
  );
};

export default Leaf;

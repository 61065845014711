import styled from 'styled-components';

const ToolbarDropdownStyled = styled.div`
  position: absolute;
  top: 34px;
  left: 0;
  background-color: #e5eef8;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 1px solid #000;
  z-index: 2;

  & > .label {
    font-size: 12px;
  }

  & > .input {
    font-size: 12px;
    margin-left: 2px;
  }

  & > .button {
    margin-left: 2px;
    font-size: 12px;
    padding: 2px 4px;
  }
`;

export default ToolbarDropdownStyled;
import React from 'react';

import { ReactComponent as XIcon } from 'assets/icons/x-white.svg';
import MultiHeaderImage from 'assets/visuals/internship/multi-header.png';
import MultiFirstIcon from 'assets/icons/internship/modals/multi-icon-1.svg';
import MultiSecondIcon from 'assets/icons/internship/modals/multi-icon-2.svg';
import MultiThirdIcon from 'assets/icons/internship/modals/multi-icon-3.svg';
import MultiFirstImage from 'assets/visuals/internship/multi-1.png';
import MultiSecondImage from 'assets/visuals/internship/multi-2.png';
import MultiThirdImage from 'assets/visuals/internship/multi-3.png';
import MultiHowItLooksImage from 'assets/visuals/internship/multi-how-it-looks.png';
import MultiExpectationsImageDesktop from 'assets/visuals/internship/multi-expectations.png';
import MultiExpectationsImageMobile from 'assets/visuals/internship/multi-expectations-mobile.png';

import Header from './Header';
import TextRightSection from './TextRightSection';
import TextLeftSection from './TextLeftSection';
import HowItLooksSection from './HowItLooksSection';
import WhatsComingSection from './WhatsComingSection';
import SuggestedDisciplines from './SuggestedDisciplines';

import { Discipline } from '../consts';
import { Color, StyledWrapper, StyledContainer, StyledCloseButton, StyledTextSectionsWrapper } from './index.styled';

const MultimediaModal = ({ internshipUrl, isOpen, close, setActiveDisciplineModal }) => {
  return (
    <StyledWrapper isOpen={isOpen} color={Color.purple}>
      {isOpen && (
        <StyledContainer>
          <StyledCloseButton onClick={close}>
            <XIcon />
          </StyledCloseButton>
        </StyledContainer>
      )}
      <Header
        internshipUrl={internshipUrl}
        backgroundColor={Color.lightPurple}
        seeThroughColor={Color.seeThroughPurple}
        title="Multimedija"
        description="Što je zapravo multimedija? U Udruzi najčešće fotografiramo i snimamo. To uključuje obilježavanje događaja, fotografiranje portreta te stvaranje sadržaja za društvene mreže. Naravno, da multimedija bude cijela, bavimo se i produkcijom javnih događaja, poput DUMP Days konferencije."
        perks={[
          {
            iconSrc: MultiFirstIcon,
            label: 'Fotografija',
          },
          {
            iconSrc: MultiSecondIcon,
            label: 'Videografija',
          },
          {
            iconSrc: MultiThirdIcon,
            label: 'Produkcija',
          },
        ]}
        buttonLabel="Prijavi se na Multimediju"
        buttonColor={Color.lightGreen}
        imageSrc={MultiHeaderImage}
      />
      <StyledTextSectionsWrapper>
        <TextRightSection
          imageSrc={MultiFirstImage}
          title="Fotografija"
          description="Prvo se učiš pravilnom korištenju fotografske opreme, a zatim ćeš na praktičnim radionicama steći uvid u sve što fotografija zapravo obuhvaća. Smišljat ćeš razne scenarije i igrati se s prirodnim i studijskih osvjetljenjem, a u konačnici i obraditi te retuširati svoje radove."
          tags={[
            'Osnove fotografije',
            'Svjetlo i sjene',
            'Pravila ekspozicije',
            'Korištenje aparata',
            'Obrada i retuširanje',
          ]}
        />
        <TextLeftSection
          title="Videografija"
          description="Kao i kod fotografije, prvo se upoznaješ s opremom za snimanje. Imat ćeš praktične radionice na kojima ćeš proći cijeli proces izrade videa, od osmišljavanja scenarija za snimanje, preko postavljanja seta i snimanja videa kroz razne tehnike. Naravno, sve snimljeno ćeš također i obraditi, dodavajući razne efekte."
          tags={['Oprema za snimanje', 'Montaža videa', 'Tehnike snimanja']}
          imageSrc={MultiSecondImage}
        />
        <TextRightSection
          imageSrc={MultiThirdImage}
          title="Produkcija"
          description="Za kraj, zakoračiti ćeš u svijet produkcije koja se brine da svaki naš događaj prođe u najboljem svjetlu! Naučiti ćeš kako ispravno postaviti ozvučenje, pobrinuti se za projekciju slike i kako korištenjem osvjetljenja pridonijeti atmosferi događaja. Također, ne smijemo zaboraviti na prijenos takvog događaja uživo kroz live stream kako bi došao do što šire publike."
          tags={['Teorija zvuka', 'Uporaba miksete', 'Mikrofoni i pojačala', 'Rasvjeta', 'Produciranje događaja']}
        />
      </StyledTextSectionsWrapper>
      <HowItLooksSection
        title="Kako će sve to izgledat?"
        imageSrc={MultiHowItLooksImage}
        features={[
          {
            title: 'Predavanja',
            description: 'Svaka cjelina kreće s manjim brojem teorijskih predavanja.',
          },
          {
            title: 'Radionice',
            description:
              'Nakon predavanja nastavljamo s praktičnim radionicama na kojima primjenjujemo naučeno znanje kroz razne scenarije.',
          },
          {
            title: 'Usvajanje znanja',
            description:
              'Najviše vremena ćemo učiti upravo kroz praktične primjere u stvarnim scenarijima, prolazeći razne žanrove fotografije i videografije. Time dobivamo uvid u svo potrebno znanje i postavljamo temelje za daljnje učenje.',
          },
          {
            title: 'Grupni rad',
            description:
              'Iako će većina učenja biti individualno, suradnja u Udruzi nam je dosta važna. Kroz grupne radove ćeš se upoznati s drugim Internima, i nadamo se i sprijateljiti!',
          },
        ]}
      />
      <WhatsComingSection
        internshipUrl={internshipUrl}
        color={Color.lightPurple}
        imageSrcDesktop={MultiExpectationsImageDesktop}
        imageSrcMobile={MultiExpectationsImageMobile}
        title="Što te očekuje?"
        listItems={[
          'Upoznavanje sa svijetom multimedije',
          'Zajednički napredak uz savjetovanje i mentorstvo',
          'Rad s profesionalnom opremom',
          'Iskustvo na stvarnim projektima',
        ]}
        tickColor={Color.lightGreen}
        buttonLabel="Prijavi se na Multimediju"
        buttonColor={Color.lightGreen}
      />
      <SuggestedDisciplines
        activeDisciplineKey={Discipline.multimedia}
        setActiveDisciplineModal={setActiveDisciplineModal}
      />
    </StyledWrapper>
  );
};

export default MultimediaModal;

import React from 'react';

import {
  StyledWrapperContainer,
  StyledWrapper,
  StyledContainer,
  StyledTextContainer,
  StyledTitle,
  StyledDescription,
  StyledPerksWrapper,
  StyledPerk,
  StyledIcon,
  StyledPerkLabel,
  StyledButtonContainer,
  StyledButton,
  StyledImage,
} from './index.styled';

const Header = ({
  internshipUrl,
  backgroundColor,
  title,
  description,
  perks,
  buttonLabel,
  buttonColor,
  imageSrc,
  imageWidth,
  imageHeight,
  seeThroughColor,
}) => {
  return (
    <StyledWrapperContainer color={backgroundColor}>
      <StyledWrapper>
        <StyledContainer>
          <StyledTextContainer>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description}</StyledDescription>
            <StyledPerksWrapper>
              {perks?.map(({ iconSrc, label }, index) => (
                <StyledPerk key={index}>
                  <StyledIcon src={iconSrc} alt={label} width={40} height={40} />
                  <StyledPerkLabel>{label}</StyledPerkLabel>
                </StyledPerk>
              ))}
            </StyledPerksWrapper>
            {internshipUrl && (
              <StyledButtonContainer color={seeThroughColor}>
                <a href={internshipUrl} target="_blank" rel="noopener noreferrer">
                  <StyledButton color={buttonColor}>{buttonLabel}</StyledButton>
                </a>
              </StyledButtonContainer>
            )}
          </StyledTextContainer>
          <StyledImage src={imageSrc} width={imageWidth} height={imageHeight} alt={title} />
        </StyledContainer>
      </StyledWrapper>
    </StyledWrapperContainer>
  );
};

export default Header;

import React, { useState, useEffect, useRef } from 'react';
import { getMembers, getMemberTypeEnum } from 'services/member';
import './styles.css';

import Flex from 'components/styled/Flex';
import ActiveMember from './ActiveMember';
import AlumnusMember from './AlumnusMember';

const Members = () => {
  const [activeMembers, setActiveMembers] = useState([]);
  const [activeMembersLoading, setActiveMembersLoading] = useState(false);
  const [alumniMembers, setAlumniMembers] = useState([]);
  const [alumniMembersLoading, setAlumniMembersLoading] = useState(false);
  const [idOfSelectedMember, setIdOfSelectedMember] = useState(-1);

  const memberRef = useRef(null);

  useEffect(() => {
    setActiveMembersLoading(true);
    setAlumniMembersLoading(true);
    getMemberTypeEnum().then(memberTypes => {
      setMembers(memberTypes.Active);
      setMembers(memberTypes.Alumni);
    });

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const setMembers = memberType => {
    getMembers(memberType).then(({ data }) => {
      const membersSorted = data.sort((a, b) => new Date(a.joinDate) - new Date(b.joinDate));

      if (memberType === 0) {
        setActiveMembers(membersSorted);
        setActiveMembersLoading(false);
      } else {
        setAlumniMembers(membersSorted);
        setAlumniMembersLoading(false);
      }
    });
  };

  const handleMemberClick = (id, e) => {
    e.stopPropagation();

    if (id === idOfSelectedMember) setIdOfSelectedMember(-1);
    else setIdOfSelectedMember(id);
  };

  const handleOutsideClick = e => {
    if (memberRef.current && !memberRef.current.contains(e.target)) setIdOfSelectedMember(-1);
  };

  return (
    <section className="members">
      {activeMembers.length > 0 && (
        <div className="members__actives">
          <h2 className="actives__title">Članovi</h2>
          <Flex className="actives__list" wrap="wrap" justifyContent="center">
            {!activeMembersLoading &&
              activeMembers.map((member, index) => (
                <ActiveMember
                  ref={memberRef}
                  member={member}
                  memberClickHandler={handleMemberClick}
                  idOfSelectedMember={idOfSelectedMember}
                  key={index}
                />
              ))}
          </Flex>
        </div>
      )}
      {alumniMembers.length > 0 && (
        <div className="members__alumni">
          <h3 className="alumni__title">Alumni</h3>
          <Flex className="alumni__list" justifyContent="center" wrap="wrap">
            {!alumniMembersLoading &&
              alumniMembers.map((member, index) => <AlumnusMember member={member} key={index} />)}
          </Flex>
        </div>
      )}
    </section>
  );
};

export default Members;

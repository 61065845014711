import React, { useState, useEffect } from 'react';
import { eventEventPush } from 'services/gtm';
import './styles.css';
import CounterWithContactForm from 'components/common/CountersWithContactForm';
import { useLocation, Redirect } from 'react-router-dom';
import Event from 'components/common/Event';
import ShapeBig from 'assets/shapes/shape_black.svg';
import ShapeSmall from 'assets/shapes/shape_black-small.svg';
import EventSection from 'components/common/EventSection';
import Flex from 'components/styled/Flex';
import { getEventById } from 'services/event';
import styled from 'styled-components';
import Loader from 'components/common/Loading/Loading';

const EventSectionsWrapper = styled(Flex)`
  margin: 0 133px 0 70px;

  @media screen and (max-width: 450px) {
    margin: 0 10px 0 40px;
  }

  @media screen and (max-width: 350px) {
    margin-left: 30px;
  }

  @media screen and (max-width: 330px) {
    margin-left: 20px;
  }
`;

const EventDetails = () => {
  let location = useLocation();
  const [eventId, setEventId] = useState(Number(location.pathname.split('/')[2].split('-')[0]));
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEventById({ eventId })
      .then(({ data }) => {
        setEvent(data);
        setLoading(false);
      })
      .catch(() => setEventId('Wrong input'));
  }, [location.pathname, eventId]);

  useEffect(() => {
    if (!event) {
      return;
    }

    const eventYear = new Date(event.dateOfBeginning)?.getFullYear();

    eventEventPush(`${event.name} ${eventYear}`, eventId);
  }, [event, eventId]);

  if (typeof eventId !== 'number') {
    return <Redirect to="/not-found" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!event) {
    return null;
  }

  return (
    <main>
      <div className="event__details">
        <Event event={event} />
        <img src={ShapeBig} alt="shape" />
        <img src={ShapeSmall} alt="shape" />
      </div>

      <EventSectionsWrapper wrap="wrap">
        {event.eventSections.map((eventSection, index) => (
          <EventSection key={index} eventSection={eventSection} />
        ))}
      </EventSectionsWrapper>

      <CounterWithContactForm />
    </main>
  );
};

export default EventDetails;

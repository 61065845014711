import iconProjectManagement from 'assets/icons/field-project-management.svg';
import iconDevelopment from 'assets/icons/field-development.svg';
import iconDesign from 'assets/icons/field-design.svg';
import iconMultimedia from 'assets/icons/field-multimedia.svg';
import iconUiDevelopment from 'assets/icons/field-ui-development.svg';
import iconPresentationSkills from 'assets/icons/field-presentation-skills.svg';
import iconMarketing from 'assets/icons/field-marketing.svg';

const fieldsOfWork = {
  Design: {
    displayName: 'Dizajn',
    image: iconDesign,
    backgroundColor: '#cb535329',
    anchor: 'design',
  },
  Marketing: {
    displayName: 'Marketing',
    image: iconMarketing,
    backgroundColor: '#cd785129',
    anchor: 'marketing',
  },
  Multimedia: {
    displayName: 'Multimedija',
    image: iconMultimedia,
    backgroundColor: '#965bc229',
    anchor: 'multimedia',
  },
  Development: {
    displayName: 'Programiranje',
    image: iconDevelopment,
    backgroundColor: '#5b6cc229',
    anchor: 'development',
  },
  UiDevelopment: {
    displayName: 'UI development',
    image: iconUiDevelopment,
    backgroundColor: '#5cc2a029',
    anchor: 'development'
  },
  PresentationSkills: {
    displayName: 'Prezentacijske vještine',
    image: iconPresentationSkills,
    backgroundColor: '#282d3029',
  },
  ProjectManagement: {
    displayName: 'Projekt management',
    image: iconProjectManagement,
    backgroundColor: '#d3ce3a29',
  },
  Hardware: {
    displayName: 'Sistemaštvo',
    backgroundColor: '#8b837229',
  },
};

export default fieldsOfWork;

import { Discipline } from '../../consts';

import SuggestionDevelopment from 'assets/visuals/internship/suggestion-development.png';
import SuggestionMarketing from 'assets/visuals/internship/suggestion-marketing.png';
import SuggestionDesign from 'assets/visuals/internship/suggestion-design.png';
import SuggestionMultimedia from 'assets/visuals/internship/suggestion-multimedia.png';

export const disciplineCardsConfig = {
  [Discipline.development]: {
    label: 'Programiranje',
    imageSrc: SuggestionDevelopment,
  },
  [Discipline.marketing]: {
    label: 'Marketing',
    imageSrc: SuggestionMarketing,
  },
  [Discipline.design]: {
    label: 'Dizajn',
    imageSrc: SuggestionDesign,
  },
  [Discipline.multimedia]: {
    label: 'Multimedija',
    imageSrc: SuggestionMultimedia,
  },
};

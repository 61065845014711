import { endpoints } from './common';
import axios from 'axios';

export const allConfigurations = () => {
  return axios.get(endpoints.admin.configuration.get);
};

export const getConfiguration = ({ key }) => {
  return axios.get(endpoints.admin.configuration.get + '/' + key);
};

export const postConfigurationWithValue = ({ key, keys }) => {
  return axios.post(endpoints.admin.configuration.get + '/' + key, keys);
};

export const editConfiguration = ({ key, value }) => {
  return axios.post(endpoints.admin.configuration.edit, { key, value });
};

export const addConfiguration = ({ key, value }) => {
  return axios.post(endpoints.admin.configuration.add, { key, value });
};

export const setConfiguration = async (key, setter) => {
  const { data } = await getConfiguration({ key });
  try {
    setter(JSON.parse(data.value));
  } catch {
    setter(data.value)
  }
};

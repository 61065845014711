import React, { useState } from 'react';
import './styles.css';
import { newContactQuestionInfo } from 'services/admin';
import Flex from 'components/styled/Flex';

const AddContactQuestionInfo = ({ contactQuestionInfo, contactQuestionInfos, setContactQuestionInfos }) => {
  const [displayText, setDisplayText] = useState('');
  const [description, setDescription] = useState('');
  const [doesHaveNewsletterOption, setDoesHaveNewsletterOption] = useState(false);
  const [error, setError] = useState('');

  if (displayText.length === 0 && contactQuestionInfo !== undefined)
    setDisplayText(contactQuestionInfo.displayText);

  const handleSubmit = event => {
    event.preventDefault();
    setError('');

    const hasContactQuestionInfoWithDisplayText = contactQuestionInfos.filter(contactQuestionInfoFilter => contactQuestionInfoFilter.displayText === displayText && contactQuestionInfoFilter.isActive).length > 0;

    if (displayText === '') {
      setError('Please input some text');
      return;
    }
    if (hasContactQuestionInfoWithDisplayText) {
      setError('Contact question already exists');
      return;
    }
    
    if (contactQuestionInfo === undefined)
      return newContactQuestionInfo({ displayText, doesHaveNewsletterOption, description })
        .then(({ data }) => {
          setContactQuestionInfos([{ id: data.id, displayText, isActive: true, doesHaveNewsletterOption, description }, ...contactQuestionInfos]);
          setDisplayText('');
          setDescription('');
          setDoesHaveNewsletterOption(false);
        })
        .catch(error => setError('Failed to add contact question info'));
  }

  return (
    <Flex direction="column">
      {!contactQuestionInfo && <div className="form__add__header"><span>Add contact question category</span></div>}
      <form className="form__add" onSubmit={handleSubmit}>
        {error && <span className="form__error">{error}</span>}
        <label className="label" htmlFor="displayText">Display text</label>
        <input className="input" type="displayText" value={displayText} onChange={e => setDisplayText(e.target.value)}/>
        <label className="label" htmlFor="description">Description</label>
        <input className="input" type="description" value={description} onChange={e => setDescription(e.target.value)}/>
        <div className="form__newsletter">
          <input className="newsletter__checkbox" type="checkbox" name="newsletter" checked={doesHaveNewsletterOption} onChange={() => { setDoesHaveNewsletterOption(!doesHaveNewsletterOption) }} />
          <span className="newsletter__checkbox">Subscribes to a newsletter</span>
        </div>
        <div className="form__add__buttons">
          <input type="submit" className="button submit-button" value="Submit"></input>
          {contactQuestionInfo && <button className="button cancel-button">Cancel</button>}
        </div>
      </form>
    </Flex>
  );
}

export default AddContactQuestionInfo;

import React, { useState } from 'react';
import '../styles.css';

import { addEvent, editEvent, deleteEvent } from 'services/admin';

import ImagePicker from 'components/common/ImagePicker';
import Flex from 'components/styled/Flex';
import Event from 'components/common/Event';
import EventSectionsBuilder from '../EventSectionBuilder';

const EventEditor = ({
  event,
  fieldsOfWork,
  targetAudiences,
  setIsAdd,
  refreshEvents,
  isAdd,
  isEdit,
  setEditingId,
}) => {
  const [editingEvent, setEditingEvent] = useState(event);
  const [isEditingEventSections, setIsEditingEventSections] = useState(false);
  if (editingEvent && editingEvent.theme == null) setEditingEvent({ ...editingEvent, theme: '' });

  const setPropertyEvent = ({ target: { value, name } }) => {
    setEditingEvent({ ...editingEvent, [name]: value });
  };

  const setCoverImagePath = coverImagePath => {
    setEditingEvent({ ...editingEvent, coverImagePath });
  };

  const setPosterImagePath = posterImagePath => {
    setEditingEvent({ ...editingEvent, posterImagePath });
  };

  const submitEvent = e => {
    e.preventDefault();
  };

  const setCancel = () => {
    if (isEdit) {
      refreshEvents();
      setEditingId(undefined);
    } else setIsAdd(false);
  };

  const runSave = () => {
    if (isAdd)
      return addEvent({ event: editingEvent }).then(() => {
        refreshEvents();
        setIsAdd(false);
      });
    if (isEdit)
      return editEvent({ event: editingEvent }).then(() => {
        refreshEvents();
        setEditingId(undefined);
      });
  };

  const runDelete = () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete this event?\nIt contains ${editingEvent.eventSections?.length || 0} sections`,
    );
    confirmed &&
      deleteEvent({ event: editingEvent }).then(() => {
        refreshEvents();
        setEditingId(undefined);
      });
  };

  if (isEditingEventSections)
    return (
      <EventSectionsBuilder
        event={editingEvent}
        setEvent={setEditingEvent}
        setIsEditingEventSections={setIsEditingEventSections}
      />
    );

  return (
    <Flex direction="column" alignItems="center">
      <div className="form__add__header">Event editor</div>
      {editingEvent && (
        <form className="form__add" onSubmit={submitEvent}>
          <label className="label">Name</label>
          <input name="name" className="input" type="text" value={editingEvent.name} onChange={setPropertyEvent} />
          <label className="label">Date of beginning</label>
          <input name="dateOfBeginning" type="date" value={editingEvent.dateOfBeginning} onChange={setPropertyEvent} />
          <label className="label">Date of end</label>
          <input name="dateOfEnd" type="date" value={editingEvent.dateOfEnd} onChange={setPropertyEvent} />
          <label className="label">Short description</label>
          <textarea
            name="shortDescription"
            value={editingEvent.shortDescription}
            className="textarea"
            onChange={setPropertyEvent}
          />
          <label className="label">Target audience</label>
          <select name="targetAudience" value={editingEvent.targetAudience} onChange={setPropertyEvent}>
            {targetAudiences.map((targetAudience, index) => (
              <option key={index} value={targetAudience}>
                {targetAudience}
              </option>
            ))}
          </select>
          <label className="label">Theme</label>
          <select name="theme" value={editingEvent.theme} onChange={setPropertyEvent}>
            <option value=""></option>
            {fieldsOfWork.map((fieldOfWork, index) => (
              <option key={index} value={fieldOfWork}>
                {fieldOfWork}
              </option>
            ))}
          </select>
          <label className="label">Event poster image</label>
          <ImagePicker
            className="image-picker-input"
            imagePath={editingEvent.posterImagePath}
            imagePathChangedCallback={setPosterImagePath}
          />
          <label className="label">Event cover image</label>
          <ImagePicker
            className="image-picker-input"
            imagePath={editingEvent.coverImagePath}
            imagePathChangedCallback={setCoverImagePath}
          />
          <div className="form__add__buttons">
            <button type="submit" className="button submit-button" onClick={runSave}>
              Save
            </button>
            {isEdit && (
              <button className="button-admin cancel-button" onClick={runDelete}>
                Delete
              </button>
            )}
            <button className="button-admin cancel-button" onClick={setCancel}>
              Cancel
            </button>
          </div>
          <div className="form__add__buttons">
            <button className="button submit-button" onClick={() => setIsEditingEventSections(true)}>
              Edit event section
            </button>
          </div>
        </form>
      )}
      <Event event={editingEvent} />
    </Flex>
  );
};

export default EventEditor;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './styles.css';

import ProjectBuilder from './ProjectBuilder';
import ProjectSectionsBuilder from './ProjectSectionsBuilder';
import EventBuilder from './EventBuilder';

const Builders = () => {
  return (
    <Switch>
      <Route exact path="/admin/builders/event">
        <EventBuilder />
      </Route>
      <Route path="/admin/builders/event/:eventId">
        <EventBuilder />
      </Route>
      <Route exact path="/admin/builders/project">
        <ProjectBuilder />
      </Route>
      <Route path="/admin/builders/project/project-sections/:projectId">
        <ProjectSectionsBuilder />
      </Route>
    </Switch>
  );
};

export default Builders;

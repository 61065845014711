import styled from 'styled-components';

import { getColor } from '../index.styled';

export const StyledWrapperContainer = styled.div`
  background-color: ${getColor};
`;

export const StyledWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledContainer = styled.div`
  padding-top: 96px;
  margin-left: 216px;
  margin-right: 112px;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;

  @media screen and (max-width: 1440px) {
    margin-left: 112px;
    margin-right: 64px;
  }

  @media screen and (max-width: 1250px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-left: unset;
    margin-right: unset;
  }
`;

export const StyledTextContainer = styled.div`
  @media (max-width: 767px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 112px;
  line-height: 120px;
  color: white;
  margin-top: 0;
  margin-bottom: 24px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 1100px) {
    font-size: 86px;
    line-height: 94px;
  }

  @media screen and (max-width: 950px) {
    font-size: 64px;
    line-height: 72px;
  }

  @media (max-width: 767px) {
    font-size: 47px;
    line-height: 52px;
  }
`;

export const StyledDescription = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 20px;
  line-height: 28px;
  color: white;
  margin-top: 0;
  margin-bottom: 40px;
  max-width: 490px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 950px) {
    max-width: 400px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: unset;
  }
`;

export const StyledPerksWrapper = styled.div`
  display: flex;
  padding: 20px 40px 16px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledPerk = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.16);
    margin-right: 40px;
    padding-right: 40px;
  }
`;

export const StyledIcon = styled.img`
  margin-bottom: 8px;
`;

export const StyledPerkLabel = styled.h4`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
`;

export const StyledButtonContainer = styled.div`
  background-color: transparent;
  width: 100%;

  /* TODO: apply one day */
  /* parent must not have overflow set */
  /* position: sticky;
  bottom: 0;
  z-index: 2; */

  @media (max-width: 767px) {
    position: absolute;
    bottom: 116px;
    left: 0;
    right: 0;
    width: unset;
    margin-left: -24px;
    margin-right: -24px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    z-index: 2;

    background-color: ${getColor};
  }
`;

export const StyledButton = styled.button`
  border: unset;
  background-color: ${getColor};
  padding: 18px 32px;
  border-radius: 8px;
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 16px;
  color: white;
  cursor: pointer;
`;

export const StyledImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;

  @media (max-width: 767px) {
    margin-top: auto;
    position: static;
    width: 120%;
  }
`;

import React, { useState, useEffect } from 'react';
import Flex from 'components/styled/Flex';
import { postConfigurationWithValue } from 'services/configuration';
import { addSeparators } from 'utils/number';

const AttendanceInfo = ({ margin, eventAttendance }) => {
  const [numberConfigurations, setNumberConfigurations] = useState();
  const [numberOfAttendees, setNumberOfAttendees] = useState('');
  const [numberOfLectures, setNumberOfLectures] = useState('');
  const [numberOfDumpDaysAttendees, setNumberOfDumpDaysAttendees] = useState('');
  const [numberOfDumpDaysLectures, setNumberOfDumpDaysLectures] = useState('');
  const [numberOfSopAttendees, setNumberOfSopAttendees] = useState('');
  const [numberOfSopLectures, setNumberOfSopLectures] = useState('');

  useEffect(() => {
    postConfigurationWithValue({
      key: 'from-list',
      keys: [
        'NumberOfAttendees',
        'NumberOfLectures',
        'NumberOfDumpDaysAttendees',
        'NumberOfDumpDaysLectures',
        'NumberOfSopAttendees',
        'NumberOfSopLectures',
      ],
    })
      .then(res => {
        setNumberConfigurations(res && res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (numberConfigurations) {
      checkIfNullOrUndefined(numberConfigurations['numberOfAttendees']) &&
        setNumberOfAttendees(numberConfigurations['numberOfAttendees'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfLectures']) &&
        setNumberOfLectures(numberConfigurations['numberOfLectures'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfDumpDaysAttendees']) &&
        setNumberOfDumpDaysAttendees(numberConfigurations['numberOfDumpDaysAttendees'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfDumpDaysLectures']) &&
        setNumberOfDumpDaysLectures(numberConfigurations['numberOfDumpDaysLectures'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfSopAttendees']) &&
        setNumberOfSopAttendees(numberConfigurations['numberOfSopAttendees'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfSopLectures']) &&
        setNumberOfSopLectures(numberConfigurations['numberOfSopLectures'].value);
    }
  }, [numberConfigurations]);

  const checkIfNullOrUndefined = itemToCheck => {
    return itemToCheck !== null && itemToCheck !== undefined;
  };

  const numbersInfo = {
    Overall: { attendees: numberOfAttendees, lectures: numberOfLectures },
    DumpDays: { attendees: numberOfDumpDaysAttendees, lectures: numberOfDumpDaysLectures },
    Sop: { attendees: numberOfSopAttendees, lectures: numberOfSopLectures },
  };

  if (!numbersInfo[eventAttendance].attendees || !numbersInfo[eventAttendance].lectures) {
    return null;
  }

  return (
    <Flex justifyContent="space-between" margin={margin} className={'events__attendance ' + eventAttendance}>
      <span className="events__attendance--info">
        <h2>{addSeparators(numbersInfo[eventAttendance].lectures)}+</h2>
        <p>predavanja</p>
      </span>
      <span className="events__attendance--info">
        <h2>{addSeparators(numbersInfo[eventAttendance].attendees)}+</h2>
        <p>posjetitelja</p>
      </span>
    </Flex>
  );
};

export default AttendanceInfo;

import React, { useEffect, useState } from 'react';
import ProjectList from 'components/common/ProjectList';
import { takePublishedProjects } from 'services/admin';
import { deserializeProject } from 'services/projectLogic';

const HighlightedProjectList = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    takePublishedProjects().then(({ data }) => {
      const deserializedProjects = data.map(project => {
        deserializeProject(project);
        return project;
      });
      setProjects(deserializedProjects);
    });
  }, []);

  return <ProjectList projects={projects} />;
};

export default HighlightedProjectList;

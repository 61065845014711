import styled from 'styled-components';

export const StyledWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 232px;
  margin-left: 10px;

  @media screen and (max-width: 1000px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 134px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-style: normal;
  font-weight: 800;
  font-size: 112px;
  line-height: 120px;
  color: #111111;
  position: sticky;
  top: 32px;
  max-width: 488px;
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (max-width: 1000px) {
    max-width: 400px;
  }

  @media screen and (max-width: 768px) {
    font-size: 56px;
    line-height: 60px;
    position: unset;
    max-width: 310px;
    margin-bottom: 60px;
  }
`;

export const StyledStepWrapper = styled.div`
  display: flex;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 180px;

    &::after {
      content: '';
      position: absolute;
      top: 80px;
      left: 64px;
      height: calc(100% - 32px);
      border-left: 2px dashed #282d30;
      opacity: 0.16;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 100px;

      &::after {
        content: unset;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(92, 194, 160, 0.8);
    border-radius: 50%;
    top: 60px;
    left: 60px;

    @media screen and (max-width: 768px) {
      content: unset;
    }
  }

  & > div {
    max-width: 280px;
  }
`;

export const StyledIndex = styled.h4`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 112px;
  line-height: 120px;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 32px;
  opacity: 0.4;

  transition: color 1000ms ease-out;
  color: ${({ isIntersecting }) => (isIntersecting ? '#44c79b' : '#bccee0')};

  @media screen and (max-width: 768px) {
    font-size: 56px;
    line-height: 60px;
    margin-right: 16px;
  }
`;

export const StyledStepTitle = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #111111;
  margin-top: 0;
  margin-bottom: 8px;
`;

export const StyledStepDescription = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  margin-top: 0;
  margin-bottom: 0;
`;

import React from 'react';
import '../styles.css';
import { Transforms, Editor } from 'slate';
import { useSlate } from 'slate-react';

import { ElementType } from 'constants/elementType';
import ToolbarMenuItemStyledSvg from '../ToolbarMenuItemStyledSvg';

const isListActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match;
};

const toggleList = (editor, format) => {
  const LIST_TYPES = [ElementType.orderedList, ElementType.unorderedList];
  const isActive = isListActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'listItem' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const ListButton = ({ format }) => {
  const editor = useSlate();
  return (
    <ToolbarMenuItemStyledSvg
      active={isListActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleList(editor, format);
      }}
    >
      <img src={require(`assets/icons/${format}.svg`)} alt={format} />
    </ToolbarMenuItemStyledSvg>
  );
};

export default ListButton;

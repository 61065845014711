import React, { useState } from 'react';
import ToolbarMenuItemStyled from '../ToolbarMenuItemStyled';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';

const ImageTagButton = ({ libraryAttachments, fileAttachments, isEmail }) => {
  const editor = useSlate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const addImg = (fileName) => {
    const img = {
      type: 'img',
      children: [{ text: `<img src="cid:${fileName}" style="max-width: 320px" />`}]
    }
    Transforms.insertNodes(editor, img);
    setIsPopupOpen(false);
  };

  const getFileNameFromPath = (path) => {
    return path.substring(path.lastIndexOf("\\") + 1);
  }

  return (<div>
    {isEmail && (fileAttachments.length > 0 || libraryAttachments.length > 0) &&
      <ToolbarMenuItemStyled
        onMouseDown={event => {
          event.preventDefault();
          setIsPopupOpen(!isPopupOpen);
        }}
      >
        <span className="embed-image__button">Embed image</span>
      </ToolbarMenuItemStyled>
    }
    {isPopupOpen &&
      <div className="image-tag__dropdown">
        {fileAttachments.map((file, key) => {
          return <span key={key} onClick={() => addImg(file[1])}>{file[1]}</span>;
        })}
        {libraryAttachments.map((path, key) => {
          return <span key={key} onClick={() => addImg(getFileNameFromPath(path))}>{getFileNameFromPath(path)}</span>;
        })}
      </div>}
  </div>
  );
}

export default ImageTagButton;
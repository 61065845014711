import React from 'react';
import { HashLink } from 'react-router-hash-link';

const SmoothHashLinkStart = ({ children, to }) => {
  
  return (
    <HashLink to={to} scroll={el => window.scrollTo({ top: el.getBoundingClientRect().top - 100 + window.scrollY, behavior: 'smooth' })}>
      {children}
    </HashLink>
  );
};

export default SmoothHashLinkStart;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import visualDumpTeam from 'assets/visuals/visual-dump-team.png';
import checkmark from 'assets/icons/checkmark-red.svg';
import arrowRed from 'assets/icons/arrow-red.svg';

import SmoothHashLinkStart from 'components/common/SmoothHashLinkStart';
import Flex from 'components/styled/Flex';
import { InternshipInfoContainer, InternshipDescriptionFlex, FieldsStack } from './index.styled';
import TextUnderline from 'components/styled/TextUnderline';
import Icon from 'components/common/Icon';
import internshipBonuses from 'constants/internshipBonuses';
import fieldsOfWork from 'constants/fieldsOfWork';
import { getAnimation } from 'components/styled/AnimatedStack';
import ImageLoader from 'components/common/ImageLoader';

import { setConfiguration } from 'services/configuration';

const FieldCard = ({ fieldOfWork }) => {
  if (fieldOfWork === fieldsOfWork.Hardware) return null;
  const url = fieldOfWork.anchor ? `/internship#${fieldOfWork.anchor}` : '/internship';

  return (
    <SmoothHashLinkStart to={url}>
      <div className="field__card">
        <Icon image={fieldOfWork.image} color={fieldOfWork.backgroundColor} alt="" />
        <h3 className="field__card__name">{fieldOfWork.displayName}</h3>
      </div>
    </SmoothHashLinkStart>
  );
};

const InternshipInfo = () => {
  const [internshipUrl, setInternshipUrl] = useState(null);

  useEffect(() => {
    setConfiguration('InternshipUrl', setInternshipUrl);
  }, []);

  return (
    <InternshipInfoContainer>
      <div className="landing__internship__fields">
        <ImageLoader className="landing__internship__image" src={visualDumpTeam} alt="internship" />
        <div className="landing__internship__camera">
          <div className="landing__internship__dolly">
            <FieldsStack animation={getAnimation(0, 2325)} duration="46" animateOnce offset="2325">
              {fieldsOfWork &&
                Object.values(fieldsOfWork).map((fieldOfWork, index) => (
                  <FieldCard key={index} fieldOfWork={fieldOfWork} />
                ))}
            </FieldsStack>
            <FieldsStack animation={getAnimation(-1000, 1325)} duration="46">
              {fieldsOfWork &&
                Object.values(fieldsOfWork).map((fieldOfWork, index) => (
                  <FieldCard key={index} fieldOfWork={fieldOfWork} />
                ))}
            </FieldsStack>
            <FieldsStack animation={getAnimation(-1000, 1325)} delay="23" offset="-1000" duration="46">
              {fieldsOfWork &&
                Object.values(fieldsOfWork).map((fieldOfWork, index) => (
                  <FieldCard key={index} fieldOfWork={fieldOfWork} />
                ))}
            </FieldsStack>
          </div>
        </div>
      </div>
      <InternshipDescriptionFlex direction="column">
        <Flex alignItems="center">
          {internshipUrl && <span className="circle--active"></span>}
          <p className="heading--tiny landing__internship__active">
            {internshipUrl ? 'Prijave u tijeku' : 'Prijave su trenutno zatvorene'}
          </p>
        </Flex>
        <h2 className="heading--normal landing__internship__heading">Želiš postati član?</h2>
        <p className="text__description landing__internship__description">
          Imaš između 16 i 24 godine? Zanima te tehnologija? Pogledaj zašto bi te mogao zanimati naš Internship:
        </p>
        <ul className="landing__internship__bonuses">
          {internshipBonuses.map((bonus, index) => (
            <li key={index}>
              <img src={checkmark} alt="Internship checkmark" />
              <p>{bonus}</p>
            </li>
          ))}
        </ul>
        {internshipUrl && (
          <Flex>
            <a rel="noopener noreferrer" target="_blank" href={internshipUrl}>
              <button className="button--red">Prijavi se</button>
            </a>
            <Link rel="noopener" to="/internship">
              <button className="button landing__internship__button">
                <TextUnderline underlineColor="#cb535333" underlineRightOffset="0">
                   Saznaj više
                </TextUnderline>
                <img src={arrowRed} alt="Red arrow" />
              </button>
            </Link>
          </Flex>
        )}
        {!internshipUrl && (
          <Flex direction="column">
            <p className="text__description landing__internship__description">
              Prijavi se na naš newsletter! Među prvima saznaj više o početku novog Internshipa!
            </p>
            <Flex>
              <SmoothHashLinkStart to="/internship">
                <button className="button--red internship__cta__button">Postani član</button>
              </SmoothHashLinkStart>
            </Flex>
          </Flex>
        )}
      </InternshipDescriptionFlex>
    </InternshipInfoContainer>
  );
};

export default InternshipInfo;

import styled, { css } from 'styled-components';

export const Color = {
  green: '#0E9061',
  lightGreen: '#11AA72',
  blue: '#2660B6',
  lightBlue: '#2476C8',
  red: '#C42635',
  lightRed: '#F64C5D',
  purple: '#53468B',
  lightPurple: '#6454A9',
  dark: '#282D30',
  seeThroughGreen: 'rgba(17, 170, 114, 0.64);',
  seeThroughBlue: 'rgba(36, 118, 200, 0.64);',
  seeThroughRed: 'rgba(246, 76, 93, 0.64);',
  seeThroughPurple: 'rgba(100, 84, 169, 0.64)',
};

export const getColor = props => props?.color || 'white';

export const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  transition: opacity 300ms ease-out, visibility 300ms ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  background-color: ${getColor};
`;

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledCloseButton = styled.button`
  border: unset;
  background-color: unset;
  position: absolute;
  top: 32px;
  right: 112px;
  z-index: 6;
  cursor: pointer;

  @media screen and (max-width: 1440px) {
    right: 64px;
  }

  @media screen and (max-width: 1250px) {
    right: 32px;
  }

  @media (max-width: 767px) {
    right: 24px;
  }
`;

export const StyledTextSectionsWrapper = styled.div`
  & > * {
    padding-bottom: 256px;

    &:first-child {
      padding-top: 212px;
    }

    @media (max-width: 767px) {
      padding-bottom: 144px;

      &:first-child {
        padding-top: 144px;
      }
    }
  }
`;

export const StyledImage = styled.img``;

import React from 'react';
import { ElementType } from 'constants/elementType';

const Element = ({ attributes, element, children }) => {
  switch (element.type) {
    case 'listItem':
      return <li {...attributes}>{children}</li>;
    case ElementType.link:
      return (
        <a {...attributes} href={element.url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    case ElementType.orderedList:
      return <ol {...attributes}>{children}</ol>;
    case ElementType.unorderedList:
      return <ul {...attributes}>{children}</ul>;
    case ElementType.h2:
      return <h2 {...attributes}>{children}</h2>;
    case ElementType.color:
      return <span {...attributes} style={{color: element.color}}>{children}</span>
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;

import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import '../styles.css';

import { sortLogic } from 'utils/sortLogic';
import { DraggableTypes } from 'constants/sectionType';

const EventSectionDraggableStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 200px;

  & .droppable__title {
    width: 100%;
    border: 2px dashed #D8D8D8;
    cursor: grab;
    text-transform: uppercase;
    display: flex;
    algin-items: center;
    justify-content: center;
    z-index: 2;

    span {
      align-self: center;
      font-weight: bold;
      font-size: 18px;
    }
  }

  ${({ visibility }) => visibility && `visibility: ${visibility}`}
  ${({ isReordering }) => {
    if (isReordering) return `& > .droppable-section { display: flex; }`;
    return `& > .droppable-section { display: none; }`;
  }}

  ${({ isDragging }) => isDragging && `& > div:not(.droppable-section) { 
    opacity: 0;
  }`}

  & > .droppable-section {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    flex-direction: column;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
  }
  
  & > .droppable-section > div {
    height: 50%;
    color: #D8D8D8;
    width: 100%;
    border: 2px dashed #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 2px dashed #000;
      color: #000;
    } 
  }
`;

const EventSectionDraggable = ({ index, eventSection, moveSection, findEventSection, isReordering }) => {
  const ref = useRef(null);
  const originalIndex = findEventSection(`${eventSection.sectionOrder}`).index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: DraggableTypes.section, index, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { index: droppedIndex, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) moveSection(originalIndex, droppedIndex);
    },
  });

  const [, drop] = useDrop({
    accept: DraggableTypes.section,
    canDrop: () => false,
    hover({ index: draggedIndex }, monitor) {
      if (!ref.current) return;
      const { index: overIndex } = findEventSection(index);
      if (!sortLogic(draggedIndex, overIndex, ref, monitor)) return;

      moveSection(overIndex, draggedIndex);
    },
  });

  const visibility = isDragging ? 'hidden' : 'visible';
  drag(drop(ref));

  return (
    <EventSectionDraggableStyled ref={ref} key={index} visibility={visibility} isDragging={isDragging} isReordering={isReordering}>
      <div className="droppable-section">
        <div>
          <span>Sort above me</span>
        </div>
        <div>
          <span>Sort under me</span>
        </div>
      </div>
      <div className="droppable__title">
        <span>{eventSection.title}</span>
      </div>
    </EventSectionDraggableStyled>
  );
};

export default EventSectionDraggable;

import React, { useState, useCallback } from 'react';
import './styles.css';

import FileBox from 'components/common/FileBox';
import { FileType } from 'constants/fileType';
import { uploadToLibrary } from 'services/admin';
import Flex from 'components/styled/Flex';
import Loading from 'components/styled/Loading';

const LibraryAdd = ({ updateImages, images }) => {
  const [droppedImage, setDroppedImage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleImageDrop = useCallback(
    formFile => {
      if (formFile) {
        if (images.find(image => image === formFile.name.toLowerCase())) setError('Image already exists');
        else setDroppedImage(formFile);
      }
    },
    [setDroppedImage, images],
  );

  const upload = () => {
    setLoading(true);
    uploadToLibrary(droppedImage)
      .then(({ data }) => {
        updateImages([...images, droppedImage.name.toLowerCase()]);
        setDroppedImage(null);
      })
      .catch(({ error }) => {
        setError('Image type not supported');
        setDroppedImage(null);
      })
      .finally(() => setLoading(false));
  };
  if (loading) return <Loading />;

  return (
    <Flex direction="column">
      <div className="form__add__header">
        <span>Add photo to library</span>
      </div>
      <div className="library-add__container form__add">
        {error && <span className="form__error">{error}</span>}
        {droppedImage && <img src={URL.createObjectURL(droppedImage)} alt="Preview" />}
        {!droppedImage && <FileBox onDrop={handleImageDrop} fileType={FileType.photo} />}
        {droppedImage && (
          <button className="button-admin cancel-button " onClick={() => setDroppedImage(null)}>
            Cancel
          </button>
        )}
        {droppedImage && <label className="label library-add__photo-name">{droppedImage.name}</label>}
        {droppedImage && (
          <button className="button-admin submit-button" onClick={() => upload(droppedImage)}>
            Submit
          </button>
        )}
      </div>
    </Flex>
  );
};

export default LibraryAdd;

import React, { useState, useEffect } from 'react';
import { addPost, editPost } from 'services/post';
import { range } from 'utils/list.js';
import ImagePicker from 'components/common/ImagePicker';
import { ImagePathLogic } from 'services/imagePathLogic';

import Flex from 'components/styled/Flex';
import Loading from 'components/styled/Loading';

class PostInitialValue {
  title = '';
  subtitle = '';
  year = 2010;
  quote = '';
  quoteMember = { email: '' };
  quoteMemberId = -1;
  mainImagePath = new ImagePathLogic().imagePath;
  secondaryImagePath = new ImagePathLogic().imagePath;
}

const AddEditPost = ({ selectedPost, posts, setPosts, setEditingId, members }) => {
  let postInitialValue = new PostInitialValue();
  if (!selectedPost) {
    postInitialValue = {
      ...postInitialValue,
    };
  } else {
    postInitialValue = {
      ...selectedPost,
    };
  }

  const [yearSelect, setYearSelect] = useState([]);
  const [post, setPost] = useState(postInitialValue);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    let years = range(2010, currentDate.getFullYear());
    if (selectedPost) {
      years = years.filter(year => !posts.some(post => post.year === year && post.year !== selectedPost.year));
    } else {
      years = years.filter(year => !posts.some(post => post.year === year));
    }
    setYearSelect(years);
    setPost({ ...post, year: years[0] });
    //eslint-disable-next-line
  }, [posts, selectedPost]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);

    if (!selectedPost)
      addPost(post)
        .then(() => setPosts())
        .catch(error => setError('Failed to add post'))
        .finally(() => setPost({ ...new PostInitialValue() }));
    else
      editPost(post)
        .then(() => setPosts())
        .catch(() => setError('Failed to edit post'));

    setLoading(false);
    if (setEditingId) setEditingId(-1);
  };

  const validate = () => {
    setError('');
    if (post.year === 0) setError('No year selected');
    else return true;
    return false;
  };

  const handleChange = e => {
    const value = e.target.value;
    setPost({ ...post, [e.target.name]: value });
  };

  const handleYearChange = e => {
    const value = e.target.value;
    setPost({ ...post, [e.target.name]: value });
  };

  const handleMemberChange = e => {
    const value = e.target.value;
    const quoteMemberId = members.find(member => member.email === e.target.value).id;

    setPost({ ...post, quoteMember: { email: value }, quoteMemberId });
  };

  const setMainImagePath = mainImagePath => {
    setPost({ ...post, mainImagePath: mainImagePath });
  };

  const setSecondaryImagePath = secondaryImagePath => {
    setPost({ ...post, secondaryImagePath: secondaryImagePath });
  };

  return (
    <Flex direction="column">
      {!selectedPost && (
        <div className="form__add__header">
          <span>Add Post</span>
        </div>
      )}
      {loading && <Loading />}
      {!loading && (
        <form className="form__add" onSubmit={handleSubmit}>
          {error && <span className="form__error">{error}</span>}
          <label htmlFor="title">Naslov</label>
          <input className="input" type="text" name="title" onChange={handleChange} value={post.title} required />
          <label htmlFor="subtitle">Podnaslov</label>
          <input className="input" type="text" name="subtitle" onChange={handleChange} value={post.subtitle} required />
          <label htmlFor="year">Godina</label>
          <select className="input" name="year" onChange={handleYearChange} value={post.year} required>
            {!(yearSelect.length > 0) && (
              <option value={0} disabled hidden>
                Every year has post
              </option>
            )}
            {yearSelect.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </select>
          <label htmlFor="quote">Izjava</label>
          <textarea className="input" type="text" name="quote" onChange={handleChange} value={post.quote} required />
          <label htmlFor="quoteMember">Član</label>
          <select
            className="input"
            type="text"
            name="quoteMember"
            onChange={handleMemberChange}
            value={post.quoteMember.email}
            required
          >
            {!selectedPost && (
              <option value="" disabled hidden>
                Email
              </option>
            )}
            {members.map((member, index) => (
              <option key={index} value={member.email}>
                {member.email}
              </option>
            ))}
          </select>
          <label htmlFor="activeImage">Main image: </label>
          <ImagePicker
            className="image-picker-input"
            imagePathChangedCallback={setMainImagePath}
            imagePath={post.mainImagePath}
          />
          <label htmlFor="activeImage">Secondary image: </label>
          <ImagePicker
            className="image-picker-input"
            imagePathChangedCallback={setSecondaryImagePath}
            imagePath={post.secondaryImagePath}
          />
          <div className="form__add__buttons">
            <input type="submit" className="button submit-button" value="Submit"></input>
            {selectedPost && (
              <button className="button cancel-button" onClick={() => setEditingId(-1)}>
                Cancel
              </button>
            )}
          </div>
        </form>
      )}
    </Flex>
  );
};

export default AddEditPost;

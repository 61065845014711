import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-bottom: 200px;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-bottom: 148px;
  }
`;

export const StyledTextContainer = styled.div`
  margin-left: 216px;

  @media screen and (max-width: 1100px) {
    margin-left: 24px;
  }

  @media screen and (max-width: 768px) {
    max-width: 400px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-size: 112px;
  line-height: 120px;
  font-weight: 800;
  color: #282d30;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 56px;
    line-height: 60px;
  }

  @media screen and (max-width: 380px) {
    font-size: 48px;
  }
`;

export const StyledSubtitle = styled.span`
  color: #44c79b;
`;

export const StyledDescription = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  color: #282d30;
  max-width: 380px;
  margin: 12px 0 0;

  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }
`;

export const StyledIconsContainer = styled.div`
  margin: 180px 112px 0;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledIconContainer = styled.div`
  margin-right: 32px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(214, 224, 233, 0.06), 0px 4px 16px rgba(214, 224, 233, 0.12),
    0px 8px 40px rgba(214, 224, 233, 0.31);
  border-radius: 32px;
  max-width: 280px;
  flex-basis: 100%;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 1100px) {
    flex-basis: calc(50% - 16px);
    margin-bottom: 32px;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    margin-right: 0;
  }
`;

export const StyledIconTitle = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ color }) => color};
  text-transform: uppercase;
  margin: 28px 0 8px;
`;

export const StyledIconDescription = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  color: #282d30;
`;

export const StyledBackgroundContainer = styled.div`
  background: rgba(188, 206, 224, 0.12);
  border-radius: 0px 40px 40px 0px;
  position: absolute;
  z-index: -1;
  top: 56px;
  bottom: 104px;
  left: -112px;
  right: 164px;

  @media screen and (max-width: 768px) {
    left: -24px;
    top: 96px;
    right: 40px;
  }
`;

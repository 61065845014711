export class ImagePathLogic {
  imagePath;

  constructor(imagePath) {
    this.imagePath = imagePath
      ? imagePath
      : {
          folder: "Default",
          image: "placeholder.png",
        };
  }
}

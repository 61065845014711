import React from 'react';
import { normalizeUpperCase } from 'utils/normalizeUpperCase';
import * as dayjs from 'dayjs';

const Log = ({ log }) => {
  const transformEmail = email => {
    return dayjs(email).format('DD-MM-YYYY HH:mm:ss');
  };

  return (
    <tr className="row__data">
      <td>{log.id}</td>
      <td>{log.content}</td>
      <td>{normalizeUpperCase(log.userActionType)}</td>
      <td>{log.user.email}</td>
      <td>{transformEmail(log.timestamp)}</td>
    </tr>
  );
};

export default Log;

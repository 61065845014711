import React, { useState } from 'react';

const COOKIE_NOTICE_KEY = 'didCheckCookieNotice';

const initialState = {
  isExpanded: false,
  didCheckCookieNotice: !!localStorage.getItem(COOKIE_NOTICE_KEY),
  isNextEventDisplayed: false,
};

export const MenuStateContext = React.createContext({
  state: { ...initialState },
});

const MenuProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(initialState.isExpanded);
  const [didCheckCookieNotice, _setDidCheckCookieNotice] = useState(initialState.didCheckCookieNotice);
  const [isNextEventDisplayed, setIsNextEventDisplayed] = useState(initialState.isNextEventDisplayed);

  const setDidCheckCookieNotice = value => {
    localStorage.setItem(COOKIE_NOTICE_KEY, value);

    return _setDidCheckCookieNotice(value);
  };

  const value = {
    state: {
      isExpanded,
      didCheckCookieNotice,
      isNextEventDisplayed,
    },
    setIsExpanded,
    setDidCheckCookieNotice,
    setIsNextEventDisplayed,
  };

  return <MenuStateContext.Provider value={value}>{children}</MenuStateContext.Provider>;
};

export default MenuProvider;

export const getYearsFromEvents = events => {
  let listOfYears = [];

  events.forEach(event => {
    let year = new Date(event.dateOfBeginning).getFullYear();
    if (!listOfYears.includes(year)) listOfYears.push(year);
  });
  listOfYears.sort((a, b) => b - a);

  return listOfYears;
};

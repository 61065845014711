export const shortenNumber = number => {
  if (isNaN(+number)) {
    return 'Error';
  }

  if (number >= 1000000) {
    number = number / 1000000 + 'M';
  } else if (number >= 1000) {
    number = number / 1000 + 'K';
  }

  return number;
};

export const addSeparators = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const roundToHundreds = number => {
  const rounded = Math.floor(number / 100) * 100;

  return !isNaN(rounded) ? rounded : 'Error';
};

import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import '../styles.css';

import { getAllEvents, getFieldOfWorkTypes, getTargetAudienceTypes } from 'services/admin';
import { EventLogic, deserializeEvent } from 'services/eventLogic';

import EventEditor from './EventEditor';
import Flex from 'components/styled/Flex';

const EventBuilder = () => {
  const { eventId } = useParams();
  const [isAdd, setIsAdd] = useState(false);
  const [events, setEvents] = useState([]);
  const [fieldsOfWorkTypes, setFieldsOfWorkTypes] = useState([]);
  const [targetAudienceTypes, setTargetAudiencesTypes] = useState([]);
  const [editingId, setEditingId] = useState(eventId);
  const [search, setSearch] = useState('');

  const refreshEvents = useCallback(() => {
    return getAllEvents().then(({ data }) => {
      const eventsRefreshed = data.map(e => {
        deserializeEvent(e);
        return e;
      });
      setEvents(eventsRefreshed);
    });
  }, []);

  useEffect(() => {
    refreshEvents().then(() => {
      Promise.all([getFieldOfWorkTypes(), getTargetAudienceTypes()]).then(values => {
        setFieldsOfWorkTypes(values[0].data);
        setTargetAudiencesTypes(values[1].data);
      });
    });
  }, [refreshEvents]);

  const isEventIdInEvents = () => events.find(e => String(e.id) === editingId);

  return (
    <div>
      <Flex justifyContent="center" margin="10px">
        {!isAdd && !isEventIdInEvents() && (
          <button className="button-admin submit-button" onClick={() => setIsAdd(true)}>
            Add new event
          </button>
        )}
      </Flex>
      {isAdd && (
        <EventEditor
          event={new EventLogic()}
          refreshEvents={refreshEvents}
          setIsAdd={setIsAdd}
          fieldsOfWork={fieldsOfWorkTypes}
          targetAudiences={targetAudienceTypes}
          isAdd
        />
      )}
      {isEventIdInEvents() && (
        <EventEditor
          event={events.find(e => String(e.id) === editingId)}
          refreshEvents={refreshEvents}
          setIsAdd={setIsAdd}
          fieldsOfWork={fieldsOfWorkTypes}
          targetAudiences={targetAudienceTypes}
          setEditingId={setEditingId}
          isEdit
        />
      )}
      <Flex direction="column" alignItems="center">
        {!isAdd && !isEventIdInEvents() && (
          <>
            <input
              className="input"
              value={search}
              placeholder="Search by event name and year"
              size="24"
              onChange={({ target: { value } }) => setSearch(value)}
            />
            <Flex justifyContent="space-around" wrap="wrap" margin="10px 0">
              {events
                .filter(event => {
                  const number = search.match(/\d+/g);
                  const text = search.match(/([a-žA-Ž ]*)/g);
                  return (
                    event.name.includes(String(text[0]).trim()) &&
                    (!number || (number && String(new Date(event.dateOfBeginning).getFullYear()).includes(number[0])))
                  );
                })
                .map((event, index) => (
                  <div className="event__preview" key={index} onClick={() => setEditingId(String(event.id))}>
                    <span className="event__preview__name">{event.name}</span>
                    <span className="event__preview__year">{new Date(event.dateOfBeginning).getFullYear()}</span>
                  </div>
                ))}
            </Flex>
          </>
        )}
      </Flex>
    </div>
  );
};

export default EventBuilder;

import React, { useReducer } from 'react';

const initialState = {
  isModalActive: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return { ...state, isModalActive: !{ ...state }.isModalActive };
    default:
      return { ...state };
  }
};

export const ContactModalContext = React.createContext({
  state: { ...initialState },
  toggleModal: () => {},
});

const ContactModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleModal = () => {
    dispatch({ type: 'TOGGLE_MODAL' });
  };

  const value = {
    state,
    toggleModal,
  };

  return <ContactModalContext.Provider value={value}>{children}</ContactModalContext.Provider>;
};

export default ContactModalProvider;

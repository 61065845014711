import React from 'react';

const AlumnusMemberDetails = ({ member }) => {
  return (
    <div className="alumnus__details">
      <span className="details__fullname--alumnus">
        {member.firstName} {member.lastName}
      </span>
    </div>
  );
};

export default AlumnusMemberDetails;

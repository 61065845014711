import React from 'react';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark-white.svg';

import {
  StyledWrapper,
  StyledContainer,
  StyledTextWrapper,
  StyledTitle,
  StyledList,
  StyledListItem,
  StyledTickWrapper,
  StyledListItemText,
  StyledButton,
  StyledImageDesktop,
  StyledImageMobile,
} from './index.styled';

const WhatsComingSection = ({
  internshipUrl,
  color,
  imageSrcDesktop,
  imageSrcMobile,
  title,
  listItems,
  tickColor,
  buttonLabel,
  buttonColor,
}) => {
  return (
    <StyledWrapper>
      <StyledContainer color={color}>
        <StyledImageDesktop src={imageSrcDesktop} alt={title} width={592} height={850} />
        <StyledImageMobile src={imageSrcMobile} alt={title} width={360} height={518} />
        <StyledTextWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledList>
            {listItems.map((text, index) => (
              <StyledListItem key={index}>
                <StyledTickWrapper width={28} height={28} color={tickColor}>
                  <CheckmarkIcon />
                </StyledTickWrapper>
                <StyledListItemText>{text}</StyledListItemText>
              </StyledListItem>
            ))}
          </StyledList>
          {internshipUrl && (
            <a href={internshipUrl} target="_blank" rel="noopener noreferrer">
              <StyledButton color={buttonColor}>{buttonLabel}</StyledButton>
            </a>
          )}
        </StyledTextWrapper>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default WhatsComingSection;

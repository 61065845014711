import { endpoints } from './common';
import axios from 'axios';
import { fileNameGuid } from 'utils/guid';
import { appendAndIfNeccessary } from 'utils/queryParameterBuilder';

export const login = ({ email, password }) => {
  return axios.post(endpoints.admin.login, { email, password });
};

export const newUser = (props) => {
  return axios.post(endpoints.admin.user.add, props);
};

export const editUser = (props) => {
  return axios.post(endpoints.admin.user.edit, props);
};

export const changeUserPassword = ({ id, oldPassword, newPassword }) => {
  return axios.post(endpoints.admin.user.changePassword, { id, oldPassword, newPassword });
};

export const setIsUserActive = ({ id, isActive }) => {
  return axios.post(endpoints.admin.user.setIsUserActive, { id, isActive });
};

export const getUsers = () => {
  return axios.get(endpoints.admin.user.getUsers);
};

export const getContactQuestionInfos = () => {
  return axios.get(endpoints.admin.contactQuestionInfo.getContactQuestionInfos);
};

export const getActiveContactQuestionInfos = () => {
  return axios.get(endpoints.admin.contactQuestionInfo.getActiveContactQuestionInfos);
};

export const newContactQuestionInfo = ({ displayText, doesHaveNewsletterOption, description }) => {
  return axios.post(endpoints.admin.contactQuestionInfo.add, { displayText, doesHaveNewsletterOption, description });
};

export const setIsContactQuestionInfoActive = ({ id, isActive }) => {
  return axios.post(endpoints.admin.contactQuestionInfo.setIsQuestionActive, { id, isActive });
};

export const sendEmailBatch = (contactQuestionInfoId, emailSubject, emailData, files, webAddress, attachedLibraryPaths) => {
  return axios.post(endpoints.admin.contactQuestion.sendEmailBatch, { contactQuestionInfoId, emailSubject, emailData, files, webAddress, attachedLibraryPaths });
};

export const setDoesHaveNewsletterOption = ({ id, doesHaveNewsletterOption }) => {
  return axios.post(endpoints.admin.contactQuestionInfo.setDoesHaveNewsletterOption, { id, doesHaveNewsletterOption });
};

export const editContactQuestionInfoText = ({ id, text }) => {
  return axios.post(endpoints.admin.contactQuestionInfo.editText, { id, text });
};

export const newContactQuestion = ({ senderMail, senderName, content, newsletterAgreed, contactQuestionInfoId }) => {
  return axios.post(endpoints.admin.contactQuestion.add, { senderMail, senderName, content, newsletterAgreed, contactQuestionInfoId });
};

export const getContactQuestions = (contactQuestionInfoId, newsletterAgreed, step) => {
  return axios.get(endpoints.admin.contactQuestion.getContactQuestions + `?id=${contactQuestionInfoId}&newsletterAgreed=${newsletterAgreed}&skipCount=${step}`);
};

export const getNewsletterSubscribers = (contactQuestionInfoId) => {
  return axios.get(endpoints.admin.contactQuestion.getNewsletterSubscribers + `?id=${contactQuestionInfoId}`);
};

export const editText = ({ id, text }) => {
  return axios.post(endpoints.admin.contactQuestion.editText, { id, text });
};

export const getNewsletterPreferences = () => {
  return axios.get(endpoints.admin.contactQuestion.getNewsletterPreferences);
};

export const setNewsletterPreferences = (newsletterPreferences) => {
  return axios.post(endpoints.admin.contactQuestion.setNewsletterPreferences, { newsletterPreferences });
};

export const deleteFileFromLibrary = fileName => {
  return axios.post(endpoints.library.delete, { fileName });
};

export const uploadToLibrary = file => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(endpoints.library.upload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadToPlaceholder = file => {
  const formData = new FormData();
  formData.append('file', file, fileNameGuid(file.name));
  return axios.post(endpoints.library.uploadPlaceHolder, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadToCaseStudyImageGallery = file => {
  const formData = new FormData();
  formData.append('file', file, fileNameGuid(file.name));
  return axios.post(endpoints.library.uploadCaseStudyImageGallery, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getAllFileNamesFromLibrary = () => {
  return axios.get(endpoints.library.getAll);
};

export const saveProject = project => {
  return axios.post(endpoints.admin.project.save, { ...project });
};

export const editProject = project => {
  return axios.post(endpoints.admin.project.edit, { ...project });
};

export const getAllProjects = () => {
  return axios.get(endpoints.project.getAll);
};

export const togglePublishProjectById = ({ projectId }) => {
  return axios.post(endpoints.admin.project.togglePublish, { projectId });
};

export const editProjectSortingOrder = projects => {
  return axios.post(endpoints.admin.project.editSort, { projects: projects });
};

export const getProject = ({ projectId }) => {
  return axios.get(`${endpoints.project.get}/${projectId}`);
};

export const saveAllProjectSections = ({ projectSections, projectId }) => {
  return axios.post(endpoints.admin.projectSections.save, { projectSections, projectId });
};

export const getFieldOfWorkTypes = () => {
  return axios.get(endpoints.admin.enum.getFieldOfWorkTypes);
};

export const getTargetAudienceTypes = () => {
  return axios.get(endpoints.admin.enum.getTargetAudienceTypes);
};

export const setProjectSkills = ({ projectSkills, projectId }) => {
  return axios.post(endpoints.admin.project.setProjectSkills, { projectSkills, projectId });
};

export const getAllPublishedProjects = () => {
  return axios.get(endpoints.project.getAllPublished);
};

export const takePublishedProjects = () => {
  return axios.get(endpoints.project.takePublished);
};

export const getPublished = ({ start, amount }) => {
  return axios.get(endpoints.project.getPublished + `?start=${start}&amount=${amount}`);
}

export const getAllEvents = () => {
  return axios.get(endpoints.event.getAll);
};

export const addEvent = ({ event }) => {
  return axios.post(endpoints.admin.event.add, event);
};

export const editEvent = ({ event }) => {
  return axios.post(endpoints.admin.event.edit, event);
};

export const deleteEvent = ({ event }) => {
  return axios.post(endpoints.admin.event.delete, { eventId: event.id });
};

export const uploadZip = file => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(endpoints.admin.zip.upload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteZip = ({ fileName }) => {
  return axios.post(endpoints.admin.zip.delete, { fileName });
};

export const deleteFileOnEventSectionById = ({ eventSectionId, fileName }) => {
  return axios.post(endpoints.admin.event.deleteFile, { eventSectionId, fileName });
};

export const getUserLogs = ({ start, amount, userActionType, content, email, day, month, year }) => {
  let baseRequest = endpoints.admin.user.getLogs;

  if (start === undefined && amount === undefined && content && email && day === undefined && month === undefined && year === undefined)
    return axios.get(baseRequest);

  baseRequest += '?';

  if (start === 0 || start)
    baseRequest += `start=${start}`;

  if (amount)
    baseRequest = appendAndIfNeccessary(baseRequest, 'amount', amount);

  if (userActionType)
    baseRequest = appendAndIfNeccessary(baseRequest, 'userActionType', userActionType);

  if (content)
    baseRequest = appendAndIfNeccessary(baseRequest, 'content', content);

  if (email)
    baseRequest = appendAndIfNeccessary(baseRequest, 'email', email);

  if (day)
    baseRequest = appendAndIfNeccessary(baseRequest, 'day', day);

  if (month)
    baseRequest = appendAndIfNeccessary(baseRequest, 'month', month);

  if (year)
    baseRequest = appendAndIfNeccessary(baseRequest, 'year', year);

  return axios.get(baseRequest);
}

export const getUserActionTypes = () => {
  return axios.get(endpoints.admin.enum.getUserActionTypes);
}

import React, { useState, useEffect } from 'react';
import './styles.css';
import AddEditUser from './AddEditUser';
import PreviewUser from './PreviewUser';
import { getUsers, changeUserPassword } from 'services/admin';

import Flex from 'components/styled/Flex';
import Loading from 'components/styled/Loading';

const passwordErrorText = 'Password needs to be at least 6 characters long and contain a number';

const Users = () => {
  const [users, _setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idOfEditingUser, setIdOfEditingUser] = useState(-1);
  const [oldPassword, _setOldPassword] = useState('');
  const [newPassword, _setNewPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState(passwordErrorText);

  const setUsers = users => {
    const usersSorted = users.sort((a, b) => a.id - b.id);
    _setUsers(usersSorted);
    setIdOfEditingUser(-1);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (passwordError !== '' && oldPasswordError !== '') return;
    setLoading(true);
    changeUserPassword({ oldPassword, newPassword })
      .then(() => {
        _setOldPassword('');
        _setNewPassword('');
        setOldPasswordError('');
        setPasswordError('');
        alert('Success!');
        setLoading(false);
      })
      .catch(() => {
        setOldPasswordError('Either your old password is wrong or your new password does not satisfy all rules');
        setLoading(false);
      });
  };

  const setOldPassword = ({ target: { value } }) => {
    setOldPasswordError('');
    _setOldPassword(value);
  };

  const setNewPassword = ({ target: { value } }) => {
    _setNewPassword(value);
    const isPasswordValid = value.match(/^(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{6,}/g);
    if (!isPasswordValid) setPasswordError(passwordErrorText);
    else setPasswordError('');
  };

  useEffect(() => {
    setLoading(true);
    getUsers()
      .then(({ data }) => setUsers(data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Flex direction="column">
        <div className="form__add__header">
          <span>Change password</span>
        </div>
        {loading && <Loading />}
        {!loading && (
          <form className="form__add" onSubmit={handleSubmit}>
            {oldPasswordError && <span className="form__error">{oldPasswordError}</span>}
            <label className="label">Old password</label>
            <input className="input" type="password" value={oldPassword} onChange={setOldPassword} />
            {passwordError && <span className="form__error">{passwordError}</span>}
            <label className="label">New password</label>
            <input className="input" type="password" value={newPassword} onChange={setNewPassword} />
            <div className="form__add__buttons">
              <input type="submit" className="button-admin submit-button" value="Submit" />
            </div>
          </form>
        )}
      </Flex>
      {<AddEditUser users={users} setUsers={setUsers} />}
      {loading && <Loading />}
      {!loading &&
        users.map(user => {
          if (idOfEditingUser === user.id)
            return (
              <AddEditUser key={user.id} user={user} users={users} setUsers={setUsers} setEdit={setIdOfEditingUser} />
            );
          return <PreviewUser key={user.id} user={user} edit={setIdOfEditingUser} />;
        })}
    </div>
  );
};

export default Users;

import React from 'react';

import {
  StyledWrapper,
  StyledContainer,
  StyledTextWrapper,
  StyledTitle,
  StyledDescription,
  StyledButton,
  StyledImageDesktop,
  StyledImageMobile,
} from './index.styled';

const QuizSection = ({
  color,
  imageSrcDesktop,
  imageSrcMobile,
  title,
  description,
  buttonLabel,
  buttonColor,
  buttonHref,
}) => {
  return (
    <StyledWrapper>
      <StyledContainer color={color}>
        <StyledImageDesktop src={imageSrcDesktop} alt={title} width={506} height={480} />
        <StyledImageMobile src={imageSrcMobile} alt={title} width={280} height={327} />
        <StyledTextWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{description}</StyledDescription>
          <a href={buttonHref} target="_blank" rel="noopener noreferrer">
            <StyledButton color={buttonColor}>{buttonLabel}</StyledButton>
          </a>
        </StyledTextWrapper>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default QuizSection;

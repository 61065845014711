import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  width: ${({ size }) => size || '40'}px;
  height: ${({ size }) => size || '40'}px;
  background-color: ${({ color }) => color || 'transparent'};
`;

const Icon = ({ color, image, alt, size }) => {
  return (
    <IconWrapper color={color} size={size}>
      <img src={image} alt={alt}></img>
    </IconWrapper>
  );
};

export default Icon;

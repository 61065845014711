import React, { useState } from 'react';
import AddEditTrustedPartner from './AddEditTrustedPartner';
import PreviewTrustedPartners from './PreviewTrustedPartners';
import './styles.css';

const TrustedPartnerBuilder = () => {
  const [trustedPartnerToEdit, setTrustedPartnerToEdit] = useState(null);
  const [trustedPartners, setTrustedPartners] = useState([]);

  return (
    <>
      <AddEditTrustedPartner setTrustedPartners={setTrustedPartners} />
      {trustedPartnerToEdit && (
        <AddEditTrustedPartner
          trustedPartner={trustedPartnerToEdit}
          setTrustedPartnerToEdit={setTrustedPartnerToEdit}
          setTrustedPartners={setTrustedPartners}
        />
      )}
      <PreviewTrustedPartners
        setTrustedPartnerToEdit={setTrustedPartnerToEdit}
        trustedPartners={trustedPartners}
        setTrustedPartners={setTrustedPartners}
      />
    </>
  );
};

export default TrustedPartnerBuilder;

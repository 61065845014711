import React from 'react';

import Step from './Step';
import { StyledWrapper, StyledContainer, StyledTitle } from './index.styled';

const AfterSignup = () => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledTitle>Proces prijave</StyledTitle>
        <div>
          <Step
            index="01"
            title="Prijava"
            description="Sviđa ti se što vidiš? Učini prvi korak i prijavi se na DUMP Internship."
          />
          <Step
            index="02"
            title="Intervju"
            description="Kada se prijave zatvore, pozivamo te na kratki razgovor na kojem te želimo malo bolje upoznati."
          />
          <Step
            index="03"
            title="Ispit"
            description="Uvjet za prolazak dalje i pozitivna ocjena iz jednostavnog predispita koji, ovisno o području, pišeš u tjednu kada su intervjui ili nakon prvog predavanja."
          />
          <Step
            index="04"
            title="Selekcija"
            description="Prati svoj sandučić i ne propusti obavijest o našoj odluci."
          />
          <Step index="05" title="Kickoff Internshipa" description="Prva predavanja počinju!" />
        </div>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default AfterSignup;

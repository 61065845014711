import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledDesktopImage = styled.img`
  width: 100%;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const StyledMobileImage = styled.img`
  width: 100%;

  @media screen and (min-width: 651px) {
    display: none;
  }
`;

export const StyledAbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledFormContainer = styled.div`
  margin-left: 104px;
  margin-top: 160px;
 
  @media screen and (max-width: 1500px) {    
    margin-right: 24px;
    margin-top: 200px;
  }

  @media screen and (max-width: 1250px) {
    margin-top: 100px;
  }

  @media screen and (max-width: 400px) {
    top: 80px;
  }
`;

export const StyledTitle = styled.h1`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 112px;
  line-height: 120px;
  color: #ffffff;
  max-width: 700px;
  margin: 0;

  @media screen and (max-width: 1500px) {
    font-size: 56px;
    line-height: 60px;
  }
`;

export const StyledSubtitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  max-width: 350px;
  margin: 24px 0 40px;

  @media screen and (max-width: 650px) {
    margin-top: 32px;
  }
`;

export const StyledButton = styled.button`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  background: #44c79b;
  border: none;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  padding: 14px 24px;
  border-radius: 8px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const StyledEmailInput = styled.input`
  background: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  outline: none;
  padding: 10px;
  margin-right: 24px;

  &:placeholder {
    color: #fff;
    opacity: 1;
  }

  @media screen and (max-width: 650px) {
    margin-right: unset;
    width: 100%;
    margin-bottom: 12px;
  }
`;

export const StyledMessage = styled.p`
  font-family: 'Fuse V.2 Display';
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 6px 0 0;
`;

import React from 'react';
import './styles.css';
import TrustedPartners from './TrustedPartners';

import Header from './Header';
import ProjectsInfo from './ProjectsInfo';
import EventsInfo from './EventsInfo';
import InternshipInfo from './InternshipInfo';
import HighlightedProjectList from './HighlightedProjectList';
import CountersWithContactForm from 'components/common/CountersWithContactForm';

const Landing = () => {
  return (
    <main>
      <Header />
      <ProjectsInfo />
      <TrustedPartners />
      <HighlightedProjectList />
      <EventsInfo />
      <InternshipInfo />
      <CountersWithContactForm />
    </main>
  );
};

export default Landing;

import React, { useState, useEffect } from 'react';
import { socialEventPush } from 'services/gtm';
import './styles.css';

import Icon from 'components/common/Icon';
import instagramIcon from 'assets/icons/ig-icon.svg';
import facebookIcon from 'assets/icons/fb-icon.svg';
import youtubeIcon from 'assets/icons/yt-icon.svg';
import { postConfigurationWithValue } from 'services/configuration';
import { addSeparators, roundToHundreds } from 'utils/number';

const SocialMediaCounter = () => {
  const [socialCounts, setSocialCounts] = useState();
  const [facebookFanCount, setFacebookFanCount] = useState('');
  const [youtubeSubscriberCount, setYoutubeSubscriberCount] = useState('');
  const [instagramFollowerCount, setInstagramFollowerCount] = useState('');

  useEffect(() => {
    postConfigurationWithValue({
      key: 'from-list',
      keys: ['FacebookFanCount', 'YoutubeSubscriberCount', 'InstagramFollowerCount'],
    })
      .then(res => {
        setSocialCounts(res && res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (socialCounts) {
      checkIfNullOrUndefined(socialCounts['facebookFanCount']) &&
        setFacebookFanCount(socialCounts['facebookFanCount'].value);
      checkIfNullOrUndefined(socialCounts['youtubeSubscriberCount']) &&
        setYoutubeSubscriberCount(socialCounts['youtubeSubscriberCount'].value);
      checkIfNullOrUndefined(socialCounts['instagramFollowerCount']) &&
        setInstagramFollowerCount(socialCounts['instagramFollowerCount'].value);
    }
  }, [socialCounts]);

  const checkIfNullOrUndefined = itemToCheck => {
    return itemToCheck !== null && itemToCheck !== undefined;
  };

  return (
    <div className="social-media-counter">
      {facebookFanCount !== '' && (
        <a
          className="facebook-counter"
          href="https://www.facebook.com/dump.hr/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => socialEventPush('Facebook')}
        >
          <Icon image={facebookIcon} alt="Facebook" />
          <span>{addSeparators(roundToHundreds(facebookFanCount))}+</span>
        </a>
      )}
      {youtubeSubscriberCount !== '' && (
        <a
          className="youtube-counter"
          href="https://www.youtube.com/user/DUMPSnimke"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => socialEventPush('Youtube')}
        >
          <Icon image={youtubeIcon} alt="Youtube" />
          <span>{addSeparators(roundToHundreds(youtubeSubscriberCount))}+</span>
        </a>
      )}
      {instagramFollowerCount !== '' && (
        <a
          className="instagram-counter"
          href="https://www.instagram.com/dumpovci/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => socialEventPush('Instagram')}
        >
          <Icon image={instagramIcon} alt="Instagram" />
          <span>{addSeparators(roundToHundreds(instagramFollowerCount))}+</span>
        </a>
      )}
    </div>
  );
};

export default SocialMediaCounter;

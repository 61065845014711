export const addLinkToText = text => {
  if (!text) {
    return '';
  }

  const httpsUrls = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  const httpsLinkTemplate = '<a class="short-description__link" href=$1 target="_blank" rel="noopener noreferrer">$1</a>';
  text = text.replace(httpsUrls, httpsLinkTemplate);

  const wwwUrls = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  const wwwLinkTemplate = '$1<a class="short-description__link" href="http://$2" target="_blank" rel="noopener noreferrer">$2</a>';
  text = text.replace(wwwUrls, wwwLinkTemplate);

  return text;
};

import React from 'react';

import {
  StyledWrapper,
  StyledContainer,
  StyledImage,
  StyledTextWrapper,
  StyledTitle,
  StyledDescription,
  StyledTagListWrapper,
  StyledTag,
} from './index.styled';

const TextRightSection = ({ imageSrc, title, description, tags }) => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledTextWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{description}</StyledDescription>
          <StyledTagListWrapper>
            {tags.map((tag, index) => (
              <StyledTag key={index}>{tag}</StyledTag>
            ))}
          </StyledTagListWrapper>
        </StyledTextWrapper>
        <StyledImage src={imageSrc} alt={title} />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default TextRightSection;

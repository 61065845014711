import React from 'react';
import '../styles.css';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import ToolbarMenuItemStyledSvg from '../ToolbarMenuItemStyledSvg';

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) Editor.removeMark(editor, format);
  else Editor.addMark(editor, format, true);
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const MarkButton = ({ format }) => {
  const editor = useSlate();
  return (
    <ToolbarMenuItemStyledSvg
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <img src={require(`assets/icons/${format}.svg`)} alt={format} />
    </ToolbarMenuItemStyledSvg>
  );
};

export default MarkButton;

import React from 'react';

import { ReactComponent as XIcon } from 'assets/icons/x-white.svg';
import MarkHeaderImage from 'assets/visuals/internship/mark-header.png';
import MarkFirstIcon from 'assets/icons/internship/modals/mark-icon-1.svg';
import MarkSecondIcon from 'assets/icons/internship/modals/mark-icon-2.svg';
import MarkThirdIcon from 'assets/icons/internship/modals/mark-icon-3.svg';
import MarkFirstImage from 'assets/visuals/internship/mark-1.png';
import MarkSecondImage from 'assets/visuals/internship/mark-2.png';
import MarkThirdImage from 'assets/visuals/internship/mark-3.png';
import MarkHowItLooksImage from 'assets/visuals/internship/mark-how-it-looks.png';
import MarkExpectationsImageDesktop from 'assets/visuals/internship/mark-expectations.png';
import MarkExpectationsImageMobile from 'assets/visuals/internship/mark-expectations-mobile.png';

import Header from './Header';
import TextRightSection from './TextRightSection';
import TextLeftSection from './TextLeftSection';
import HowItLooksSection from './HowItLooksSection';
import WhatsComingSection from './WhatsComingSection';
import SuggestedDisciplines from './SuggestedDisciplines';

import { Discipline } from '../consts';
import { Color, StyledWrapper, StyledContainer, StyledCloseButton, StyledTextSectionsWrapper } from './index.styled';

const MarketingModal = ({ internshipUrl, isOpen, close, setActiveDisciplineModal }) => {
  return (
    <StyledWrapper isOpen={isOpen} color={Color.blue}>
      {isOpen && (
        <StyledContainer>
          <StyledCloseButton onClick={close}>
            <XIcon />
          </StyledCloseButton>

          <Header
            internshipUrl={internshipUrl}
            backgroundColor={Color.lightBlue}
            seeThroughColor={Color.seeThroughBlue}
            title="Marketing"
            description="Da se dobar glas čuje daleko, to je naša dužnost! Uz usku suradnju s Dizajnom i Multimedijom, radimo na kreativnim konceptima te porukama kojima privlačimo naše publike."
            perks={[
              {
                iconSrc: MarkFirstIcon,
                label: 'Psihologija publike',
              },
              {
                iconSrc: MarkSecondIcon,
                label: 'Društvene mreže',
              },
              {
                iconSrc: MarkThirdIcon,
                label: 'Kampanje i Analitika',
              },
            ]}
            buttonLabel="Prijavi se na Marketing"
            buttonColor={Color.lightGreen}
            imageSrc={MarkHeaderImage}
          />
          <StyledTextSectionsWrapper>
            <TextRightSection
              imageSrc={MarkFirstImage}
              title="Psihologija publike"
              description="Na marketingu ćeš krenuti s učenjem od samih početaka i osnovnih teorija, a najviše ćeš se dotaknuti psihologije publike što će ti pomoći u istraživanju tržišta i definiranju ciljne publike tijekom rada na kampanjama."
              tags={['SWOT', 'TOWS', 'Segmentacija', 'Interesi', 'Demografija', 'Ponašanje', 'Marketing mix']}
            />
            <TextLeftSection
              title="Društvene mreže"
              description="Zatim prelazimo na društvene mreže. Naučit ćeš kako tvoja publika diše i zakoračit ćeš u svijet alata za plaćene oglase."
              tags={['Facebook', 'Instagram', 'Tiktok', 'Linkedin', 'YouTube', 'Organic', 'Paid']}
              imageSrc={MarkSecondImage}
            />
            <TextRightSection
              imageSrc={MarkThirdImage}
              title="Kampanje i Analitika"
              description="Sad dolazi ono najzabavnije: sve naučeno ćeš iskoristiti pri izgradnji kampanja na projektima Udruge te kroz analizu otkriti što je to što pali kod publike!"
              tags={['Citylights', 'Billboards', 'Ads Manager', 'Pixel', 'SEO', 'SEM', 'E-mail']}
            />
          </StyledTextSectionsWrapper>
          <HowItLooksSection
            title="Kako će sve to izgledat?"
            imageSrc={MarkHowItLooksImage}
            features={[
              {
                title: 'Predavanja',
                description: 'Sve cjeline ćeš teoretski obraditi, a ciljem da budeš spreman za radionice.',
              },
              {
                title: 'Radionice',
                description:
                  'Gledaj na ovo kao na drugu polovicu Marketing Internshipa - većina teorije je odrađena i sada te čekaju praktični zadaci.',
              },
              {
                title: 'Usvajanje znanja',
                description:
                  'Brojni stručnjaci će se pridružiti predavanjima i radionicama da tebi prenesu svoje znanje i iskustvo.',
              },
              {
                title: 'Timski rad',
                description: 'U marketingu je tim dosta važan jer zajedno ćete brainstormati nove ideje za kampanje!',
              },
            ]}
          />
          <WhatsComingSection
            internshipUrl={internshipUrl}
            color={Color.lightBlue}
            imageSrcDesktop={MarkExpectationsImageDesktop}
            imageSrcMobile={MarkExpectationsImageMobile}
            title="Što te očekuje?"
            listItems={[
              'Upoznavanje sa svijetom marketinga',
              'Zajednički napredak uz savjetovanje i mentorstvo',
              'Individualni i grupni radovi',
              'Super ekipa',
            ]}
            tickColor={Color.lightGreen}
            buttonLabel="Prijavi se na marketing"
            buttonColor={Color.lightGreen}
          />
          <SuggestedDisciplines
            activeDisciplineKey={Discipline.marketing}
            setActiveDisciplineModal={setActiveDisciplineModal}
          />
        </StyledContainer>
      )}
    </StyledWrapper>
  );
};

export default MarketingModal;

import React, { useState, useEffect } from 'react';
import './styles.css';
import AddContactQuestionInfo from './AddContactQuestionInfo';
import PreviewContactQuestionInfo from './PreviewContactQuestionInfo';
import { getContactQuestionInfos } from 'services/admin';
import Loading from 'components/styled/Loading';

const ContactQuestionInfos = () => {
  const [contactQuestionInfos, _setContactQuestionInfos] = useState([]);
  const [loading, setLoading] = useState(false);

  const setContactQuestionInfos = contactQuestionInfosToSet => {
    const sortedContactQuestionInfos = contactQuestionInfosToSet.sort((firstQuestion, secondQuestion) => firstQuestion.id < secondQuestion.id ? 1 : -1);
    _setContactQuestionInfos(sortedContactQuestionInfos);
  }

  useEffect(() => {
    setLoading(true);
    getContactQuestionInfos()
      .then(({ data }) => setContactQuestionInfos(data))
      .finally(() => setLoading(false));
  }, []);

  return <div>
    <AddContactQuestionInfo contactQuestionInfos={contactQuestionInfos} setContactQuestionInfos={setContactQuestionInfos} />
    {loading && <Loading />}
    {
      !loading && contactQuestionInfos.map(contactQuestionInfo => {
        return <PreviewContactQuestionInfo
          key={contactQuestionInfo.id}
          contactQuestionInfo={contactQuestionInfo}
          contactQuestionInfos={contactQuestionInfos} setContactQuestionInfos={setContactQuestionInfos}
        />
      })
    }
  </div>
}

export default ContactQuestionInfos;

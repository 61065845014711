import React, { useState, useEffect } from 'react';

import { S3_BUCKET_BASE_URL as base } from 'services/common';
import { getLatestEventImages } from 'services/event';
import { useWindowSize, mediaLessThan } from 'utils/media';
import useFallbackImage from 'hooks/useFallbackImage';
import { normalizeForUrl } from 'utils/normalizeForUrl';

import AnimatedCarousel from 'components/common/AnimatedCarousel';

import { StyledSpacing } from '../index.styled';
import {
  StyledContainer,
  StyledTextContainer,
  StyledTitle,
  StyledEventsCamera,
  StyledEventLink,
  StyledEventPoster,
} from './index.styled';
import { events } from './mock';


const WhatDoWeDo = () => {
  const [latestEvents, setLatestEvents] = useState(null);
  useWindowSize();

  const { setFallbackImageSource } = useFallbackImage('./images/posts/fallback.png');

  useEffect(() => {
    getLatestEventImages({ numberOfEvents: 6 })
      .then(response => {
        setLatestEvents(response.data);
      })
      .catch(() => {
        setLatestEvents(events);
      });
  }, []);

  if (!latestEvents) {
    return null;
  }

  const isMobile = mediaLessThan(767);

  const cardWidth = isMobile ? 288 : 384;
  const cardHeight = isMobile ? 176 : 228;
  const cardMargin = isMobile ? 24 : 32;

  const getPosterImagePath = event => {
    if (event.coverImagePath?.folder && event.coverImagePath?.image) {
      return `${base}/images/${event.coverImagePath.folder}/${event.coverImagePath.image}`;
    }
  };

  return (
    <StyledContainer>
      <StyledSpacing>
        <StyledTextContainer>
          <StyledTitle>Čime se bavimo u DUMP-u?</StyledTitle>
        </StyledTextContainer>
      </StyledSpacing>
      <StyledEventsCamera>
        <AnimatedCarousel margin={cardMargin} duration={50} direction="left">
          {latestEvents.map(event => (
            <StyledEventLink
              key={event.id}
              to={{
                pathname: `/events/${event.id}-${normalizeForUrl(event.name)}`,
                state: { event },
              }}
            >
              <StyledEventPoster
                alt={`${event.name} image`}
                onError={setFallbackImageSource}
                src={getPosterImagePath(event)}
                width={cardWidth}
                height={cardHeight}
              />
            </StyledEventLink>
          ))}
        </AnimatedCarousel>
      </StyledEventsCamera>
    </StyledContainer>
  );
};

export default WhatDoWeDo;

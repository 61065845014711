import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Flex, { FlexHeader } from 'components/styled/Flex';
import logo from 'assets/icons/dump.svg';
import ContactForm from 'screens/ContactForm';
import { useWindowSize, mediaLessThan, mediaGreaterThan } from 'utils/media.js';

import { ContactModalContext } from 'services/providers/ContactModalProvider';
import { MenuStateContext } from 'services/providers/MenuProvider';

const FlexHeaderStyled = styled(FlexHeader)`
  padding: 40px 132px 30px 82px;
  background-color: #fff;
  z-index: 4;

  ${({ isExpanded }) => isExpanded && 'z-index: 5;'}

  @media screen and (max-width: 1240px) {
    padding: 40px 50px 30px 30px;
  }

  @media screen and (max-width: 500px) {
    padding: 40px 25px 30px;
  }
`;

const MobileWrapper = styled(Flex)`
  transition: transform 0.6s ease-out;

  top: ${({ isNextEventDisplayed }) => (isNextEventDisplayed ? 'calc(-160vh + 97px)' : 'calc(-100vh + 97px)')};
  transform: ${({ isExpanded, isNextEventDisplayed }) =>
    isExpanded && (isNextEventDisplayed ? 'translateY(160vh)' : 'translateY(100vh)')};
`;

const Navigation = () => {
  const modalContext = useContext(ContactModalContext);
  const menuContext = useContext(MenuStateContext);

  const {
    state: { isExpanded, isNextEventDisplayed },
    setIsExpanded,
  } = menuContext;

  useWindowSize();

  useEffect(() => {
    modalContext.state.isModalActive
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'scroll');
  }, [modalContext.state.isModalActive]);

  useEffect(() => {
    document.body.style.overflowY = isExpanded ? 'hidden' : 'unset';
    document.getElementsByTagName('html')[0].style.overflowY = isExpanded ? 'hidden' : 'unset';

    if (isExpanded) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [isExpanded]);

  const closeHamburger = () => {
    setIsExpanded(false);
  };

  const handleHamburgerClick = () => {
    setIsExpanded(!isExpanded);
  };

  mediaGreaterThan(769) && isExpanded && closeHamburger();

  return (
    <FlexHeaderStyled
      justifyContent="space-between"
      position="relative"
      isExpanded={isExpanded}
      className={isExpanded && 'header__gradient'}
    >
      <Link className="header__dump" to="/">
        <img alt="Dump logo" src={logo} onClick={closeHamburger} />
      </Link>

      {mediaLessThan(768) && (
        <button aria-label="open navigation" className="header__menu__button" onClick={handleHamburgerClick}>
          <div className="hamburger" alt={`Header ${isExpanded ? 'close' : 'open'}`}>
            <div className={`bar-1 ${isExpanded && 'bar-1-animation'}`}></div>
            <div className={`bar-2 ${isExpanded && 'bar-2-animation'}`}></div>
          </div>
        </button>
      )}
      {mediaGreaterThan(769) && (
        <div className="header__link__list">
          <Link rel="noopener" to="/events" className="header__link">
            Događaji
          </Link>
          <Link rel="noopener" to="/projects" className="header__link">
            Projekti
          </Link>
          <Link rel="noopener" to="/about" className="header__link">
            O nama
          </Link>
          <Link rel="noopener" to="/internship" className="header__link">
            Postani član
          </Link>
          <button className="contact-button contact-button" onClick={modalContext.toggleModal}>
            Kontakt
          </button>
        </div>
      )}
      {mediaLessThan(768) && (
        <MobileWrapper
          position="absolute"
          justifyContent="start"
          direction="column"
          className="navigation-modal"
          isExpanded={isExpanded}
          isNextEventDisplayed={isNextEventDisplayed}
        >
          <Link rel="noopener" to="/events" className="header__link--mobile" onClick={closeHamburger}>
            Događaji
          </Link>
          <Link rel="noopener" to="/projects" className="header__link--mobile" onClick={closeHamburger}>
            Projekti
          </Link>
          <Link rel="noopener" to="/about" className="header__link--mobile" onClick={closeHamburger}>
            O nama
          </Link>
          <Link rel="noopener" to="/internship" className="header__link--mobile" onClick={closeHamburger}>
            Postani član
          </Link>
          <button className="contact-button contact-button--mobile" onClick={modalContext.toggleModal}>
            Kontakt
          </button>
        </MobileWrapper>
      )}
      {modalContext.state.isModalActive && <ContactForm modal onModalToggle={modalContext.toggleModal} />}
    </FlexHeaderStyled>
  );
};

export default Navigation;

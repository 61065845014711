export const faqs = [
  {
    question: 'Do kada se mogu prijaviti?',
    answer: 'Prijave su otvorene do 19. listopada.',
  },
  {
    question: 'Gdje se i kada održavaju predavanja?',
    answer: 'Predavanja i radionice se održavaju vikendom, uglavnom u prostorijama FESB-a.',
  },
  {
    question: 'Trebam li se pripremiti za intervju?',
    answer:
      'Ne trebaš! Internship je namijenjen za početnike koji nisu nužno imali doticaja s prijavljenim područjem. Jedino programeri trebaju imati znanje o osnovama programiranja, što će se utvrditi testom.',
  },
  {
    question: 'Trebam li slati radove?',
    answer: 'Ako imaš ikakav portfolio, vrlo rado bismo ga pogledali, no radovi nisu presudni za našu konačnu odluku.',
  },
  {
    question: 'Kad ćete mi se javiti s odlukom?',
    answer: 'Dobit ćeš naš odgovor unutar dva tjedna od intervjua.',
  },
  {
    question: 'Kako da odaberem područje?',
    answer:
      'Tijekom ispunjavanja prijave imat ćeš višestruki izbor za biranje područja - samo stisni checkbox i mi ćemo znati. Ne moraš dva ili više puta ispunjavati formu za svako područje. ',
  },
  {
    question: 'Koliko vremena treba izdvojiti svaki tjedan za Internship?',
    answer:
      'Ovisi o području i individualnoj brzini savladavanja gradiva; u pravilu je to oko 10 sati na tjednoj bazi.',
  },
  {
    question: 'Što ako ne mogu doći na predavanje ili ne stignem napraviti domaći rad?',
    answer:
      'Prilično smo fleksibilni po ovom pitanju - pod uvjetom da nam se javi bar par dana ranije da nešto neće biti na vrijeme. Redovito izvršavanje obveza nam je prioritet unutar Udruge, što znači da se neispunjavanje obveza bez razloga neće tolerirati.',
  },
  {
    question: 'Što je svrha Internshipa?',
    answer:
      'Preko Internshipa educiramo određen broj ljudi u punoj većoj mjeri i obujmu nego što to možemo napraviti preko naših ciklusa predavanja. Polaznici dobiju znanje i iskustvo od A do Ž za profesionalni rad u određenom području. Uz to, Internship je glavni način na koji Udruga dobiva nove članove.',
  },
];

import React, { useState, useEffect } from 'react';

import Flex from 'components/styled/Flex';
import Loading from 'components/styled/Loading';

import { getArrayOfRolesFromString, getStringOfRolesFromArray } from 'utils/roleParser';
import { mapArrayToMultiselectOptions, mapMultiselectOptionsToArray } from 'utils/multiselectMapper';
import { UserRoleType } from 'constants/userRoleType';
import { newUser, editUser } from 'services/admin';

import './styles.css';
import MultiSelect from 'react-multi-select-component';

const userRolesOptions = Object.values(UserRoleType).map(urt => ({
  label: urt,
  value: urt,
}));

const AddEditUser = ({ user, users, setUsers, setEdit }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [roles, setRoles] = useState(user?.roles || '');

  const initialRoleOptions = mapArrayToMultiselectOptions(getArrayOfRolesFromString(user?.roles || ''));
  const [roleOptions, setRoleOptions] = useState(initialRoleOptions);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  if (email.length === 0 && user !== undefined) setEmail(user.email);

  useEffect(() => {
    const chosenRoles = mapMultiselectOptionsToArray(roleOptions);
    const roleString = getStringOfRolesFromArray(chosenRoles);

    setRoles(roleString);
  }, [roleOptions])

  const handleSubmit = event => {
    event.preventDefault();
    setError('');
    setLoading(true);
    const hasUserWithEmail = users.filter(userFilter => userFilter.email === email
      && userFilter.id !== user?.id).length > 0;
    if (hasUserWithEmail) {
      setError('User with email exsists');
      setLoading(false);
      return;
    }
    if (!roleOptions.length) {
      setError('Choose at least one role');
      setLoading(false);
      return;
    }
    if (user === undefined)
      return newUser({ email, roles, firstName, lastName })
        .then(({ data }) => {
          setUsers([...users, { id: data, email, isActive: true, roles, firstName, lastName }]);
        })
        .catch(error => setError('Failed to add user'))
        .finally(() => setLoading(false));
    else
      return editUser({ id: user.id, email, roles, firstName, lastName })
        .then(({ data }) => {
          const filteredUsers = users.filter(userFilter => userFilter.id !== user.id);
          setUsers([...filteredUsers, { id: user.id, email, isActive: user.isActive, roles, firstName, lastName }]);
        })
        .catch(error => setError('Failed to edit user'))
        .finally(() => setLoading(false));
  };

  return (
    <Flex direction="column">
      {!user && (
        <div className="form__add__header">
          <span>Add User</span>
        </div>
      )}
      {loading && <Loading />}
      {!loading && (
        <form className="form__add" onSubmit={handleSubmit}>
          {error && <span className="form__error">{error}</span>}
          <label className="label" htmlFor="email">
            Email
          </label>
          <input className="input" type="email" value={email} onChange={e => setEmail(e.target.value)} />
          <label className="label" htmlFor="firstName">
            First name
          </label>
          <input className="input" name="firstName"
            value={firstName} onChange={e => setFirstName(e.target.value)} />
          <label className="label" htmlFor="lastName">
            Last name
          </label>
          <input className="input" name="lastName"
            value={lastName} onChange={e => setLastName(e.target.value)} />
          <label className="label" htmlFor="userRole">
            Role
          </label>
          <MultiSelect
            value={roleOptions}
            onChange={setRoleOptions}
            options={userRolesOptions}
          />
          <div className="form__add__buttons">
            <input type="submit" className="button-admin submit-button" value="Submit" />
            {user && (
              <button className="button-admin cancel-button" onClick={() => setEdit(-1)}>
                Cancel
              </button>
            )}
          </div>
        </form>
      )}
    </Flex>
  );
};

export default AddEditUser;

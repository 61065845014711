import React, { useState } from 'react';
import './styles.css';
import { parseActivity } from 'utils/ActivityParser';
import { setIsContactQuestionInfoActive, setDoesHaveNewsletterOption, editContactQuestionInfoText } from 'services/admin';
import Loading from 'components/styled/Loading';

const PreviewContactQuestionInfo = ({ contactQuestionInfo, contactQuestionInfos, setContactQuestionInfos }) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newText, setNewText] = useState(contactQuestionInfo.displayText);
  const [newDescription, setNewDescription] = useState(contactQuestionInfo.description);
  const [error, setError] = useState('');

  const setContactQuestionInfoIsActive = ({ target: { value } }) => {
    if (!window.confirm(`Do you really want to ${parseActivity(value)} contact question ${contactQuestionInfo.displayText}`))
      return;

    const hasUniqueDisplayText = contactQuestionInfos.filter(contactQuestionInfoFilter => (
      contactQuestionInfoFilter.displayText === newText && contactQuestionInfoFilter.isActive && contactQuestionInfoFilter.id !== contactQuestionInfo.id)).length > 0;

    if (hasUniqueDisplayText && value) {
      setError('Contact question already exists');
      return;
    }

    setLoading(true);

    setIsContactQuestionInfoActive({ id: contactQuestionInfo.id, isActive: value })
      .then(({ data }) => {
        const filteredContactQuestionInfos = contactQuestionInfos.filter(contactQuestionInfoFilter => contactQuestionInfoFilter.id !== contactQuestionInfo.id);

        setContactQuestionInfos([
          {
            id: contactQuestionInfo.id,
            displayText: contactQuestionInfo.displayText,
            doesHaveNewsletterOption: contactQuestionInfo.doesHaveNewsletterOption,
            isActive: value === "true" ? true : false,
            description: contactQuestionInfo.description
          },
          ...filteredContactQuestionInfos
        ]);
      })
      .finally(() => {
        setLoading(false);
        setError('');
      });
  }

  const handleEditInput = (event) => {
    setNewText(event.target.value);
  }

  const handleEditDescriptionInput = (event) => {
    setNewDescription(event.target.value);
  }

  const confirmEdit = ({ target: { value } }) => {
    if (newText === '') {
      setEditing(false);
      return;
    }

    if (!window.confirm(`Are you sure?`))
      return;

    const hasUniqueDisplayText = contactQuestionInfos.filter(contactQuestionInfoFilter => (
      contactQuestionInfoFilter.displayText.toLowerCase() === newText.toLowerCase() && contactQuestionInfoFilter.isActive && contactQuestionInfoFilter.id !== contactQuestionInfo.id)).length > 0;

    if (hasUniqueDisplayText && value) {
      setError('Contact question already exists');
      return;
    }

    setLoading(true);

    editContactQuestionInfoText({ id: contactQuestionInfo.id, text: newText, description: newDescription })
      .then(({ data }) => {
        const filteredContactQuestionInfos = contactQuestionInfos.filter(contactQuestionInfoFilter => contactQuestionInfoFilter.id !== contactQuestionInfo.id);

        setContactQuestionInfos([
          {
            id: contactQuestionInfo.id,
            displayText: newText,
            doesHaveNewsletterOption: contactQuestionInfo.doesHaveNewsletterOption,
            isActive: contactQuestionInfo.isActive,
            description: newDescription
          },
          ...filteredContactQuestionInfos
        ]);
      })
      .finally(() => {
        setLoading(false);
        setEditing(false);
        setError('');
      });
  }

  const setNewsletterIsActive = ({ target: { value } }) => {
    if (!window.confirm(`Do you really want to ${parseActivity(value)} newsletter option for ${contactQuestionInfo.displayText}`))
      return;

    setLoading(true);

    setDoesHaveNewsletterOption({ id: contactQuestionInfo.id, doesHaveNewsletterOption: value })
      .then((data) => {
        const filteredContactQuestionInfos = contactQuestionInfos.filter(contactQuestionInfoFilter => contactQuestionInfoFilter.id !== contactQuestionInfo.id);

        setContactQuestionInfos([
          ...filteredContactQuestionInfos,
          {
            id: contactQuestionInfo.id,
            displayText: contactQuestionInfo.displayText,
            doesHaveNewsletterOption: value === "true" ? true : false,
            isActive: contactQuestionInfo.isActive,
            description: contactQuestionInfo.description
          }
        ]);
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="contact-question__preview__container">
      {loading && <Loading />}
      {error && <span className="contact-question__error">{error}</span>}
      {!loading && <div className="contact-question__preview">
        <div className="contact-question__preview__left">
          {editing ?
            <>
              <input className="input contact-question__preview__edit-text" type="text" placeholder={contactQuestionInfo.displayText} onChange={handleEditInput} />
              <input className="input contact-question__preview__edit-description" type="text" placeholder={contactQuestionInfo.description} onChange={handleEditDescriptionInput} />
            </> :
            <>
              <span className="contact-question__preview__display-text">{contactQuestionInfo.id}</span>
              <span className="contact-question__preview__display-text">{contactQuestionInfo.displayText}</span>
              <span className="contact-question__preview__display-description">{contactQuestionInfo.description}</span>
            </>}
        </div>
        <div className="contact-question__preview__controls">
          <button className="button edit-button" value={!contactQuestionInfo.isActive} onClick={editing ? confirmEdit : () => setEditing(true)}>{editing ? "Confirm" : "Edit text"}</button>
          <button className={`button ${parseActivity(contactQuestionInfo.isActive)}-button`} value={!contactQuestionInfo.isActive} onClick={setContactQuestionInfoIsActive}>
            {parseActivity(contactQuestionInfo.isActive)} option
          </button>
          <button className={`button ${parseActivity(contactQuestionInfo.doesHaveNewsletterOption)}-button`} value={!contactQuestionInfo.doesHaveNewsletterOption} onClick={setNewsletterIsActive}>
            {parseActivity(contactQuestionInfo.doesHaveNewsletterOption)} newsletter
          </button>
        </div>
      </div>}
    </div>
  )
}

export default PreviewContactQuestionInfo;

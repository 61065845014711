import { SectionType } from 'constants/sectionType';
import { ImagePathLogic } from './imagePathLogic';

export default class ProjectSection extends ImagePathLogic {
  id;
  title;
  text;
  sectionType;
  sectionOrder;
  projectId;
  imagePath;

  constructor(sectionOrder, sectionType, projectId, imagePath) {
    super(imagePath);
    this.title = '';
    this.sectionOrder = sectionOrder;
    this.sectionType = sectionType;
    this.projectId = projectId;
    this.text = [];
    if (sectionType !== SectionType.ImageGallery) {
      this.text = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];
    }
  }
}

export const getStyledComponentProps = projectSection => {
  switch (projectSection.sectionType) {
    case SectionType.OnlyTextCenter:
      return {
        justifyContent: 'center',
      };
    case SectionType.TextLeftImageRight:
      return {
        justifyContent: 'flex-end',
      };
    case SectionType.TextRightImageLeft:
      return {
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
      };
    case SectionType.OnlyImageCenter:
      return {
        justifyContent: 'center',
      };
    case SectionType.OnlyTextLeft:
      return {
        justifyContent: 'flex-start',
      };
    case SectionType.OnlyTextRight:
      return {
        justifyContent: 'flex-end',
      };
    case SectionType.TextColumns:
      return {
        justifyContent: 'center',
      };
    default:
      return 'Ops';
  }
};

export const hasImage = projectSection =>
  projectSection.sectionType === SectionType.TextLeftImageRight ||
  projectSection.sectionType === SectionType.OnlyImageCenter ||
  projectSection.sectionType === SectionType.TextRightImageLeft ||
  projectSection.sectionType === SectionType.ImageGallery;

export const isSingleTextEditor = projectSection =>
  projectSection.sectionType === SectionType.OnlyTextCenter ||
  projectSection.sectionType === SectionType.OnlyTextLeft ||
  projectSection.sectionType === SectionType.OnlyTextRight;

export const isImageOnly = projectSection =>
  projectSection.sectionType === SectionType.OnlyImageCenter || projectSection.sectionType === SectionType.ImageGallery;
export const isTripleTextEditor = projectSection => projectSection.sectionType === SectionType.TripleTextEditor;
export const isImageGallery = projectSection => projectSection.sectionType === SectionType.ImageGallery;
export const isTwoThirdsOfScreen = projectSection => projectSection.sectionType === SectionType.OnlyImageCenter;

export const serializeProjectSection = projectSection => {
  projectSection.text = JSON.stringify(projectSection.text);
};

export const deserializeProjectSection = projectSection => {
  projectSection.text = JSON.parse(projectSection.text);
};

import React from 'react';

import useIntersect from 'utils/intersect';

import { StyledStepWrapper, StyledStepTitle, StyledStepDescription, StyledIndex } from './index.styled';

const Step = ({ index, title, description }) => {
  const [ref, entry] = useIntersect({
    threshold: 1,
  });

  const { isIntersecting } = entry;

  return (
    <StyledStepWrapper ref={ref}>
      <StyledIndex isIntersecting={isIntersecting}>{index}</StyledIndex>
      <div>
        <StyledStepTitle>{title}</StyledStepTitle>
        <StyledStepDescription>{description}</StyledStepDescription>
      </div>
    </StyledStepWrapper>
  );
};

export default Step;

import React from 'react';

import { disciplineCardsConfig } from './const';
import {
  StyledContainer,
  StyledWrapper,
  StyledSubtitle,
  StyledTitle,
  StyledCardsListWrapper,
  StyledCardWrapper,
  StyledCardLabel,
  StyledCardImage,
} from './index.styled';

const SuggestedDisciplines = ({ activeDisciplineKey, setActiveDisciplineModal }) => {
  const disciplineKeysToDisplay = Object.keys(disciplineCardsConfig).filter(key => key !== activeDisciplineKey);

  const disciplinesToDisplay = disciplineKeysToDisplay.reduce((acc, curr) => {
    acc[curr] = disciplineCardsConfig[curr];

    return acc;
  }, {});

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledSubtitle>Nije tvoj đir?</StyledSubtitle>
        <StyledTitle>Baci oko na ostala područja</StyledTitle>
        <StyledCardsListWrapper>
          {Object.keys(disciplinesToDisplay).map(key => {
            const { label, imageSrc } = disciplinesToDisplay[key];

            return (
              <StyledCardWrapper key={key} onClick={() => setActiveDisciplineModal(key)}>
                <StyledCardLabel>{label}</StyledCardLabel>
                <StyledCardImage src={imageSrc} alt={label} width={386} height={156} />
              </StyledCardWrapper>
            );
          })}
        </StyledCardsListWrapper>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default SuggestedDisciplines;

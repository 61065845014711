import styled from 'styled-components';

const TextUnderline = styled.span`
  font-family: inherit;
  line-height: inherit;
  display: inline-block;
  position: relative;
  letter-spacing: -0.42px;
  font-size: ${({ fontSize }) => fontSize || 'inherit'};

  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ color }) => color && `color: ${color}`};

  ::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: ${({ underlineLeftOffset }) => underlineLeftOffset || '4'}px;
    right: ${({ underlineRightOffset }) => underlineRightOffset || '4'}px;
    border-bottom: ${({ borderWidth }) => borderWidth || '9'}px solid #5cc2a0;

    ${({ underlineColor }) => underlineColor && `border-color: ${underlineColor}`}
    ${({ underlineOpacity }) => underlineOpacity && `opacity: ${underlineOpacity}`}
  }

  ${({ mediaQuery }) => mediaQuery ? mediaQuery : ''}
`;

export default TextUnderline;

import React from 'react';
import styled from 'styled-components';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { guid } from 'utils/guid';
import { FileType } from 'constants/fileType';

const FileBoxStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #D8D8D8;
  color: #D8D8D8;
  font-size: 18px;

  & span::selection  {
    display: none;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  & img {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-right: 2px;[]
  }

  & ~ input[type="file"] {
    display: none;
  }

  & ~ label {
    display: block;
    border: 2px solid #D8D8D8;
    color: #000;
    margin-top: 4px;
    text-align: center;
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: #E6FFED;
    border: 2px dashed #E6FFED;
  `}
`;

const FileBox = ({ onDrop, fileType }) => {
  const id = guid();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop(monitor.getItem().files[0]);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  
  return (
    <div>
      <FileBoxStyled ref={drop} isActive={isActive}>
        {isActive && (
          <div>
            <span>Release {fileType}</span>
          </div>
        )}
        {!isActive && (
          <div>
            <img src={require(`assets/icons/${fileType}add.svg`)} alt={`${fileType} add icon`} />{' '}
            <span>Drop {fileType} here</span>
          </div>
        )}
      </FileBoxStyled>
      {!isActive && fileType === FileType.photo && (
        <>
          <input id={"files" + fileType + id} accept="image/*" type="file" onChange={event => onDrop(event.target.files[0])} />
          <label htmlFor={"files" + fileType + id}  className="button-admin">
            Browse files
          </label>
        </>
      )}
      {fileType === FileType.zip && (
        <>
          <input id={"files" + fileType + id}  accept=".zip" type="file" onChange={event => onDrop(event.target.files[0])} />
          <label htmlFor={"files" + fileType + id}  className="button-admin">
            Browse for zip
          </label>
        </>
      )}
    </div>
  );
};

export default FileBox;

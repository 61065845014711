import React, { useState, useEffect } from 'react';
import { addMember, editMember } from 'services/member';
import { getMemberTypeEnum } from 'services/member';
import ImagePicker from 'components/common/ImagePicker';
import * as dayjs from 'dayjs';

import Flex from 'components/styled/Flex';
import Loading from 'components/styled/Loading';

const AddEditMember = ({ selectedMember, members, setMembers, setEditingId, isAdd }) => {
  let memberInitialValue = {
    firstName: '',
    lastName: '',
    dateOfBirth: dayjs()
      .set('year', 2004)
      .format('YYYY-MM-DD'),
    memberType: 'Active',
    description: '',
    joinDate: dayjs().format('YYYY-MM-DD'),
    exitDate: '',
    email: '',
    activeImagePath: {
      folder: 'Default',
      image: 'fallbackMember.png',
    },
    hoverImagePath: {
      folder: 'Default',
      image: 'fallbackMember.png',
    },
    alumniImagePath: {
      folder: 'Default',
      image: 'fallbackMember.png',
    },
    isDeceased: false,
  };

  if (selectedMember) {
    memberInitialValue = { ...selectedMember };
    memberInitialValue.dateOfBirth = memberInitialValue.dateOfBirth
      ? dayjs(new Date(memberInitialValue.dateOfBirth)).format('YYYY-MM-DD')
      : '';
    memberInitialValue.joinDate = dayjs(new Date(memberInitialValue.joinDate)).format('YYYY-MM-DD');
    memberInitialValue.exitDate = memberInitialValue.exitDate
      ? dayjs(new Date(memberInitialValue.exitDate)).format('YYYY-MM-DD')
      : '';
  }

  const [member, setMember] = useState(memberInitialValue);
  const [memberTypes, setMemberTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMemberTypeEnum().then(data => setMemberTypes(data));
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);

    if (!selectedMember)
      addMember(member)
        .then(() => {
          window.alert(`Member ${member.firstName} ${member.lastName} successfully added!`);
          setMembers();
        })
        .catch(() => window.alert('Failed to add member'))
        .finally(() => setMember(memberInitialValue));
    else
      editMember(member)
        .then(() => {
          window.alert(`Member ${member.firstName} ${member.lastName} successfully added!`);
          setMembers();
        })
        .catch(() => window.alert('Failed to edit member'));

    setLoading(false);
    if (setEditingId) setEditingId(-1);
  };

  const setActiveImagePath = activeImagePath => {
    setMember({ ...member, activeImagePath });
  };

  const setHoverImagePath = hoverImagePath => {
    setMember({ ...member, hoverImagePath });
  };

  const setAlumniImagePath = alumniImagePath => {
    setMember({ ...member, alumniImagePath });
  };

  const validate = () => {
    if (members.filter(memberFilter => memberFilter.email === member.email && memberFilter.id !== member.id).length > 0)
      window.alert('Member with the same email exists');
    else if (member.memberType === 'Active' && member.exitDate !== '')
      window.alert("Active member can't have exit date");
    else if (member.memberType !== 'Active' && member.exitDate === '')
      window.alert('Non active members must have exit date');
    else if (dayjs(member.exitDate).isBefore(member.joinDate)) window.alert("Join date can't come after exit date");
    else if (dayjs(member.exitDate).isAfter(dayjs())) window.alert("Can't set future exit dates");
    else if (dayjs(member.joinDate).isAfter(dayjs())) window.alert("Can't set future join dates");
    else if (member.dateOfBirth !== '' && dayjs(member.dateOfBirth).isAfter(member.joinDate))
      window.alert("Date of birth can't come after join date");
    else if (member.dateOfBirth !== '' && dayjs().diff(dayjs(member.dateOfBirth), 'year') < 16)
      window.alert("Member can't be below 16 years old");
    else return true;

    return false;
  };

  const handleChange = e => {
    const value = e.target.value;
    setMember({ ...member, [e.target.name]: value });
  };

  const setIsMemberDeceased = isDeceased => {
    console.log(isDeceased);
    setMember({ ...member, isDeceased: isDeceased });
  };

  return (
    <Flex direction="column">
      {!selectedMember && (
        <div className="form__add__header">
          <span>Add Member</span>
        </div>
      )}
      {loading && <Loading />}
      {!loading && (
        <form className="form__add" onSubmit={handleSubmit}>
          <label htmlFor="firstName">Ime: </label>
          <input
            className="input"
            type="text"
            name="firstName"
            onChange={handleChange}
            value={member.firstName}
            required
          />
          <label htmlFor="lastName">Prezime: </label>
          <input
            className="input"
            type="text"
            name="lastName"
            onChange={handleChange}
            value={member.lastName}
            required
          />
          <label htmlFor="dateOfBirth">Datum rođenja: </label>
          <input className="input" type="date" name="dateOfBirth" onChange={handleChange} value={member.dateOfBirth} />
          <label htmlFor="memberType">Tip člana: </label>
          <select className="input" name="memberType" onChange={handleChange} value={member.memberType} required>
            {Object.getOwnPropertyNames(memberTypes).map((memberType, index) => (
              <option value={`${memberType}`} key={index}>
                {memberType}
              </option>
            ))}
          </select>
          <label htmlFor="description">Opis: </label>
          <textarea className="input" name="description" onChange={handleChange} value={member.description} required />
          <label htmlFor="joinDate">Datum učlanjenja: </label>
          <input
            className="input"
            type="date"
            name="joinDate"
            onChange={handleChange}
            value={member.joinDate}
            required
          />
          <label htmlFor="exitDate">Datum izlaska: </label>
          <input className="input" type="date" name="exitDate" onChange={handleChange} value={member.exitDate} />
          <label htmlFor="email">Email: </label>
          <input className="input" type="email" name="email" onChange={handleChange} value={member.email} required />
          <label htmlFor="activeImage">Active image: </label>
          <ImagePicker
            className="image-picker-input"
            imagePathChangedCallback={setActiveImagePath}
            imagePath={member.activeImagePath}
            imagePathFallback={{
              folder: 'Default',
              image: 'fallbackMember.png',
            }}
            isAdd={isAdd}
          />
          <label htmlFor="activeImage">Hover image: </label>
          <ImagePicker
            className="image-picker-input"
            imagePathChangedCallback={setHoverImagePath}
            imagePath={member.hoverImagePath}
            imagePathFallback={{
              folder: 'Default',
              image: 'fallbackMember.png',
            }}
            isAdd={isAdd}
          />
          <label htmlFor="activeImage">Alumni image: </label>
          <ImagePicker
            className="image-picker-input"
            imagePathChangedCallback={setAlumniImagePath}
            imagePath={member.alumniImagePath}
            imagePathFallback={{
              folder: 'Default',
              image: 'fallbackMember.png',
            }}
            isAdd={isAdd}
          />
          <label htmlFor="isDeceased">Član preminuo?</label>
          <input
            className="input"
            type="checkbox"
            name="isDeceased"
            checked={member.isDeceased}
            onChange={() => {
              setIsMemberDeceased(!member.isDeceased);
            }}
          />
          <div className="form__add__buttons">
            <input type="submit" className="button submit-button" value="Submit"></input>
            {selectedMember && (
              <button className="button cancel-button" onClick={() => setEditingId(-1)}>
                Cancel
              </button>
            )}
          </div>
        </form>
      )}
    </Flex>
  );
};

export default AddEditMember;

import React, { useState, useEffect } from 'react';

import TextUnderline from 'components/styled/TextUnderline';
import { getActiveContactQuestionInfos, newContactQuestion } from 'services/admin';
import arrowDown from 'assets/icons/arrow-down.svg';

const Component = props => {
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(',false');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [isCheckboxActive, setIsCheckboxActive] = useState(false);
  const [areInputsDisabled, setAreInputsDisabled] = useState(false);

  useEffect(() => {
    getActiveContactQuestionInfos().then(response => {
      setDropdownOptions(response.data);
    });
  }, []);

  const setDropdownOption = valueAndNewsletter => {
    setSelectedDropdownOption(valueAndNewsletter);
    valueAndNewsletter = valueAndNewsletter.split(',');
    setIsCheckboxActive(valueAndNewsletter[1] === 'true');
  };

  const onSubmit = e => {
    e.preventDefault();

    setError('');
    if (selectedDropdownOption === ',false') {
      setError('Odaberite kategoriju');

      return;
    } else if (!email || !e.target.checkValidity()) {
      setError('Nevažeća email adresa!');

      return;
    } else if (!name) {
      setError('Ime je obavezno polje!');

      return;
    } else if (!message && !newsletter && isCheckboxActive) {
      setError('Unesite poruku ili se pretplatite na newsletter');

      return;
    } else if (!message && !newsletter) {
      setError('Unesite poruku');

      return;
    }

    setAreInputsDisabled(true);
    setConfirmation('Poruka se šalje...');

    let newContactQuestionInfoId =
      selectedDropdownOption.split(',')[0] === 0 ? null : selectedDropdownOption.split(',')[0];

    const contactQuestion = {
      senderMail: email,
      senderName: name,
      content: message,
      newsletterAgreed: newsletter,
      contactQuestionInfoId: newContactQuestionInfoId,
    };

    newContactQuestion(contactQuestion)
      .then(response => {
        setEmail('');
        setName('');
        setEmail('');
        setMessage('');
        setNewsletter(false);
        setSelectedDropdownOption(',false');
        setConfirmation('Poruka je uspješno poslana');
        setTimeout(() => setConfirmation(''), 2000);
        setAreInputsDisabled(false);
        setIsCheckboxActive(false);
      })
      .catch(() => {
        setConfirmation('');
        setError('Dogodila se greška :( Molimo pokušajte ponovo.');
        setTimeout(() => setError(''), 5000);
        setAreInputsDisabled(false);
      });
  };

  return (
    <>
      <h2 className="heading--normal">Imaš ideju ili pitanje?</h2>
      <h2 className="heading--normal">
        <TextUnderline color="#5cc2a0" underlineColor="transparent" lineHeight="0.85">
          Kontaktiraj nas
        </TextUnderline>
        !
      </h2>
      <form onSubmit={onSubmit} className="contact__form">
        {props.dropdown && (
          <div className="contact-form__select">
            <select
              id="question-topic"
              value={selectedDropdownOption}
              onChange={e => setDropdownOption(e.target.value)}
              aria-label="Tema pitanja"
            >
              <option value={',false'} disabled defaultValue>
                Što te zanima?
              </option>
              {dropdownOptions.map(dropdownOption => {
                return (
                  <option
                    key={dropdownOption.id}
                    value={`${dropdownOption.id},${dropdownOption.doesHaveNewsletterOption}`}
                  >
                    {dropdownOption.displayText}
                  </option>
                );
              })}
            </select>
            <img src={arrowDown} alt="arrow-down" />
          </div>
        )}
        <div className="contact-form__inputs">
          <input
            value={name}
            onChange={e => setName(e.target.value)}
            type="text"
            placeholder="Ime i prezime"
            disabled={areInputsDisabled}
            aria-label="Ime i prezime"
          />
          <input
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            disabled={areInputsDisabled}
            aria-label="Email"
          />
          <textarea
            maxLength="1000"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Napiši svoju poruku"
            disabled={areInputsDisabled}
            aria-label="Napiši svoju poruku"
          />
          <span className="heading--tiny contact-form__length">{message.length}/1000</span>
          <div className={isCheckboxActive ? 'contact-form__newsletter active' : 'contact-form__newsletter'}>
            <label className="newsletter__checkbox-container">
              <input
                className="checkbox__input"
                type="checkbox"
                value={newsletter}
                checked={newsletter}
                onChange={() => setNewsletter(!newsletter)}
              />
              <span className="checkbox__style"></span>
              {message.length > 0
                ? 'Želio bih primati e-mail obavijesti na ovu temu'
                : 'Pretplati me na newsletter bez slanja poruke'}
            </label>
          </div>
        </div>
        <input type="submit" value="Pošalji" disabled={areInputsDisabled} />
      </form>
      {error && <p className="heading--tiny text--error">{error}</p>}
      {confirmation && <p className="heading--tiny text--confirmation">{confirmation}</p>}
    </>
  );
};

export default Component;

import React, { useState, useContext } from 'react';
import TextUnderline from 'components/styled/TextUnderline';
import Flex from 'components/styled/Flex';
import Icon from 'components/common/Icon';
import { mediaLessThan, mediaHeightLessThan } from 'utils/media';
import { useScrollPosition } from 'utils/scroll';
import { MenuStateContext } from 'services/providers/MenuProvider';

import iconEducation from 'assets/icons/icon_education.svg';
import iconProjects from 'assets/icons/icon_projects.svg';
import illustrationIcons from 'assets/illustrations/illustration_landing-icons.svg';
import illustrationCharacters from 'assets/illustrations/illustration_landing-characters.svg';
import shapeBackground from 'assets/shapes/shape_green-illustration.svg';
import styled from 'styled-components';

const Parallax = styled.object.attrs(({ yScroll, yOffset, coeficient }) => ({
  style: {
    top: `${75 + coeficient * yScroll + yOffset}px`,
  },
}))`
  position: absolute;
  width: calc(90% - 5px);
`;

const mediaWidthBreak = {
  small: 768,
  medium: 900,
  large: 1440,
};

const mediaHeightBreak = 800;

const Header = () => {
  const {
    state: { isNextEventDisplayed },
  } = useContext(MenuStateContext);

  const [yScroll, setYScroll] = useState(0);
  useScrollPosition(effect => setYScroll(effect.currPos.y));

  const getYNextEventOffset = () => {
    if (!isNextEventDisplayed) {
      return 0;
    }

    if (mediaLessThan(mediaWidthBreak.medium) && mediaHeightLessThan(mediaHeightBreak)) {
      return 32;
    }

    if (mediaLessThan(mediaWidthBreak.large)) {
      return 120;
    }

    return 200;
  };
  const getYResponsiveOffset = () => {
    if (mediaLessThan(mediaWidthBreak.medium) && mediaHeightLessThan(mediaHeightBreak)) {
      return 20;
    }

    return 0;
  };

  const yOffset = getYNextEventOffset() + getYResponsiveOffset();
  const coeficient = mediaLessThan(mediaWidthBreak.medium) ? 0.1 : 0.2;
  const isMobile = mediaLessThan(mediaWidthBreak.small);

  return (
    <div className="landing__header">
      <Flex direction="column" className="landing__header__content">
        <h1 className="heading--big landing__heading">
          Putokaz u svijet&nbsp;
          <TextUnderline color="#282d30" underlineColor="#5cc2a0cc">
            digitalnih
          </TextUnderline>
          <TextUnderline color="#282d30" underlineColor="#5cc2a0cc">
            tehnologija
          </TextUnderline>
          .
        </h1>
        <Flex alignItems="start" justifyContent="start" margin="28px 0 0 0">
          <span className="ongoings__line" />
          <p className="heading--tiny">Čime se bavimo</p>
        </Flex>
        <div className="ongoings__container">
          <Flex direction={mediaLessThan(530) ? 'column' : 'row'}>
            <Icon color="#5b6cc233" image={iconProjects} alt="projects" />
            <div className="ongoing__card">
              <b>Cool projekti</b>
              <p className="text__description">
                Radom na cool projektima povezujemo lokalnu zajednicu i brusimo svoje talente.
              </p>
            </div>
          </Flex>
          <Flex direction={mediaLessThan(530) ? 'column' : 'row'}>
            <Icon color="#cd785133" image={iconEducation} alt="education" />
            <div className="ongoing__card ongoing__card--last">
              <b>Edukacija zajednice</b>
              <p className="text__description">
                Želimo podijeliti znanje i entuzijazam koji imamo na što širi krug ljudi.
              </p>
            </div>
          </Flex>
        </div>
      </Flex>
      <div className="landing__illustration__container">
        <div className="landing__illustration">
          <img className="landing__illustration__icons" src={illustrationIcons} alt="illustration icons" />
          <Parallax
            className="landing__illustration__characters"
            data={illustrationCharacters}
            aria-label="illustration characters"
            yScroll={yScroll}
            yOffset={yOffset}
            coeficient={coeficient}
            isMobile={isMobile}
          />
          <img
            className="landing__illustration__background"
            src={shapeBackground}
            alt="Green shape illustration background"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import Flex, { FlexFooter } from 'components/styled/Flex';
import dump from 'assets/icons/dump.svg';
import footerShape from 'assets/shapes/footerShape.png';

const Footer = () => {
  return (
    <FlexFooter direction="column" justifyContent="space-between" margin="120px 0 0" position="relative">
      <Flex className="footer__container">
        <Link rel="noopener" to="/">
          <img className="footer__dump" src={dump} alt="Dump logo" />
        </Link>
        <Flex direction="column" className="footer__column--info">
          <span className="email">email</span>
          <a href="mailto:info@dump.hr" className="email-text">
            info@dump.hr
          </a>
          <span className="footer__adress">adresa</span>
          <span className="footer__adress-text">Ul. Ruđera Boškovića 32, FESB, Split</span>
        </Flex>
        <Flex direction="column" className="footer__column--links">
          <span className="footer__explore">istraži</span>
          <Link rel="noopener" to="/events" className="footer__links">
            Događaji
          </Link>
          <Link rel="noopener" to="/projects" className="footer__links">
            Projekti
          </Link>
          <Link rel="noopener" to="/about" className="footer__links">
            O nama
          </Link>
          <Link rel="noopener" to="/internship" className="footer__links">
            Postani član
          </Link>
        </Flex>
      </Flex>
      <span className="footer__copyright">© 2020 DUMP Udruga mladih programera. Sva prava pridržana.</span>
      <img className="footer__image" src={footerShape} alt="" />
    </FlexFooter>
  );
};

export default Footer;

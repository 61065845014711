import React, { useState, useEffect } from 'react';
import './styles.css';
import CounterWithContactForm from 'components/common/CountersWithContactForm';
import Header from './Header';
import PopularEvents from './PopularEvents';
import AllEvents from './AllEvents';
import { getAllEvents } from 'services/event';

const Events = () => {
  const [allEvents, setAllEvents] = useState(null);

  useEffect(() => {
    getAllEvents().then(response => setAllEvents(response.data));
  }, []);

  return (
    <main>
      <Header />
      <h2 className="events__popular--title events__popular--title--main">Naši najpoznatiji događaji</h2>
      <PopularEvents allEvents={allEvents} />
      <AllEvents allEvents={allEvents} />
      <CounterWithContactForm />
    </main>
  );
};

export default Events;

import React, { useCallback } from 'react';
import '../styles.css';
import { useDrop } from 'react-dnd';

import EventSection from 'components/common/EventSection';
import EventSectionEditor from './EventSectionEditor';
import EventSectionDraggable from './EventSectionDraggable';
import { SectionType, DraggableTypes } from 'constants/sectionType';

const EventSectionsBuildingContainer = ({
  eventSections,
  setEventSections,
  members,
  editingIndex,
  setEditingIndex,
  isSorting,
}) => {
  const [{ isOver, canDrop }, setDropItemContainer] = useDrop({
    accept: [...Object.keys(SectionType), DraggableTypes.section],
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const findEventSection = useCallback(
    order => {
      const section = eventSections.filter(item => `${item.sectionOrder}` === order)[0];
      return {
        section,
        index: eventSections.indexOf(section),
      };
    },
    [eventSections],
  );

  const moveSection = useCallback(
    (atIndex, itemIndexOriginal) => {
      if (atIndex === itemIndexOriginal) return;
      const eventSectionsNew = eventSections;
      const { section, index } = findEventSection(itemIndexOriginal);
      eventSectionsNew.splice(index, 1);
      eventSectionsNew.splice(atIndex, 0, section);
      setEventSections([...eventSectionsNew]);
    },
    [eventSections, findEventSection, setEventSections],
  );

  const deleteEventSection = index => {
    if (!window.confirm(`Are you sure you want to delete ${eventSections[index].title}`)) return;
    const eventSectionsNew = eventSections;
    eventSectionsNew.splice(index, 1);
    setEventSections([...eventSectionsNew]);
  };

  if (isSorting)
    return (
      <div ref={setDropItemContainer}>
        {eventSections.map((eventSection, index) => (
          <EventSectionDraggable
            key={index}
            index={`${eventSection.sectionOrder}`}
            eventSection={eventSection}
            moveSection={moveSection}
            findEventSection={findEventSection}
            isReordering={isOver && canDrop}
          />
        ))}
      </div>
    );

  return (
    <div>
      {editingIndex !== undefined && (
        <EventSectionEditor
          eventSection={eventSections[editingIndex]}
          eventSections={eventSections}
          setEventSections={setEventSections}
          setEditingIndex={setEditingIndex}
          findEventSection={findEventSection}
          members={members}
        />
      )}
      {editingIndex === undefined &&
        eventSections.map((eventSection, index) => (
          <div className="event-section__container" key={index}>
            <button className="button-admin cancel-button" onClick={() => deleteEventSection(index)}>
              Delete
            </button>
            <button className="button-admin submit-button" onClick={() => setEditingIndex(index)}>
              Edit
            </button>
            <EventSection eventSection={eventSection} />
          </div>
        ))}
    </div>
  );
};

export default EventSectionsBuildingContainer;

import React from 'react';

import MembersImageDesktop from 'assets/visuals/internship/reasons-members.png';
import MembersImageMobile from 'assets/visuals/internship/reasons-members-mobile.png';
import PersonalDevelopmentIcon from 'assets/icons/personal-development.svg';
import ExperienceIcon from 'assets/icons/experience.svg';
import AcquaintancesIcon from 'assets/icons/acquaintances.svg';

import {
  StyledContainer,
  StyledTitle,
  StyledSubtitle,
  StyledTitleWrapper,
  StyledContentWrapper,
  StyledImageDesktop,
  StyledImageMobile,
  StyledReasonsContainer,
} from './index.styled';
import { StyledSpacing } from '../index.styled';

import ReasonsItem from './ReasonsItem';

const ReasonsToBecomeAMember = () => {
  const PersonalDevelopmentTitle = 'Osobni razvoj';
  const PersonalDevelopmentText =
    'Rad u Udruzi ti omogućava da proširiš svoje vidike, radiš na svojim aspiracijama i pripremiš se za profesionalni svijet.';
  const ExperienceTitle = 'Iskustvo';
  const ExperienceText =
    'Kroz stvarne projekte utvrđuješ znanje te dobivaš iskustvo koje će ti pomoći prevladati buduće izazove u profesionalnom svijetu.';
  const AcquaintancesTitle = 'Poznanstva';
  const AcquaintancesText =
    'Članstvo u Udruzi ti otvara vrata u svijet brojnih lokalnih IT tvrtki i povezuje te sa stručnjacima iz struke.';

  return (
    <StyledSpacing>
      <StyledContainer>
        <StyledTitleWrapper>
          <StyledTitle>Zašto postati dumpovac?</StyledTitle>
          <StyledSubtitle>
            Biti dumpovac znači biti dio uspješne zajednice koja se međusobno podržava i potiče na daljnje učenje. Imaš
            priliku raditi na stvarnim projektima, družiti se, podučavati druge i na kraju krajeva, kroz sve to graditi
            sebe i Udrugu.
          </StyledSubtitle>
        </StyledTitleWrapper>
        <StyledContentWrapper>
          <StyledImageDesktop src={MembersImageDesktop} />
          <StyledImageMobile src={MembersImageMobile} />
          <StyledReasonsContainer>
            <ReasonsItem
              icon={PersonalDevelopmentIcon}
              title={PersonalDevelopmentTitle}
              text={PersonalDevelopmentText}
            />
            <ReasonsItem icon={ExperienceIcon} title={ExperienceTitle} text={ExperienceText} />
            <ReasonsItem icon={AcquaintancesIcon} title={AcquaintancesTitle} text={AcquaintancesText} />
          </StyledReasonsContainer>
        </StyledContentWrapper>
      </StyledContainer>
    </StyledSpacing>
  );
};

export default ReasonsToBecomeAMember;

import React from 'react';
import './styles.css';

import ContactForm from 'screens/ContactForm';
import SocialMediaCounter from '../SocialMediaCounter';

const CounterWithContactForm = () => {
  return (
    <div className="counter__contact-form">
      <div className="counter__contact-form--form">
        <ContactForm />
      </div>
      <div className="counter__contact-form--counter">
        <SocialMediaCounter />
      </div>
    </div>
  );
};

export default CounterWithContactForm;

import React, { useState } from 'react';
import { deleteMember } from '../../../services/member';
import './styles.css';
import Loading from 'components/styled/Loading';

const PreviewMember = ({ member, setEditingId, setMembers }) => {
  const [loading] = useState(false);

  const handleDeleteMember = () => {
    if (window.confirm(`Are you sure you want to delete ${member.firstName} ${member.lastName}?`))
      deleteMember(member.id)
        .catch(error =>
          alert("Failed to delete a member either because he's already deleted or he's featured on a Post or a Event"),
        )
        .finally(() => setMembers());
  };

  return (
    <div className="list__member list__preview">
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="member__content preview__content">
            <span className="member__info">
              {member.firstName} {member.lastName} - {member.email} - {member.memberType}
            </span>
          </div>
          <div className="user__preview__controls">
            <button className="button edit-button" onClick={() => setEditingId(member.id)}>
              Edit
            </button>
            <button className="button deactivate-button" onClick={() => handleDeleteMember()}>
              Delete
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PreviewMember;

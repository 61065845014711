import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { S3_BUCKET_BASE_URL as base } from 'services/common';
import { getActiveEvent } from 'services/event';
import { MenuStateContext } from 'services/providers/MenuProvider';
import { normalizeForUrl } from 'utils/normalizeForUrl';

const Image = styled.img`
  transition: max-height 0.6s ease-in-out;
  will-change: max-height;

  ${({ isMenuExpanded }) => isMenuExpanded && 'max-height: 0 !important; display: block;'}
`;

const NextEvent = () => {
  const [event, setEvent] = useState(null);
  const {
    state: { isExpanded },
    setIsNextEventDisplayed,
  } = useContext(MenuStateContext);

  const currentPathname = window.location.pathname;
  const shouldDisplayNextEventBanner = currentPathname === '/events' || currentPathname === '/';

  useEffect(() => {
    if (shouldDisplayNextEventBanner) {
      getActiveEvent().then(response => {
        setEvent(response.data);
      });
    }

    setIsNextEventDisplayed(shouldDisplayNextEventBanner);
  }, [setIsNextEventDisplayed, shouldDisplayNextEventBanner]);

  if (!shouldDisplayNextEventBanner || !event) {
    return null;
  }

  return (
    <Link
      rel="noopener"
      to={{
        pathname: `/events/${event.id}-${normalizeForUrl(event.name)}`,
        state: { event },
      }}
    >
      <Image
        className="events__banner"
        src={`${base}/images/${event.coverImagePath.folder}/${event.coverImagePath.image}`}
        alt="Cover"
        isMenuExpanded={isExpanded}
      />
    </Link>
  );
};

export default NextEvent;

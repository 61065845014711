import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.section`
  position: relative;
`;

export const StyledTextContainer = styled.div`
  padding-top: 88px;
  padding-left: 112px;
  padding-bottom: 196px;
  margin-right: 216px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 216px;
    bottom: 0;
    background-color: #f7f9fb;
    border-radius: 0px 40px 40px 0px;
    z-index: -1;
  }

  @media screen and (max-width: 767px) {
    padding-top: 64px;
    padding-left: 24px;
    padding-bottom: 184px;
    margin-right: 64px;

    &::after {
      right: 64px;
    }
  }
`;

export const StyledTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  color: #282d30;
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 56px;
  line-height: 60px;
`;

export const StyledEventsCamera = styled.div`
  position: absolute;
  bottom: -80px;
  width: 100%;
  height: 248px;
  padding: 10px 0;
  z-index: 1;
  max-width: 1920px;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 767px) {
    height: 196px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 10px;
    z-index: 1;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
`;

export const StyledEventLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 32px;

    @media screen and (max-width: 767px) {
      margin-right: 24px;
    }
  }
`;

export const StyledEventPoster = styled.img`
  border-radius: 32px;
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 767px) {
    width: 288px;
    height: 176px;
  }
`;

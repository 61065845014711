import React, { useCallback } from 'react';
import '../styles.css';
import { useDrop } from 'react-dnd';

import ProjectSection from './ProjectSection';
import { SectionType, DraggableTypes } from 'constants/sectionType';

const activeColor = '#5CC2A0';
const notActive = '#000';

const ProjectSectionsBuildingContainer = ({ projectSections, setProjectSections, addedIndex, setAddedIndex }) => {
  const [{ isOver, canDrop, isType }, setDropItemContainer] = useDrop({
    accept: [...Object.keys(SectionType), DraggableTypes.section],
    collect: monitor => ({
      isType: (function() {
        const item = monitor.getItem();
        if (!item) return false;
        const { type } = item;
        return [...Object.keys(SectionType)].includes(type);
      })(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const findSection = useCallback(
    order => {
      const section = projectSections.filter(item => `${item.sectionOrder}` === order)[0];
      return {
        section,
        index: projectSections.indexOf(section),
      };
    },
    [projectSections],
  );

  const moveSection = useCallback(
    (atIndex, itemIndexOriginal) => {
      if (atIndex === itemIndexOriginal) return;
      const projectSectionsNew = projectSections;
      const { section, index } = findSection(itemIndexOriginal);
      projectSectionsNew.splice(index, 1);
      projectSectionsNew.splice(atIndex, 0, section);
      setProjectSections([...projectSectionsNew]);
    },
    [projectSections, findSection, setProjectSections],
  );

  const isActive = canDrop && isOver && isType;
  const isSorting = canDrop && isOver && !isType;

  return (
    <div
      className="project-sections-builder"
      ref={setDropItemContainer}
      style={{
        border: `1px solid ${isActive ? activeColor : notActive}`,
        opacity: isActive && 0.2,
        backgroundColor: isActive && activeColor,
      }}
    >
      {projectSections.map((projectSection, index) => (
        <ProjectSection
          key={index}
          index={`${projectSection.sectionOrder}`}
          item={projectSection}
          moveSection={moveSection}
          findSection={findSection}
          isSorting={isSorting}
          setProjectSections={setProjectSections}
          projectSections={projectSections}
          isNew={projectSection.sectionOrder === addedIndex}
          setAddedIndex={setAddedIndex}
        />
      ))}
    </div>
  );
};

export default ProjectSectionsBuildingContainer;

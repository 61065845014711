import React from 'react';
import Leaf from './Leaf';
import Element from './Element';

const TextElement = ({ text }) => {
  if (Array.isArray(text))
    return text.map((element, elementIndex) => <TextElement key={elementIndex} text={element} />);

  return (
    <>
      {text.hasOwnProperty('children') && (
        <>
          {
            <Element element={text}>
              <TextElement text={text.children} />
            </Element>
          }
        </>
      )}
      {!text.hasOwnProperty('children') && <>{<Leaf leaf={text}>{text.text}</Leaf>}</>}
    </>
  );
};

export default TextElement;

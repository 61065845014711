import styled from 'styled-components';

export const StyledContainer = styled.section`
  margin: 0 112px;

  @media (max-width: 1024px) {
    margin-left: 60px;
  }

  @media (max-width: 900px) {
    margin-left: 42px;
  }

  @media (max-width: 500px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledTitleWrapper = styled.div`
  max-width: 695px;
  margin-left: 104px;

  @media (max-width: 767px) {
    margin-left: 40px;
  }

  @media (max-width: 500px) {
    margin-left: 0px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-size: 112px;
  font-weight: 800;
  line-height: 112px;
  color: #111111;
  margin-top: 232px;
  margin-bottom: 24px;

  @media (max-width: 1024px) {
    font-size: 80px;
    line-height: 86px;
  }

  @media (max-width: 767px) {
    font-size: 56px;
    line-height: 60px;
    max-width: 500px;
    margin-top: 150px;
  }

  @media (max-width: 360px) {
    font-size: 52px;
  }
`;

export const StyledSubtitle = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  max-width: 488px;
  color: #282d30;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  margin-top: 78px;

  @media (max-width: 1150px) {
    align-items: flex-end;
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const StyledImageDesktop = styled.img`
  display: block;
  height: 100%;

  @media (max-width: 900px) {
    width: 380px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledImageMobile = styled.img`
  display: none;
  width: 80%;

  @media (max-width: 767px) {
    display: block;
    margin-top: 92px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const StyledReasonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 173px;

  @media (max-width: 1280px) {
    margin-left: 79px;
  }

  @media (max-width: 1024px) {
    margin-left: 40px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    justify-content: center;
    max-width: 360px;
  }

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const StyledReasonsItem = styled.div`
  display: flex;
  margin-bottom: 80px;
`;

export const StyledReasonsIcon = styled.img`
  align-self: start;
`;

export const StyledReasonsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  max-width: 418px;
`;

export const StyledReasonsItemTitle = styled.h3`
  font-family: Fuse V.2 Display;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  margin: 0;
`;

export const StyledReasonsItemText = styled.p`
  font-family: Fuse V.2 Display;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 0;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

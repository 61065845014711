import React, { useState, useEffect } from 'react';

import { internshipEventPush } from 'services/gtm';

import Header from './Header';
import WhatIsInternship from './WhatIsInternship';
import Perks from './Perks';
import DisciplineCards from './DisciplineCards';
import ReasonsToBecomeAMember from './ReasonsToBecomeAMember';
import WhatDoWeDo from './WhatDoWeDo';
import ForWhoIsIt from './ForWhoIsIt';
import Supporters from './Supporters';
import AfterSignup from './AfterSignup';
import SignUpCta from './SignUpCta';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';

import { setConfiguration } from 'services/configuration';
import { disciplineCardsConfig } from './DisciplineCards/const';

const internshipSignUpCtaAnchor = 'sign-up-cta';

const InternshipActive = () => {
  const [internshipUrl, setInternshipUrl] = useState(null);
  const [preactivatedDiscipline, setPreactivatedDiscipline] = useState(null);

  useEffect(() => {
    setConfiguration('InternshipUrl', setInternshipUrl);
    internshipEventPush();

    const hash = window.location.hash.substring(1);
    if (Object.keys(disciplineCardsConfig).includes(hash)) {
      setPreactivatedDiscipline(hash)
    }
  }, []);

  return (
    <>
      <Header internshipUrl={internshipUrl} formId={internshipSignUpCtaAnchor} />
      <WhatIsInternship />
      <Perks />
      <DisciplineCards internshipUrl={internshipUrl} preactivatedDiscipline={preactivatedDiscipline} />
      <ReasonsToBecomeAMember />
      <WhatDoWeDo />
      <ForWhoIsIt />
      <Supporters />
      <AfterSignup />
      <SignUpCta internshipUrl={internshipUrl} />
      <FrequentlyAskedQuestions />
    </>
  );
};

export default InternshipActive;

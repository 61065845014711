import styled from 'styled-components';

export const FieldLabel = styled.label`
  font-family: 'Fuse V.2 Display';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 12px;
  margin-right: 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
  ${({ color }) => `color: ${color};`}
`;

export const ProgressBarDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  left: -4px;
  transition: background 500ms linear 1s;

  ${({ top }) => `top: ${top}px`}
  ${({ color }) => `background-color: ${color}`}
`;

import styled from 'styled-components';

import { getColor } from '../index.styled';

export const StyledWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 112px;
  margin-right: 112px;
  margin-bottom: 256px;
  border-radius: 24px;
  padding-right: 104px;

  background-color: ${getColor};

  @media screen and (max-width: 1440px) {
    margin-left: 64px;
    margin-right: 64px;
  }

  @media screen and (max-width: 1250px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 767px) {
    border-radius: unset;
    margin-left: unset;
    margin-right: unset;
    flex-direction: column-reverse;
    padding-right: unset;
    padding-top: 40px;
  }
`;

export const StyledImageDesktop = styled.img`
  display: block;
  margin-top: auto;

  @media screen and (max-width: 1000px) {
    width: 50%;
    height: auto;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledImageMobile = styled.img`
  display: none;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const StyledTextWrapper = styled.div`
  max-width: 592px;
  margin-top: 56px;
  margin-bottom: 52px;

  @media (max-width: 767px) {
    margin-bottom: unset;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const StyledTitle = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 56px;
  line-height: 60px;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const StyledDescription = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-top: 0;
  margin-bottom: 48px;
`;

export const StyledButton = styled.button`
  border: unset;
  padding: 18px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  color: white;
  cursor: pointer;

  background-color: ${getColor};

  @media (max-width: 767px) {
    justify-content: center;
    width: 100%;
  }
`;

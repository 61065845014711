import styled from 'styled-components';

const flexToTextOptions = option => {
  switch (option) {
    case 'flex-start':
      return 'start';
    case 'flex-end':
      return 'end';
    case 'center':
      return 'center';
    default:
      return option;
  }
};

const ProjectStyled = styled.article`
  position: relative;
  width: 90%;
  margin-bottom: 30px;

  ${({ marginTop, isFirst }) => marginTop && isFirst && `margin-top: ${-1 * marginTop}vw;`}
  ${({ isFirst }) => !isFirst && 'margin-top: 30px;'}
  ${({ isPreview }) => isPreview && `border: 1px dashed #000;`}

  & .project__styled__text {
    position: absolute;
    display: flex;
    flex-direction: column;
    user-select: none;

    ${({ ctaTextAlignment }) => ctaTextAlignment && `align-items: ${ctaTextAlignment};`}
    ${({ top, offsetTop }) => `top: calc(${top}% - ${offsetTop}px);`}
    ${({ left, offsetLeft }) => `left: calc(${left}% - ${offsetLeft}px);`}


    & > h2 {
      font-size: 52px;
      max-width: 900px;
      margin: 0;

      @media screen and (max-width: 1400px) {
      max-width: 700px;
      font-size: 48px;
      }

      @media screen and (max-width: 1200px) {
        font-size: 48px;
      }

      @media screen and (max-width: 1100px) {
        font-size: 38px;
      }

      @media screen and (max-width: 900px) {
        max-width: 460px;
        font-size: 32px;
      }

      @media screen and (max-width: 800px) {
        max-width: 430px;
        font-size: 28px;
      }

      @media screen and (max-width: 700px) {
        max-width: 360px;
        font-size: 26px;
      }

      @media screen and (max-width: 500px) {
        max-width: 300px;
        font-size: 22px;
      }

      @media screen and (max-width: 450px) {
        max-width: 250px;
        font-size: 20px;
      }

      @media screen and (max-width: 400px) {
        max-width: 200px;
        font-size: 16px;
      }
    }

    & > h2:last-of-type {
      margin-bottom: 35px;

      @media screen and (max-width: 1100px) {
        margin-bottom: 20px;
      }
    }

    & > p {
      max-width: 400px;
      margin: 8px 0;
      ${({ ctaTextAlignment }) => ctaTextAlignment && `text-align: ${flexToTextOptions(ctaTextAlignment)};`}
      
      @media screen and (max-width: 900px) {
          font-size: 14px;
      }

      @media screen and (max-width: 800px) {
          display: none;
      }
    }

    & > .project__styled__skills {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: ${({ ctaTextAlignment }) => (ctaTextAlignment ? `${ctaTextAlignment}` : 'flex-start')};
      margin-bottom: 18px;

      & > .project__styled__skill:first-child {
        margin-left: 0;
      }

      & > .project__styled__skill {
        display: flex;
        color: #fff;
        align-items: center;
        align-self: center;
        text-transform: uppercase;
        border-radius: 12px;
        margin-left: 7px;
        margin-bottom: 7px;
        padding: 2px 12px;
        font-size: 16px;

        ${({ projectSkillsBackgroundColor }) =>
          projectSkillsBackgroundColor && `background-color:${projectSkillsBackgroundColor};`}
      }

      @media screen and (max-width: 800px) {
        & > .project__styled__skill {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 700px) {
        & {
          margin-bottom: 10px;
        }
      }

      @media screen and (max-width: 600px) {
        & > .project__styled__skill {
          font-size: 13px;
        }
      }

      @media screen and (max-width: 400px) {
        & > .project__styled__skill {
          font-size: 8px;
        }
        & {
          margin-top: 15px;
        }
      }

      @media screen and (max-width: 350px) {
        & > .project__styled__skill {
          font-size: 7px;
        }
      }

      @media screen and (max-width: 325px) {
        & {
          margin-bottom: 7px;
        }
      }
    }
  }

  & .project__styled__img {
    ${({ width }) => `width: ${width}%;`}
    ${({ marginTop }) => `margin-top: ${marginTop}%;`}
    ${({ marginRight }) => `margin-right: ${marginRight}%;`}
    ${({ marginBottom }) => `margin-bottom: ${marginBottom}%;`}
    ${({ marginLeft }) => `margin-left: ${marginLeft}%;`}
    user-select: none;
    border-radius: 10px;
  }

  & > .button-container {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
  }

  & > .button-container .button-admin {
    margin-left: 5px;
  }

  &:hover > .button-container {
    display: initial;
  }
`;

export default ProjectStyled;

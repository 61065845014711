import React from 'react';

import { ReactComponent as XIcon } from 'assets/icons/x-white.svg';
import DesHeaderImage from 'assets/visuals/internship/des-header.png';
import DesFirstIcon from 'assets/icons/internship/modals/des-icon-1.svg';
import DesSecondIcon from 'assets/icons/internship/modals/des-icon-2.svg';
import DesThirdIcon from 'assets/icons/internship/modals/des-icon-3.svg';
import DesFirstImage from 'assets/visuals/internship/des-1.png';
import DesSecondImage from 'assets/visuals/internship/des-2.png';
import DesThirdImage from 'assets/visuals/internship/des-3.png';
import DesHowItLooksImage from 'assets/visuals/internship/des-how-it-looks.png';
import DesExpectationsImageDesktop from 'assets/visuals/internship/des-expectations.png';
import DesExpectationsImageMobile from 'assets/visuals/internship/des-expectations-mobile.png';

import Header from './Header';
import TextRightSection from './TextRightSection';
import TextLeftSection from './TextLeftSection';
import HowItLooksSection from './HowItLooksSection';
import WhatsComingSection from './WhatsComingSection';
import SuggestedDisciplines from './SuggestedDisciplines';

import { Discipline } from '../consts';
import { Color, StyledWrapper, StyledContainer, StyledCloseButton, StyledTextSectionsWrapper } from './index.styled';

const DesignModal = ({ internshipUrl, isOpen, close, setActiveDisciplineModal }) => {
  return (
    <StyledWrapper isOpen={isOpen} color={Color.red}>
      {isOpen && (
        <StyledContainer>
          <StyledCloseButton onClick={close}>
            <XIcon />
          </StyledCloseButton>
        </StyledContainer>
      )}
      <Header
        internshipUrl={internshipUrl}
        backgroundColor={Color.lightRed}
        seeThroughColor={Color.seeThroughRed}
        title="Dizajn"
        description="Kroz Dizajn Internship, naučit ćeš kako stvarati vizualni sadržaj koji prenosi snažne poruke svim vrstama digitalnog dizajna."
        perks={[
          {
            iconSrc: DesFirstIcon,
            label: 'Branding',
          },
          {
            iconSrc: DesSecondIcon,
            label: 'Grafički dizajn',
          },
          {
            iconSrc: DesThirdIcon,
            label: 'UI / UX',
          },
        ]}
        buttonLabel="Prijavi se na Dizajn"
        buttonColor={Color.dark}
        imageSrc={DesHeaderImage}
      />
      <StyledTextSectionsWrapper>
        <TextRightSection
          imageSrc={DesFirstImage}
          title="Branding"
          description="Prvo ćemo te upoznati s nekima od najpoznatijih dizajnerskih pojmova i razlikama između njih, kako bismo istakli važnost njihovih uloga u primjeni. Fokus ove cjeline je predstaviti dizajn kao disciplinu koja se temelji na funkcionalnosti i rješavanju problema, a ne samo na izradi nečega što je “lijepo”."
          tags={['Brand', 'Vizualni identitet', 'Logo', 'Tipografija', 'Knjiga standarda', 'Illustrator']}
        />
        <TextLeftSection
          title="Grafički dizajn"
          description="Poznavanje pravila kompozicije, hijerarhije i odnosa među elementima ključni su za izradu svih dizajnerskih proizvoda. Upravo će ta tematika biti temelj druge cjeline kroz koju ćemo obrađivati i proizvode koje najčešće izrađujemo u svrhu promocije rada Udruge."
          tags={['Grid', 'Kompozicija', 'Layout', 'Plakat', 'Objave i oglasi']}
          imageSrc={DesSecondImage}
        />
        <TextRightSection
          imageSrc={DesThirdImage}
          title="UI / UX"
          description="Zadnja cjelina koju obrađujemo je User Interface (UI) i User Experience (UX). Proći ćeš kroz proces izrade web stranica i aplikacija koji uključuje različite faze UX-a kako bi što bolje razumio krajnjeg korisnika, te primjenu vizualnih elemenata kako bi konačno iskustvo korištenja bilo što ugodnije.
          Surađivati ćeš s programerima jer oni su ti koji će tvoju viziju na kraju i razvijati."
          tags={[
            'Korisničko iskustvo',
            'Sučelja',
            'Web dizajn',
            'User flows',
            'Wireframe',
            'Figma',
            'Dizajn mobilnih aplikacija',
          ]}
        />
      </StyledTextSectionsWrapper>
      <HowItLooksSection
        title="Kako će sve to izgledat?"
        imageSrc={DesHowItLooksImage}
        features={[
          {
            title: 'Predavanja',
            description:
              'Predavanjima ćemo obuhvatit ključno teorijsko gradivo koje će ti omogućiti shvaćanje dizajnerskih proizvoda i procesa njihove izrade.  Stečeno znanje aplicirat ćeš na brojnim praktičnim zadacima, njima ćeš steći iskustvo izrade i pravilnog pristupa dizajnerskim zadacima. Postepeno ćeš razviti niz radova koji će ti moći služiti i kao osobni portfolio. Predavanja će se odvijati najčešće subotom.',
          },
          {
            title: 'Radionice',
            description:
              'Praktični dio našeg druženja su tehničke radionice na kojima ćemo se baviti upoznavanjem i vježbanjem digitalnih alata koje najčešće koristimo pri radu. Takvi alati podrazumijevaju Adobe Illustrator, Adobe Photoshop i Figmu za dizajn sučelja. Započet ćemo sa samim osnovama, a redovitim zadacima i domaćim radovima ćemo utvrđivati naučeno.',
          },
          {
            title: 'Podrška i mentorstvo',
            description:
              'Kroz čitav DUMP Internship uložit ćemo što veću količinu vremena kako bi vam osigurali pomoć na svakom koraku. Ideja je da zajedno učimo i napredujemo, ti od nas, ali i mi od tebe. Zajedničkim komentiranjem i savjetovanjem, nastojat ćemo iz svakog rada izvući maksimalnu kvalitetu, jer ipak je to i standardan dio svakog dizajnerskog procesa.',
          },
        ]}
      />
      <WhatsComingSection
        internshipUrl={internshipUrl}
        color={Color.lightRed}
        imageSrcDesktop={DesExpectationsImageDesktop}
        imageSrcMobile={DesExpectationsImageMobile}
        title="Što te očekuje?"
        listItems={[
          'Cjelovit i detaljan uvid u sve aspekte suvremenog dizajna',
          'Iskustvo rada na stvarnim projektima uz primjenu profesionalnih metoda rada',
          'Izrada vlastitog portfolija uz stalnu podršku i međusobno savjetovanje',
          'Vanjska predavanja profesionalnih dizajnera',
        ]}
        tickColor={Color.dark}
        buttonLabel="Prijavi se na Dizajn"
        buttonColor={Color.dark}
      />
      <SuggestedDisciplines
        activeDisciplineKey={Discipline.design}
        setActiveDisciplineModal={setActiveDisciplineModal}
      />
    </StyledWrapper>
  );
};

export default DesignModal;

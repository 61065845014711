import { useRef } from 'react';

const useFallbackImage = fallbackImageSrc => {
  const imageExtensionFallbackRef = useRef('.webp');

  // Matches the file extension at the end of string or if no extension is present
  const FILE_EXT_REGEX = /(\.\w+)?$/;

  const setFallbackImageSource = event => {
    const currentSrc = event.target.src;
    const currentExtension = imageExtensionFallbackRef.current;

    if (currentExtension === '.webp') {
      event.target.src = currentSrc.replace(FILE_EXT_REGEX, '.webp');
      imageExtensionFallbackRef.current = '.png';
    } else if (currentExtension === '.png') {
      event.target.src = currentSrc.replace(FILE_EXT_REGEX, '.png');
      imageExtensionFallbackRef.current = '.jpg';
    } else if (currentExtension === '.jpg') {
      event.target.src = currentSrc.replace(FILE_EXT_REGEX, '.jpg');
      imageExtensionFallbackRef.current = 'fallback';
    } else {
      if (!fallbackImageSrc) {
        event.target.src = '';
        return;
      }
      event.target.src = fallbackImageSrc;
    }
  };

  return { setFallbackImageSource };
};

export default useFallbackImage;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { parseJwt } from 'utils/parseJwt';
import { login } from 'services/admin';
import './styles.css';
import Loading from 'components/styled/Loading';
import { UserRoleType } from 'constants/userRoleType';

const isAuthorizedForWeb = (token) => {
	const { roles } = parseJwt(token);

	if (!roles)
		return false;

	return roles.some(r => r === UserRoleType.admin);
}

const Login = () => {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [token, setToken] = useState(localStorage.getItem('token'))

	useEffect(() => {
		if (token)
			history.push('/admin/users');
	}, [token, history]);

	const submitForm = event => {
		event.preventDefault();
		setLoading(true);
		login({ email, password })
			.then(({ data }) => {
				if (!isAuthorizedForWeb(data)) {
					setError('You don\'t have access to DumpWeb!');

					return;
				}

				localStorage.setItem("token", data);
				setToken(data);
			})
			.catch(() => {
				setError('Failed to authenticate.');
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="login__form__container">
			<h2>Login</h2>
			{loading && <Loading />}
			{!loading && <form className="login__form" onSubmit={e => submitForm(e)}>
				{error && <span className="login__error">{error}</span>}
				<label htmlFor="email">Email</label>
				<input type="email" value={email} onChange={e => setEmail(e.target.value)}></input>
				<label htmlFor="password">Password</label>
				<input type="password" value={password} onChange={e => setPassword(e.target.value)}></input>
				<input type="submit" value="Submit"></input>
			</form>
			}
		</div>
	);
}

export default Login;
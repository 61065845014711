import { endpoints } from './common';
import axios from 'axios';

export const getAllEvents = () => {
  return axios.get(endpoints.event.getAll);
};

export const getEventById = ({ eventId }) => {
  return axios.get(endpoints.event.getEventById + '/' + eventId);
};

export const getActiveEvent = () => {
  return axios.get(endpoints.event.getActive);
};

export const getLatestEventImages = ({ numberOfEvents }) => {
  return axios.get(endpoints.event.getLatestEventImages + '/' + numberOfEvents);
};

import React, { useState, useEffect } from 'react';
import { addTrustedPartner, editTrustedPartner, allTrustedPartners } from 'services/trustedPartner';
import './styles.css';
import Loading from 'components/styled/Loading';

const AddEditTrustedPartner = ({ trustedPartner, setTrustedPartnerToEdit, setTrustedPartners }) => {
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (trustedPartner) {
      setName(trustedPartner.name);
      setLink(trustedPartner.linkToWebsite);
    }
  }, [trustedPartner]);

  const getTrustedPartners = () => {
    allTrustedPartners()
      .then(response => {
        setTrustedPartners(response.data);
      })
      .catch(() => alert('Cannot get trusted partners'));
  };

  const submitForm = e => {
    e.preventDefault();
    setLoading(true);

    if (!trustedPartner)
      addTrustedPartner({ name, linkToWebsite: link })
        .then(() => {
          setName('');
          setLink('');
          getTrustedPartners();
          setError('');
        })
        .catch(() => setError('Failed to add trusted partner'))
        .finally(() => setLoading(false));
    else
      editTrustedPartner({ id: trustedPartner.id, name, linkToWebsite: link })
        .then(() => {
          setTrustedPartnerToEdit(null);
          getTrustedPartners();
          setError('');
        })
        .catch(() => setError('Failed to edit trusted partner'))
        .finally(() => setLoading(false));
  };

  return (
    <>
      {!trustedPartner && (
        <div className="form__add__header">
          <span>Trusted partner add</span>
        </div>
      )}
      {loading && <Loading />}
      {!loading && (
        <form className="form__add trusted-partner__add" onSubmit={submitForm}>
          {error && <span className="form__error">{error}</span>}
          <input
            className="input"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Ime partnera (mora biti jednako imenu slike samo bez podcrtica ( _ ) )"
          />
          <input
            className="input"
            value={link}
            onChange={e => setLink(e.target.value)}
            placeholder="Link na partnerovu web-stranicu (ne upisivati https://)"
          />
          <div className="form__add__buttons">
            <input className="button-admin submit-button" type="submit" />
            {trustedPartner && (
              <button className="button-admin cancel-button" onClick={() => setTrustedPartnerToEdit(null)}>
                Cancel
              </button>
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default AddEditTrustedPartner;

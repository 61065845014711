import React from 'react';
import styled from 'styled-components';
import './styles.css';

import { sortEventSectionMembers } from 'utils/sortEventSectionMembers';
import { addLinkToText } from 'utils/addLinkToText';

import { S3_BUCKET_BASE_URL as base } from 'services/common';
import Download from 'assets/icons/download.svg';
import YoutubeLink from 'assets/icons/youtube_link.svg';
import useFallbackImage from 'hooks/useFallbackImage';

const EventSectionStyled = styled.article`
  display: flex;
  align-items: flex-start;
  margin-bottom: 125px;
  position: relative;

  & > img {
    width: 488px;
    height: 368px;
    border-radius: 7px;
    margin-right: 74px;
  }

  @media (max-width: 1100px) {
    & > img {
      width: 300px;
      height: auto;
    }
  }

  @media (max-width: 800px) {
    & > img {
      width: 50%;
    }
  }

  @media (max-width: 710px) {
    flex-direction: column;

    & > img {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const EventSection = ({ eventSection }) => {
  const isFootageLinkEmpty = () => eventSection.footageLink.length === 0;
  const { setFallbackImageSource } = useFallbackImage('');
  return (
    <EventSectionStyled>
      <img
        src={`${base}/images/${eventSection.imagePath.folder}/${eventSection.imagePath.image}`}
        alt={`Event section ${eventSection.title}`}
        onError={setFallbackImageSource}
      />
      <div className="event-section__content">
        <div className="event-section__heading">
          <div className="heading__wrap">
            <h3 className="heading__title">{eventSection.title}</h3>
            <div className="heading__links">
              {!isFootageLinkEmpty() && (
                <a href={`${eventSection.footageLink}`} target="_blank" rel="noopener noreferrer">
                  <img className="heading__link" src={YoutubeLink} alt={`Event ${eventSection.title} youtube video link`} />
                </a>
              )}
              {eventSection.fileName.length !== 0 && (
                <a className="event-section__download" href={`${base}/files/${eventSection.fileName}`} download>
                  <img className="heading__link" src={Download} alt={`Event ${eventSection.title} zip download link`} />
                </a>
              )}
            </div>
          </div>
          <div className="event-section__description">
            <p className="description__text" dangerouslySetInnerHTML={{__html: addLinkToText(eventSection.text)}}></p>
          </div>
          <div className="event-section__event-footer">
            {eventSection.eventSectionMembers.length !== 0 && (
              <div>
                <h4 className="footer-container__title">Predavači</h4>
                <p className="footer-container__content">
                  {sortEventSectionMembers(eventSection.eventSectionMembers).map((eventSectionMember, index) => {
                    return (
                      <span key={index}>
                        {eventSectionMember.member.firstName} {eventSectionMember.member.lastName}
                        {eventSection.eventSectionMembers.length - 1 !== index && <>,&nbsp;</>}
                      </span>
                    );
                  })}
                </p>
              </div>
            )}
            {eventSection.timeAndLocation.length !== 0 && (
              <div>
                <h4 className="footer-container__title">Vrijeme i mjesto</h4>
                <p className="footer-container__content">{eventSection.timeAndLocation}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </EventSectionStyled>
  );
};

export default EventSection;

import React from 'react';

import { ReactComponent as XIcon } from 'assets/icons/x-white.svg';
import DevHeaderImage from 'assets/visuals/internship/dev-header.png';
import DevFirstIcon from 'assets/icons/internship/modals/dev-icon-1.svg';
import DevSecondIcon from 'assets/icons/internship/modals/dev-icon-2.svg';
import DevThirdIcon from 'assets/icons/internship/modals/dev-icon-3.svg';
import DevFirstImage from 'assets/visuals/internship/dev-1.png';
import DevSecondImage from 'assets/visuals/internship/dev-2.png';
import DevThirdImage from 'assets/visuals/internship/dev-3.png';
import DevHowItLooksImage from 'assets/visuals/internship/dev-how-it-looks.png';
import DevExamImageDesktop from 'assets/visuals/internship/dev-exam.png';
import DevExamImageMobile from 'assets/visuals/internship/dev-exam-mobile.png';
import DevExpectationsImageDesktop from 'assets/visuals/internship/dev-expectations.png';
import DevExpectationsImageMobile from 'assets/visuals/internship/dev-expectations-mobile.png';

import Header from './Header';
import TextRightSection from './TextRightSection';
import TextLeftSection from './TextLeftSection';
import QuizSection from './QuizSection';
import HowItLooksSection from './HowItLooksSection';
import WhatsComingSection from './WhatsComingSection';
import SuggestedDisciplines from './SuggestedDisciplines';

import { Discipline } from '../consts';
import { Color, StyledWrapper, StyledContainer, StyledCloseButton, StyledTextSectionsWrapper } from './index.styled';

const DevelopmentModal = ({ internshipUrl, isOpen, close, setActiveDisciplineModal }) => {
  return (
    <StyledWrapper isOpen={isOpen} color={Color.green}>
      {isOpen && (
        <StyledContainer>
          <StyledCloseButton onClick={close}>
            <XIcon />
          </StyledCloseButton>

          <Header
            internshipUrl={internshipUrl}
            backgroundColor={Color.lightGreen}
            seeThroughColor={Color.seeThroughGreen}
            title="Programiranje"
            description="Programeri u Udruzi prvenstveno se bave izradom custom web aplikacija uz usku suradnju s ostalim područjima, poglavito s Dizajnom."
            perks={[
              {
                iconSrc: DevFirstIcon,
                label: 'Git',
              },
              {
                iconSrc: DevSecondIcon,
                label: 'Backend',
              },
              {
                iconSrc: DevThirdIcon,
                label: 'Frontend',
              },
            ]}
            buttonLabel="Prijavi se na programiranje"
            buttonColor={Color.dark}
            imageSrc={DevHeaderImage}
          />
          <StyledTextSectionsWrapper>
            <TextRightSection
              imageSrc={DevFirstImage}
              title="Git"
              description="Prvo ćeš se upoznati s Gitom. On ti omogućuje da pratiš sve revizije na izvornom kodu, ali i da ukloniš svu bespotrebnu komunikaciju s poslužiteljem."
              tags={['Repozitorij', 'Commit', 'Pull request', 'Git bash', 'GitHub']}
            />
            <TextLeftSection
              title="Backend"
              description="Kad budeš dovoljno dobro poznavao Git, onda ćeš se usredotočiti na ono što se događa na poslužiteljskom dijelu aplikacije. Detaljno ćeš se upoznati s backend programiranjem."
              tags={['C#', 'OOP', 'SQL baze podataka', 'NestJS']}
              imageSrc={DevSecondImage}
            />
            <TextRightSection
              imageSrc={DevThirdImage}
              title="Frontend"
              description="Nakon backenda, željno te iščekuje frontend programiranje. Tu dolazi do izražaja suradnja s Dizajnom jer ćeš programirati korisnička sučelja koja ti dizajneri pripreme."
              tags={['Sliceing', 'HTML', 'CSS', 'JavaScript', 'React']}
            />
          </StyledTextSectionsWrapper>
          <QuizSection
            color={Color.lightGreen}
            imageSrcDesktop={DevExamImageDesktop}
            imageSrcMobile={DevExamImageMobile}
            title="Ispit predznanja"
            description="Preduvjet za ulazak u područje programiranja je uspješno rješavanje ispita temeljenog na algoritamskim zadatcima. Ispit se piše za računalom, a možeš ga riješiti u jednom od ponuđenih jezika (JavaScript, Python, C#, C++, C, Java, Go). Naši edukativni ciklusi iz programiranja uvijek su dobar izvor potrebnog znanja."
            buttonLabel="Škola osnova programiranja"
            buttonColor={Color.red}
            buttonHref="https://www.youtube.com/watch?v=sokmtV1_SRc"
          />
          <HowItLooksSection
            title="Kako će sve to izgledat?"
            imageSrc={DevHowItLooksImage}
            features={[
              {
                title: 'Predavanja',
                description:
                  'Kroz dvadesetak predavanja učiti ćeš od osnova programiranja pa sve do izrade cijele web aplikacije. Unutar svake cjeline predavanja se tematski nadovezuju jedno na drugo te iz istog razloga svako predavanje je popraćeno s obveznim domaćim radom.',
              },
              {
                title: 'Domaći radovi',
                description:
                  'Domaći rad nam služi da dobijemo uvid koliko si savladao gradivo s prijašnjeg predavanja te da te pomognemo usmjeriti ako vidimo da si na dobrom putu.',
              },
              {
                title: 'Usvajanje znanja',
                description:
                  'Suradnja s lokalnom zajednicom je Udruzi iznimno važno, a imat ćeš priliku usvajati i znanje od raznih IT stručnjaka koji nas podržavaju i ove godine tijekom Internshipa.',
              },
            ]}
          />
          <WhatsComingSection
            internshipUrl={internshipUrl}
            color={Color.lightGreen}
            imageSrcDesktop={DevExpectationsImageDesktop}
            imageSrcMobile={DevExpectationsImageMobile}
            title="Što te očekuje?"
            listItems={[
              'Učenje najpopularnijih web tehnologija',
              'Usavršavanje i napredak kroz izazovne zadatke',
              'Rad u dinamičnim timovima i na stvarnim projektima',
              'Predavanja IT stručnjaka i posjeti lokalnim tvrtkama',
            ]}
            tickColor={Color.dark}
            buttonLabel="Prijavi se na Programiranje"
            buttonColor={Color.dark}
          />
          <SuggestedDisciplines
            activeDisciplineKey={Discipline.development}
            setActiveDisciplineModal={setActiveDisciplineModal}
          />
        </StyledContainer>
      )}
    </StyledWrapper>
  );
};

export default DevelopmentModal;

export const SectionType = {
  TextLeftImageRight: 'TextLeftImageRight',
  TextRightImageLeft: 'TextRightImageLeft',
  OnlyTextCenter: 'OnlyTextCenter',
  OnlyImageCenter: 'OnlyImageCenter',
  OnlyTextLeft: 'OnlyTextLeft',
  OnlyTextRight: 'OnlyTextRight',
  TripleTextEditor: 'TripleTextEditor',
  ImageGallery: 'ImageGallery',
};

export const DraggableTypes = {
  section: 'section',
};

import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: 314px;

  @media screen and (max-width: 768px) {
    margin-top: 240px;
  }
`;

export const StyledTextContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto 70px;

  @media screen and (max-width: 1100px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledTitle = styled.h1`
  font-family: 'Fuse V.2 Display';
  font-size: 112px;
  font-weight: 800;
  line-height: 120px;
  color: #282d30;
  margin: 0;

  @media screen and (max-width: 1100px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const StyledDescription = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  margin: 24px 0 0;
  max-width: 700px;

  @media screen and (max-width: 1100px) {
    max-width: 400px;
  }
`;

export const StyledImage = styled.img`
  max-width: 1440px;
  display: flex;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;

export const StyledSelfPraiseContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 70px auto 0;
  max-width: 1100px;

  @media screen and (max-width: 1200px) {
    max-width: 680px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledSelfPraiseItem = styled.div`
  margin-bottom: 70px;
  max-width: 270px;
  flex-basis: calc(100% / 3 - 140px);
  flex-grow: 1;

  &:not(:nth-child(3n)) {
    margin-right: 136px;
  }

  @media screen and (max-width: 1200px) {
    flex-basis: calc(50% - 35px);

    &:not(:nth-child(3n)) {
      margin-right: unset;
    }

    &:not(:nth-child(2n)) {
      margin-right: 70px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 80px;

    &:not(:nth-child(2n)) {
      margin-right: unset;
    }
  }
`;

export const StyledSelfPraiseItemTitle = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #111111;
  margin: 0;
`;

export const StyledSelfPraiseItemText = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  margin: 0;
`;

import React, { useState } from 'react';
import { addConfiguration } from 'services/configuration';
import Flex from 'components/styled/Flex';

const AddConfiguration = ({ trigger, currentConfigurations }) => {
  const [error, setError] = useState('');
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');

  const validate = () => {
    if (currentConfigurations.some(configuration => configuration.key === key)) {
      setError(`${key} already exists, try updating it instead`);

      return false;
    }

    if (key === '') {
      setError('Key cannot be an empty string');

      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    setError('');
    validate() &&
      addConfiguration({ key, value })
        .then(() => {
          setKey('');
          setValue('');
          trigger(prev => !prev);
        })
        .catch(() => alert(`Oops, an error occurred while creating '${key}'`));
  };

  return (
    <Flex justifyContent="center" direction="column">
      <div className="form__add__header">
        <span>Add configuration</span>
      </div>
      <div className="form__add">
        <label className="label">Key</label>
        <input className="input" type="text" value={key} onChange={e => setKey(e.target.value)} />
        <label className="label">Value</label>
        <input className="input" type="text" value={value} onChange={e => setValue(e.target.value)} />
        {error && <span className="form__error">{error}</span>}
        <div className="form__add__buttons">
          <button className="button submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </Flex>
  );
};

export default AddConfiguration;

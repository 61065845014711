const EVENT_DUMP = 'dumpEvent';
const ACTIONS = {
  click: 'click',
  view: 'view',
};

const gtmPush = (event, eventAction, eventCategory, eventLabel, eventValue) => {
  return window.dataLayer.push({
    event,
    eventAction,
    eventCategory,
    eventLabel,
    eventValue,
  });
};

export const socialEventPush = network => {
  const eventCategory = 'Social button';

  return gtmPush(EVENT_DUMP, ACTIONS.click, eventCategory, network);
};

export const caseStudyEventPush = (projectName, projectId) => {
  const eventCategory = 'Case study';

  return gtmPush(EVENT_DUMP, ACTIONS.view, eventCategory, projectName, projectId);
};

export const eventEventPush = (eventNameAndYear, eventId) => {
  const eventCategory = 'Event';

  return gtmPush(EVENT_DUMP, ACTIONS.view, eventCategory, eventNameAndYear, eventId);
};

export const internshipEventPush = () => {
  const eventCategory = 'Internship';

  return gtmPush(EVENT_DUMP, ACTIONS.view, eventCategory);
};

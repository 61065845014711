import React, { useState } from 'react';
import '../styles.css';

import { saveProject, editProject, setProjectSkills } from 'services/admin';
import { serializeProject } from 'services/projectLogic';
import { isNullOrWhitespace } from 'utils/string';

import Flex from 'components/styled/Flex';
import ImagePicker from 'components/common/ImagePicker';
import RichTextEditor from 'components/common/RichTextEditor';
import Project from 'components/common/Project';
import MultiSelect from 'react-multi-select-component';

const ProjectEditor = ({ setAdd, project, isEdit, setEditingIndex, refreshProjects, lastIndex, fieldOfWorkTypes }) => {
  const fieldsOfWorkOptions = fieldOfWorkTypes.map(fieldOfWork => {
    return {
      label: fieldOfWork.replace(/([A-Z])/g, ' $1').trim(),
      value: fieldOfWork,
    };
  });

  const projectSectionsInitial = project.projectSkills;
  const [editingProject, setEditingProject] = useState(project);
  const [fieldsOfWork, setFieldsOfWork] = useState(
    projectSectionsInitial.map(projectSkill => fieldsOfWorkOptions.find(fwt => fwt.value === projectSkill.skill)),
  );

  const setPropertyOnEditingProject = ({ target: { value, name } }) => {
    setEditingProject({ ...editingProject, [name]: value });
  };

  const setFieldOfWorkOnEditingProject = fieldsOfWork => {
    setFieldsOfWork(fieldsOfWork);
    setEditingProject({ ...editingProject, projectSkills: fieldsOfWork.map(field => ({ skill: field.value })) });
  };

  const setTitle = title => {
    setEditingProject({ ...editingProject, title });
  };

  const setShortDescription = shortDescription => {
    setEditingProject({ ...editingProject, shortDescription });
  };

  const setBackgroundImageOffset = ({ target: { value, name } }) => {
    setEditingProject({
      ...editingProject,
      backgroundImageOffset: { ...editingProject.backgroundImageOffset, [name]: Number(value) },
    });
  };

  const setCtaTextCoordinates = ({ target: { value, name } }) => {
    setEditingProject({
      ...editingProject,
      ctaTextCoordinates: { ...editingProject.ctaTextCoordinates, [name]: Number(value) },
    });
  };

  const setImagePath = imagePath => {
    setEditingProject({ ...editingProject, imagePath });
  };

  const submitProject = e => {
    e.preventDefault();
    const editingProjectSerialized = { ...editingProject };
    if (isNullOrWhitespace(editingProjectSerialized.name)) {
      window.alert('All projects need to have a title');
      return;
    }
    serializeProject(editingProjectSerialized);
    if (isEdit) {
      const projectSkills = fieldsOfWork.map(fw => {
        const projectSkill = projectSectionsInitial.find(ps => ps === fw.value);
        if (projectSkill) return projectSkill;
        return {
          id: 0,
          skill: fw.value,
          projectId: editingProjectSerialized.id,
        };
      });
      editProject(editingProjectSerialized);
      setProjectSkills({ projectSkills, projectId: editingProjectSerialized.id }).then(() => {
        refreshProjects();
        setEditingIndex(undefined);
      });
      return;
    }
    saveProject({ ...editingProjectSerialized, priority: lastIndex })
      .then(({ data }) => {
        setAdd(false);
        return data;
      })
      .then(data => {
        const projectSkills = fieldsOfWork.map(fw => {
          return {
            projectId: data,
            skill: fw.value,
          };
        });
        return setProjectSkills({ projectSkills, projectId: data }).then(() => refreshProjects());
      });
  };

  const setCancel = () => {
    if (isEdit) {
      refreshProjects();
      setEditingIndex(undefined);
    } else setAdd(false);
  };

  return (
    <Flex direction="column" alignItems="center">
      <div className="form__add__header">Project editor</div>
      {editingProject && (
        <form className="form__add" onSubmit={submitProject}>
          <label className="label">Name</label>
          <input
            name="name"
            className="input"
            type="text"
            value={editingProject.name}
            onChange={setPropertyOnEditingProject}
          />
          <label className="label">Title</label>
          <RichTextEditor text={editingProject.title} setValueCallback={setTitle} isLinkDisabled={true} />
          <label className="label">Margin top</label>
          <input
            name="marginTop"
            className="input"
            type="number"
            value={editingProject.backgroundImageOffset.marginTop}
            onChange={setBackgroundImageOffset}
          />
          <label className="label">Margin right</label>
          <input
            name="marginRight"
            className="input"
            type="number"
            value={editingProject.backgroundImageOffset.marginRight}
            onChange={setBackgroundImageOffset}
          />
          <label className="label">Margin bottom</label>
          <input
            name="marginBottom"
            className="input"
            type="number"
            value={editingProject.backgroundImageOffset.marginBottom}
            onChange={setBackgroundImageOffset}
          />
          <label className="label">Margin left</label>
          <input
            name="marginLeft"
            className="input"
            type="number"
            value={editingProject.backgroundImageOffset.marginLeft}
            onChange={setBackgroundImageOffset}
          />
          <label className="label">Width of background image</label>
          <input
            name="width"
            className="input"
            type="number"
            value={editingProject.backgroundImageOffset.width}
            onChange={setBackgroundImageOffset}
          />
          <label className="label">Set text Y axis</label>
          <input
            name="top"
            className="input"
            type="number"
            value={editingProject.ctaTextCoordinates.top}
            onChange={setCtaTextCoordinates}
          />
          <label className="label">Set text X axis</label>
          <input
            name="left"
            className="input"
            type="number"
            value={editingProject.ctaTextCoordinates.left}
            onChange={setCtaTextCoordinates}
          />
          <label className="label">Field of work</label>
          <MultiSelect
            options={fieldsOfWorkOptions}
            value={fieldsOfWork}
            onChange={setFieldOfWorkOnEditingProject}
            labelledBy={'Select field of work'}
          />
          <label className="label">Project skills background color</label>
          <input
            name="projectSkillsBackgroundColor"
            className="input"
            maxLength="30"
            value={editingProject.projectSkillsBackgroundColor}
            onChange={setPropertyOnEditingProject}
          />
          <label className="label">Project call to action text alignment</label>
          <select
            name="ctaTextAlignment"
            value={editingProject.ctaTextAlignment}
            onChange={setPropertyOnEditingProject}
          >
            <option value="flex-start">Start</option>
            <option value="center">Center</option>
            <option value="flex-end">End</option>
          </select>
          <ImagePicker
            className="image-picker-input"
            imagePath={editingProject.imagePath}
            imagePathChangedCallback={setImagePath}
          />
          <RichTextEditor
            text={editingProject.shortDescription}
            setValueCallback={setShortDescription}
            isLinkDisabled={true}
          />
          <div className="form__add__buttons">
            <input type="submit" className="button submit-button" value="Save" />
            <button className="button-admin cancel-button" onClick={setCancel}>
              Cancel
            </button>
          </div>
        </form>
      )}
      {editingProject && <Project isFirst project={editingProject} isPreview />}
    </Flex>
  );
};

export default ProjectEditor;

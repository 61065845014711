import React from 'react';

import {
  StyledReasonsItem,
  StyledReasonsIcon,
  StyledReasonsTextContainer,
  StyledReasonsItemTitle,
  StyledReasonsItemText,
} from './index.styled';

const ReasonsItem = ({ icon, title, text }) => {
  return (
    <StyledReasonsItem>
      <StyledReasonsIcon src={icon} />
      <StyledReasonsTextContainer>
        <StyledReasonsItemTitle>{title}</StyledReasonsItemTitle>
        <StyledReasonsItemText>{text}</StyledReasonsItemText>
      </StyledReasonsTextContainer>
    </StyledReasonsItem>
  );
};

export default ReasonsItem;

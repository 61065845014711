import styled from 'styled-components';

import { FlexSection } from 'components/styled/Flex';

export const StyledFlexSection = styled(FlexSection)`
  background: linear-gradient(to bottom, #ffffff, #ffffff 36%, #f7f9fb 36%);

  @media (max-width: 1024px) {
    background: linear-gradient(to bottom, #ffffff, #ffffff 28%, #f7f9fb 28%);
  }
`;

export const StyledWrapper = styled.section`
  margin: 0 216px 128px 216px;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 1024px) {
    margin: 0 112px 178px 112px;
  }

  @media (max-width: 768px) {
    margin: 0 24px 178px 24px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fuse V.2 Display';
  font-size: 112px;
  font-weight: 800;
  color: #111111;
  text-transform: uppercase;
  margin: 226px 0 96px 0;

  @media (max-width: 1024px) {
    margin: 178px 0 24px 0;
  }

  @media (max-width: 1024px) {
    font-size: 80px;
  }
`;

export const StyledFaq = styled.article`
  padding: 34px 32px;
  box-shadow: 0px 8px 40px 0px #d6e0e94f, 0px 4px 16px 0px #d6e0e91f, 0px 2px 6px 0px #d6e0e90f;
  border-radius: 24px;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  opacity: 0;
  transform: translate3d(-100px, 0, 0) rotate(0.02deg);

  &.enter-done {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(0.02deg);
    transition: all 0.4s cubic-bezier(0.01, 0.72, 0.4, 1.01);
  }

  @media screen and (max-width: 380px) {
    padding: 34px 14px;
  }
`;

export const StyledQuestion = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-size: 24px;
  font-weight: 800;
  color: #282d30;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 380px) {
    font-size: 20px;
  }
`;

export const StyledArrow = styled.img`
  margin-left: 16px;
  transform: ${({ isActive }) => (!isActive ? 'rotate(180deg);' : 'rotate(360deg);')};
  transition: transform 200ms ease;
`;

export const StyledAnswer = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  margin: 0;
  margin-top: 18px;
`;

import React from 'react';
import './styles.css';
import * as dayjs from 'dayjs';

const PreviewContactQuestions = ({ contactQuestion }) => {
  return (
    <div className="contact-question__preview__container">
      <div className="contact-question__preview">
        <span className="contact-question__preview__display-datetime">
          <span>{dayjs(contactQuestion.datePosted).format('DD.MM.YYYY HH:mm')}</span>
        </span>
        <span className="contact-question__preview__display-mail">{contactQuestion.senderMail}</span>
        <span className="contact-question__preview__display-name">{contactQuestion.senderName}</span>
        <span className="contact-question__preview__display-info">
          {contactQuestion.contactQuestionInfo.displayText}
        </span>
        <span className="contact-question__preview__display-description">{contactQuestion.content}</span>
        <span className="contact-question__preview__display-newsletter">
          {contactQuestion.newsletterAgreed ? 'Yes' : 'No'}
        </span>
      </div>
    </div>
  );
};

export default PreviewContactQuestions;

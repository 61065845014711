import React, { useState } from 'react';
import '../styles.css';

import ProjectSectionItem from './ProjectSectionItem';
import { SectionType } from 'constants/sectionType';

import Flex from 'components/styled/Flex';

const ProjectSectionCreator = ({ itemDroppedCallback }) => {
  const sectionTypeArray = Object.keys(SectionType);
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Flex
      className={`project-section-creator ${isCollapsed && 'project-section-creator--collapsed'}`}
      direction="column"
    >
      <button
        className="button-admin submit-button project-section-creator__collapse"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed && <>{`<`}</>}
        {!isCollapsed && <>{'>'}</>}
      </button>
      {sectionTypeArray.map((sectionType, index) => (
        <ProjectSectionItem key={index} sectionType={sectionType} itemDroppedCallback={itemDroppedCallback} />
      ))}
    </Flex>
  );
};

export default ProjectSectionCreator;

export const sortEventSectionMembers = eventSectionMembers => {
  eventSectionMembers.sort((a, b) => {
    const nameA = a.member.lastName.toLowerCase();
    const nameB = b.member.lastName.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return eventSectionMembers;
};

import React, { useEffect, useState, useCallback } from 'react';
import { allTrustedPartners, deleteTrustedPartner } from 'services/trustedPartner';
import Loading from 'components/styled/Loading';

const PreviewTrustedPartners = ({ setTrustedPartnerToEdit, trustedPartners, setTrustedPartners }) => {
  const [loading, setLoading] = useState(true);
  const getTrustedPartners = useCallback(() => {
    allTrustedPartners()
      .then(response => {
        setTrustedPartners(response.data);
        setLoading(false);
      })
      .catch(error => alert('Cannot get trusted partners'));
  }, [setTrustedPartners]);

  useEffect(() => {
    getTrustedPartners();
  }, [getTrustedPartners]);

  const deletePartner = id => {
    deleteTrustedPartner({ id })
      .then(res => getTrustedPartners())
      .catch(err => alert('Delete failed'));
  };

  if (loading)
    return (
      <>
        <Loading />
      </>
    );

  return (
    <>
      <div className="user__preview__container">
        {trustedPartners &&
          trustedPartners.map((trustedPartner, index) => (
            <div key={index} className="user__preview">
              <span className="user__preview__email">{trustedPartner.name}</span>
              <div className="user__preview__controls">
                <button className="button-admin deactivate-button" onClick={() => deletePartner(trustedPartner.id)}>
                  Delete
                </button>
                <button className="button-admin edit-button" onClick={() => setTrustedPartnerToEdit(trustedPartner)}>
                  Edit
                </button>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default PreviewTrustedPartners;

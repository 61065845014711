import React from 'react';
import TextUnderline from 'components/styled/TextUnderline';
import dumpTeam from 'assets/visuals/photo-dump-team.webp';
import ImageLoader from 'components/common/ImageLoader';

const Header = () => {
  return (
    <div className="about__header">
      <h1 className="heading--big about__heading">
        Mi smo&nbsp;
        <TextUnderline color="#282d30" underlineColor="#5cc2a0cc">
          dumpovci
        </TextUnderline>
        .
      </h1>
      <ImageLoader className="about__header__image" src={dumpTeam} alt="" relative />
    </div>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import './styles.css';
import RichTextEditor from 'components/common/RichTextEditor';
import LibraryPicker from 'components/common/ImagePicker/LibraryPicker';
import { getActiveContactQuestionInfos, sendEmailBatch } from 'services/admin';
import escapeHtml from 'escape-html';
import { Text } from 'slate';

const BatchEmails = () => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState('');
  const [attachedFileData, setAttachedFileData] = useState([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [textData, setTextData] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [attachedLibraryPaths, setAttachedLibraryPaths] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    getActiveContactQuestionInfos().then(({ data }) => {
      const dropdownOptionsWithNewsletter = [];
      data.forEach(contactQuestionInfo => {
        if (contactQuestionInfo.doesHaveNewsletterOption) dropdownOptionsWithNewsletter.push(contactQuestionInfo);
      });
      setDropdownOptions(dropdownOptionsWithNewsletter);
    });
  }, []);

  const submitEmail = () => {
    if (emailSubject === '') {
      setErrorMessage('Subject line is empty');
      return;
    }
    if (selectedDropdownOption === '') {
      setErrorMessage('Select a topic to send to');
      return;
    }

    if (!window.confirm(`Are you sure you want to send?`)) return;
    const emailHtmlHeader =
      '<table><tr align="center"><div style="' +
      'max-width: 540px; ' +
      'border: 1px solid rgba(92, 194, 160, 1); ' +
      'border-radius: 5px; ' +
      'padding: 10px;' +
      '">';
    const arrayOfElements = textData.map(node => {
      return serialize(node);
    });
    const emailHtmlFooter = '</tr></table></div>';

    const serializedHtml = emailHtmlHeader + arrayOfElements.join('') + emailHtmlFooter;
    const url = `${window.location.protocol}//${window.location.host}`;

    setConfirmationMessage('Poruke se šalju...');
    setIsSubmitDisabled(true);

    sendEmailBatch(selectedDropdownOption, emailSubject, serializedHtml, attachedFileData, url, attachedLibraryPaths)
      .then(data => {
        setErrorMessage('');
        setConfirmationMessage('Poruke su uspješno poslane');
        setTimeout(() => setConfirmationMessage(''), 2000);
        setIsSubmitDisabled(false);
      })
      .catch(() => {
        setConfirmationMessage('');
        setErrorMessage('Dogodila se greška :( Molimo pokušajte ponovo.');
        setTimeout(() => setErrorMessage(''), 5000);
        setIsSubmitDisabled(false);
      });
  };

  const handleFileSubmit = file => {
    readFile(file);
  };

  const readFile = file => {
    const reader = new FileReader();
    reader.onload = () => {
      setAttachedFileData([...attachedFileData, [reader.result, file.name]]);
    };
    reader.readAsDataURL(file);
  };

  const removeAttachment = fileName => {
    const indexToRemove = attachedFileData.findIndex(fileData => fileData[1] === fileName);
    const tmpAttachedFileData = [...attachedFileData];
    if (indexToRemove !== -1) {
      tmpAttachedFileData.splice(indexToRemove, 1);
    }
    setAttachedFileData(tmpAttachedFileData);
  };

  const attachLibraryImage = imageName => {
    setAttachedLibraryPaths([...attachedLibraryPaths, imageName]);
  };

  const removeLibraryImage = pathToDelete => {
    const indexToRemove = attachedLibraryPaths.findIndex(path => pathToDelete === path);
    const tmpAttachedLibraryPaths = [...attachedLibraryPaths];
    if (indexToRemove !== -1) {
      tmpAttachedLibraryPaths.splice(indexToRemove, 1);
    }
    setAttachedLibraryPaths(tmpAttachedLibraryPaths);
  };

  const getFileNameFromPath = path => {
    return path.substring(path.lastIndexOf('\\') + 1);
  };

  const serialize = node => {
    if (Text.isText(node)) {
      if (node.bold) return `<b>${node.text}</b>`;
      if (node.italic) return `<i>${node.text}</i>`;
      if (node.underline) return `<u>${node.text}</u>`;
      if (node.code) return `<code>${node.text}</code>`;
      if (node.text === '') return '<br>';
      return node.text;
    }

    const children = node.children.map(n => serialize(n)).join('');

    switch (node.type) {
      case 'h2':
        return `<h2>${children}</h2>`;
      case 'color':
        return `<span style="color: ${node.color}">${children}</span>`;
      case 'paragraph':
        return `<p>${children}</p>`;
      case 'link':
        return `<a href="${escapeHtml(node.url)}">${children}</a>`;
      case 'orderedList':
        return `<ol>${children}</ol>`;
      case 'unorderedList':
        return `<ul>${children}</ul>`;
      case 'listItem':
        return `<li>${children}</li>`;

      default:
        return children;
    }
  };

  return (
    <div className="batch-emails">
      <div className="batch-emails__text-editor">
        <div className="batch-emails__header">
          <select
            value={selectedDropdownOption}
            onChange={e => setSelectedDropdownOption(e.target.value)}
            className="batch-emails__topic-dropdown"
          >
            <option value="" disabled>
              Select topic
            </option>
            {dropdownOptions.map(option => {
              return (
                <option
                  key={option.id}
                  value={option.id}
                  className={`topic-dropdown__${option.isActive ? 'active' : 'inactive'}`}
                >
                  {option.displayText} - {option.description}
                </option>
              );
            })}
          </select>
          <div>
            <span className="batch-emails__text--error">{errorMessage}</span>
            <span className="batch-emails__text--confirmation">{confirmationMessage}</span>
          </div>
          <button className="button" onClick={submitEmail} disabled={isSubmitDisabled}>
            Send
          </button>
        </div>
        <input
          className="text-editor__subject"
          value={emailSubject}
          onChange={e => setEmailSubject(e.target.value)}
          placeholder="Enter email subject..."
        />
        <RichTextEditor
          className="text-editor__window"
          text={textData}
          setValueCallback={setTextData}
          isEmail={true}
          libraryAttachments={attachedLibraryPaths}
          fileAttachments={attachedFileData}
        />
        <div className="text-editor__attachments">
          {attachedFileData.map((file, key) => {
            return (
              <span key={key} className="attachments__attachment">
                {file[1]}
                <div
                  className="attachments__remove"
                  filename={file[1]}
                  onClick={e => removeAttachment(e.target.getAttribute('filename'))}
                >
                  X
                </div>
              </span>
            );
          })}
          {attachedLibraryPaths.map((path, key) => {
            return (
              <span key={key} className="attachments__attachment">
                {getFileNameFromPath(path)}
                <div
                  className="attachments__remove"
                  filename={path}
                  onClick={e => removeLibraryImage(e.target.getAttribute('filename'))}
                >
                  X
                </div>
              </span>
            );
          })}
        </div>
        <div className="text-editor__buttons">
          <input
            id="add-file"
            className="text-editor__file-input"
            type="file"
            onChange={e => handleFileSubmit(e.target.files[0])}
          />
          <label htmlFor="add-file" className="button">
            Browse your PC
          </label>
          <LibraryPicker pickedCallback={attachLibraryImage} />
        </div>
      </div>
    </div>
  );
};

export default BatchEmails;

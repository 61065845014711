import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { sortLogic } from 'utils/sortLogic';
import { DraggableTypes } from 'constants/sectionType';

import Project from 'components/common/Project';

const DraggableProjectStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  & > .droppable-section {
    display: none;
  }

  ${({ isActive }) =>
    isActive &&
    `
  & > .droppable-section {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
  }

  & > article {
    opacity: 0;
  }
  
  & > .droppable-section > div {
    height: 50%;
    color: #D8D8D8;
    width: 100%;
    border: 2px dashed #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border: 2px dashed #000;
    color: #000;
  }
  `}

  & > .sorting-placeholder {
    border: 2px dashed #d8d8d8;
    text-align: center;
    width: 100%;
    padding: 40px;
    font-size: 50px;
    visibility: ${({ visibility }) => visibility};
  }
`;

const ProjectDraggable = ({ project, index, findProject, moveProject, isActive, isSorting }) => {
  const ref = useRef(null);
  const originalIndex = findProject(index).index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: DraggableTypes.section, index, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { index: droppedIndex, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) moveProject(originalIndex, droppedIndex);
    },
  });

  const [, drop] = useDrop({
    accept: DraggableTypes.section,
    canDrop: () => false,
    hover({ index: draggedIndex }, monitor) {
      if (!ref.current) return;
      const { index: overIndex } = findProject(index);
      if (!sortLogic(draggedIndex, overIndex, ref, monitor)) return;

      moveProject(overIndex, draggedIndex);
    },
  });

  drag(drop(ref));
  const visibility = isDragging ? 'hidden' : 'visible';

  return (
    <DraggableProjectStyled ref={ref} visibility={visibility} isActive={isActive}>
      <div className="droppable-section">
        <div>
          <span>Sort above me</span>
        </div>
        <div>
          <span>Sort under me</span>
        </div>
      </div>
      {!isSorting && <Project project={project} />}
      {isSorting && <div className="sorting-placeholder">{project.name}</div>}
    </DraggableProjectStyled>
  );
};

export default ProjectDraggable;

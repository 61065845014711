import styled from 'styled-components';

import { getColor } from '../index.styled';

export const StyledWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 112px;
  margin-bottom: 200px;
  padding-top: 86px;
  padding-left: 112px;
  border-radius: 0px 40px 40px 0px;

  @media screen and (max-width: 1440px) {
    padding-left: 64px;
    margin-right: 64px;
  }

  @media screen and (max-width: 1250px) {
    padding-left: 32px;
    margin-right: 32px;
  }

  background-color: ${getColor};

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    margin-right: unset;
    border-radius: unset;
    padding-top: 64px;
    padding-left: unset;
    overflow-x: hidden;
    align-items: center;
  }
`;

export const StyledImageDesktop = styled.img`
  display: block;
  margin-right: 20px;

  @media screen and (max-width: 1000px) {
    width: 50%;
    height: auto;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledImageMobile = styled.img`
  display: none;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    margin-top: 80px;
    height: auto;
  }
`;

export const StyledTextWrapper = styled.div`
  max-width: 488px;
  margin-right: 104px;
  margin-bottom: 86px;

  @media (max-width: 767px) {
    margin-bottom: unset;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledTitle = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 112px;
  line-height: 120px;
  color: white;
  margin-top: 0;
  margin-bottom: 40px;

  @media screen and (max-width: 1100px) {
    font-size: 86px;
    line-height: 94px;
  }

  @media screen and (max-width: 950px) {
    font-size: 64px;
    line-height: 72px;
  }

  @media (max-width: 767px) {
    font-size: 56px;
    line-height: 60px;
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 40px;
`;

export const StyledListItem = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 48px;
  }
`;

export const StyledTickWrapper = styled.div`
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 4px;

  background-color: ${getColor};
`;

export const StyledListItemText = styled.span`
  font-family: 'Fuse V.2 Display';
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 340px;

  @media (max-width: 500px) {
    max-width: 320px;
  }
`;

export const StyledButton = styled.button`
  border: unset;
  padding: 18px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  color: white;
  cursor: pointer;

  background-color: ${getColor};

  @media (max-width: 767px) {
    margin-bottom: 80px;
    width: 100%;
  }
`;

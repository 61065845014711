export class EventSectionLogic {
  id;
  title;
  text;
  footageLink;
  timeAndLocation;
  sectionOrder;
  eventId;
  event;
  eventSectionMembers;
  fileName;

  constructor(eventId, sectionOrder) {
    this.id = 0;
    this.title = 'Event section title';
    this.footageLink = '';
    this.timeAndLocation = '';
    this.sectionOrder = sectionOrder ? sectionOrder : 999;
    this.eventId = eventId;
    this.eventSectionMembers = [];
    this.fileName = '';
    this.imagePath = {
      folder: 'Default',
      image: 'event-section-default.jpg',
    };
  }
}

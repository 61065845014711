import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './styles.css';

import AdminNavigation from './AdminNavigation.js';
import Login from './Login';
import Users from './Users';
import ContactQuestionInfo from './ContactQuestionInfos';
import ContactQuestions from './ContactQuestions';
import BatchEmails from './BatchEmails';
import MailingLists from './MailingLists';
import Members from './Members';
import Builders from './Builders';
import TimelinePosts from './TimelinePosts';
import TrustedPartnerBuilder from './TrustedPartner';
import Configuration from './Configuration';
import Library from './Library';
import UserLogs from './UserLogs';
import Flex from 'components/styled/Flex';

const AdminLayoutScene = ({ children, screenOnly }) => {
  return (
    <Flex direction="column">
      {!screenOnly && <AdminNavigation />}
      {children}
    </Flex>
  );
};

const Admin = () => {
  return (
    <Switch>
      <Route path="/admin/login">
        <AdminLayoutScene screenOnly>
          <Login />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/users">
        <AdminLayoutScene>
          <Users />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/members">
        <AdminLayoutScene>
          <Members />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/timeline">
        <AdminLayoutScene>
          <TimelinePosts />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/builders">
        <AdminLayoutScene>
          <Builders />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/trusted-partners">
        <AdminLayoutScene>
          <TrustedPartnerBuilder />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/contact-question-info">
        <AdminLayoutScene>
          <ContactQuestionInfo />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/contact-questions">
        <AdminLayoutScene>
          <ContactQuestions />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/batch-emails">
        <AdminLayoutScene>
          <BatchEmails />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/mailing-lists">
        <AdminLayoutScene>
          <MailingLists />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/configuration">
        <AdminLayoutScene>
          <Configuration />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/library">
        <AdminLayoutScene>
          <Library />
        </AdminLayoutScene>
      </Route>
      <Route path="/admin/user-logs">
        <AdminLayoutScene>
          <UserLogs />
        </AdminLayoutScene>
      </Route>
      <Redirect to="/admin/login" />
    </Switch>
  );
};

export default Admin;

import React, { useState, useEffect } from 'react';
import { getActiveContactQuestionInfos, getNewsletterSubscribers } from 'services/admin';
import './styles.css';

const MailingLists = () => {
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [selectedDropdownOption, setSelectedDropdownOption] = useState('');
    const [subscribers, setSubscribers] = useState([])
    const [delimiter, setDelimiter] = useState(";")

    useEffect(() => {
        getActiveContactQuestionInfos()
            .then(({ data }) => data.filter(contactQuestionInfo => contactQuestionInfo.doesHaveNewsletterOption))
            .then(options => setDropdownOptions(options))        
    }, []);

    useEffect(() => {
        if (!selectedDropdownOption) {
            setSubscribers([])
            return
        }

        getNewsletterSubscribers(selectedDropdownOption)
            .then(({ data }) => data.map(subscriber => subscriber.senderMail))
            .then(subscribers => setSubscribers(subscribers))
    }, [selectedDropdownOption])

    return (
        <div className='mailing-lists'>
            <h2>Mailing lists</h2>
            <select
                value={selectedDropdownOption}
                onChange={e => setSelectedDropdownOption(e.target.value)}
                className="mailing-lists__topic-dropdown"
            >
                <option value="" disabled>
                    Select topic
                </option>
                {dropdownOptions.map(option => {
                    return (
                        <option
                            key={option.id}
                            value={option.id}
                            className={`topic-dropdown__${option.isActive ? 'active' : 'inactive'}`}
                        >
                            {option.displayText} - {option.description}
                        </option>
                    );
                })}
            </select>

            <h2>Delimiter</h2>
            <select
                value={delimiter}
                onChange={e => setDelimiter(e.target.value)}
                className="mailing-lists__topic-dropdown"
            >
                <option value=";">;</option>
                <option value=",">,</option>
                <option value="\n">line</option>
            </select>

            <h2>Subscribers</h2>
            {subscribers.length > 0 ? 
                <pre className='mailing-lists__view'>
                    {subscribers.join(delimiter).replaceAll("\\n", "\n")}
                </pre>
            : <div>Select topic</div>}
        </div>
    )
}

export default MailingLists
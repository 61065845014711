import { useRef, useState } from 'react';

const useHoverFallbackImage = fallbackImageSrc => {
  const haveEmptyFallbackImage = useRef(false);
  const imageExtensionFallbackRef = useRef('.webp');
  const [haveSourceImage, setHaveSourceImage] = useState(true);

  // Matches the file extension at the end of string or if no extension is present
  const FILE_EXT_REGEX = /(\.\w+)?$/;

  const setHoverFallbackImageSource = event => {
    if (!haveSourceImage && haveEmptyFallbackImage.current) {
      event.target.src = './images/members/active/fallback.png';
      return;
    }

    const currentExtension = imageExtensionFallbackRef.current;
    const currentSrc = haveSourceImage ? event.target.src : fallbackImageSrc;

    if (currentExtension === '.webp') {
      event.target.src = currentSrc.replace(FILE_EXT_REGEX, '.webp');
      imageExtensionFallbackRef.current = '.png';
    } else if (currentExtension === '.png') {
      event.target.src = currentSrc.replace(FILE_EXT_REGEX, '.png');
      imageExtensionFallbackRef.current = '.jpg';
    } else if (currentExtension === '.jpg') {
      event.target.src = currentSrc.replace(FILE_EXT_REGEX, '.jpg');
      imageExtensionFallbackRef.current = '.webp';
      setHaveSourceImage(false);

      if (!haveSourceImage) {
        haveEmptyFallbackImage.current = true;
      }
    }
  };

  return { setHoverFallbackImageSource };
};

export default useHoverFallbackImage;

export const events = [
  {
    id: 39,
    coverImagePath: {},
  },
  {
    id: 40,
    coverImagePath: {},
  },
  {
    id: 38,
    coverImagePath: {},
  },
  {
    id: 37,
    coverImagePath: {},
  },
  {
    id: 3312,
    coverImagePath: {},
  },
  {
    id: 412,
    coverImagePath: {},
  },
];

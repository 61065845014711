import { getMemberTypeEnum } from 'services/member';

export const constructMemberComparers = (memberTypesSorted) => {
  const nameComparer = (x, y) => `${x.lastName} ${x.firstName}` > `${y.lastName} ${y.firstName}` ? 1 : -1;

  const typeComparer = (x, y) => x.memberType !== y.memberType
    ? memberTypesSorted.indexOf(x.memberType) > memberTypesSorted.indexOf(y.memberType) ? 1 : -1
    : nameComparer(x, y);

  const newestComparer = (x, y) => x.joinDate !== y.joinDate
    ? x.joinDate < y.joinDate ? 1 : -1
    : nameComparer(x, y);

  const oldestComparer = (x, y) => x.joinDate !== y.joinDate
    ? x.joinDate > y.joinDate ? 1 : -1
    : nameComparer(x, y);

  const comparers = {
    type: typeComparer,
    name: nameComparer,
    newest: newestComparer,
    oldest: oldestComparer
  }

  return comparers;
}

export const getFilteredMembers = (members, filter) => members
  .filter(m => !filter.memberType || filter.memberType === m.memberType)
  .filter(m => `${m.firstName} ${m.lastName}`.toLowerCase().includes(filter.name.toLowerCase()))
  .filter(m => m.email.toLowerCase().includes(filter.email.toLowerCase()));

export const getSortedMemberTypes = async () => { 
  const data = await getMemberTypeEnum();
  const memberTypeKeys = Object.keys(data);

  return memberTypeKeys.sort((x,y) => data[x] - data[y]);
}
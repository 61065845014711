import React, { useState, createRef, useEffect } from 'react';
import '../styles.css';
import { Editor, Transforms, Range } from 'slate';
import { useSlate } from 'slate-react';
import isUrl from 'is-url';

import DropdownStyled from '../DropdownStyled';
import ToolbarMenuItemStyledSvg from '../ToolbarMenuItemStyledSvg';

export const withLinks = editor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => (element.type === 'link' ? true : isInline(element));

  editor.insertText = text => {
    if (text && isUrl(text)) wrapLink(editor, text);
    else insertText(text);
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) wrapLink(editor, text);
    else insertData(data);
  };

  return editor;
};

const insertLink = (editor, url, selection) => {
  if (editor.selection && !selection) wrapLink(editor, url);
  else if (selection) wrapLink(editor, url, selection);
};

const wrapLink = (editor, url, selection) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
    return;
  }

  if (!selection) selection = editor.selection;

  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) Transforms.insertNodes(editor, link);
  else {
    editor.selection = selection;
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' });
  return !!link;
};

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};

const LinkDropdown = ({ setIsClicked, editor, currentSelection }) => {
  const [selection] = useState(currentSelection);
  const [url, setUrl] = useState('');
  const ref = createRef(null);
  const handleOutsideClick = ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      setIsClicked(false);
      setUrl('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const onSubmit = () => {
    insertLink(editor, url, selection);
    setUrl('');
    setIsClicked(false);
  };

  return (
    <DropdownStyled ref={ref}>
      <label className="label">Link:</label>
      <input autoFocus className="input" type="text" value={url} onChange={e => setUrl(e.target.value)}></input>
      <button className="button submit-button" onClick={onSubmit}>
        Submit
      </button>
    </DropdownStyled>
  );
};

const LinkButton = () => {
  const [isClicked, setIsClicked] = useState(false);
  const editor = useSlate();

  return (
    <ToolbarMenuItemStyledSvg
      active={isLinkActive(editor, 'link')}
      onMouseDown={event => {
        event.preventDefault();
        setIsClicked(true);
      }}
    >
      <img src={require(`assets/icons/${'link'}.svg`)} alt={'link'} />
      {isClicked && <LinkDropdown setIsClicked={setIsClicked} editor={editor} currentSelection={editor.selection} />}
    </ToolbarMenuItemStyledSvg>
  );
};

export default LinkButton;

import React from 'react';

import HeaderImage from 'assets/visuals/internship/header.png';

import {
  StyledContainer,
  StyledTextContainer,
  StyledTitle,
  StyledSubtitle,
  StyledButton,
  StyledImage,
} from './index.styled';
import { StyledSpacing } from '../index.styled';

const Header = ({ internshipUrl, formId }) => {
  const scrollToForm = () => {
    const form = document.getElementById(formId);

    if (form) {
      form.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledSpacing>
      <StyledContainer>
        <StyledTextContainer>
          <StyledTitle>Postani dumpovac!</StyledTitle>
          <StyledSubtitle>
            Voliš sve digitalno? I mi! Zato se prijavi na DUMP Internship, započni svoju profesionalnu avanturu i
            pridruži nam se!
          </StyledSubtitle>
          {internshipUrl ? (
            <a href={internshipUrl} target="_blank" rel="noopener noreferrer">
              <StyledButton>Pridruži nam se</StyledButton>
            </a>
          ) : (
            <StyledButton onClick={scrollToForm}>Primi obavijest o idućem Internshipu</StyledButton>
          )}
        </StyledTextContainer>
        <StyledImage src={HeaderImage} alt="Dumpovci" height={488} width={488} />
      </StyledContainer>
    </StyledSpacing>
  );
};

export default Header;

import React from 'react';
import '../styles.css';
import { useDrag } from 'react-dnd';

import { SectionType } from 'constants/sectionType';
import { normalizeUpperCase } from 'utils/normalizeUpperCase';

const ProjectSectionItem = ({ sectionType, itemDroppedCallback }) => {
  const [{ opacity }, dragRef] = useDrag({
    item: { type: sectionType, sectionType: SectionType[sectionType] },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) itemDroppedCallback(item);
    },
  });

  return (
    <div ref={dragRef} className="project-section-creator__item" style={{ opacity }}>
      <span>{normalizeUpperCase(sectionType)}</span>
    </div>
  );
};

export default ProjectSectionItem;

import React from 'react';

import Flex from 'components/styled/Flex';

export const initialState = {
  filter: {
    memberType: "",
    name: "",
    email: "",
    sortBy: "type"
  }
}

const sortTypes = {
  type: "Type",
  name: "Last name (alphabetically)",
  newest: "Join date (newest)",
  oldest: "Join date (oldest)"
}


const MemberFilter = ({ filter, setFilter, memberTypes }) => {
  const handleChange = (e) => {
    e.persist();
    setFilter(prev => ({ ...prev, [e.target.name]: e.target.value.trimStart() }));
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setFilter(initialState.filter);
  }

  return (
    <Flex direction="column">
      <div className="form__add__header">
        <span>Filter members</span>
      </div>
      <form className="form__add" autoComplete="off">
        <Flex justifyContent="space-between" wrap="wrap">
          <div>
            <label htmlFor="memberType" className="label">Member type</label>
            <select
              value={filter.memberType} onChange={handleChange}
              name="memberType" className="input"
            >
              <option value="">All</option>
              {memberTypes.map(type =>
                <option value={type} key={type}>{type}</option>)};
            </select>
          </div>
          <div>
            <label htmlFor="name" className="label">Member name</label>
            <input
              value={filter.name} onChange={handleChange}
              name="name" className="input"
            />
          </div>
          <div>
            <label htmlFor="email" className="label">Member email</label>
            <input
              value={filter.email} onChange={handleChange}
              name="email" className="input"
            />
          </div>
          <div>
            <label htmlFor="sortBy" className="label">Sort by</label>
            <select
              value={filter.sortBy} onChange={handleChange}
              name="sortBy" className="input"
            >
              {Object.entries(sortTypes).map(([key, name]) =>
                <option value={key} key={key}>{name}</option>)}
            </select>
          </div>
        </Flex>
        <div className="form__add__buttons">
          <button className="button" onClick={resetFilter}>Reset</button>
        </div>
      </form>
    </Flex>
  );
};

export default MemberFilter;
import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import { history } from 'utils/BrowserHistoryWrapper';
import Flex from 'components/styled/Flex';

const AdminNavigation = () => {
  const logout = () => {
    localStorage.removeItem('token');
    history.push('/');
  };

  return (
    <Flex justifyContent="space-around" wrap="wrap" margin="10px 0">
      <Link to="/admin/users">Users</Link>
      <Link to="/admin/timeline">Timeline</Link>
      <Link to="/admin/members">Members</Link>
      <Link to="/admin/builders/project">Projects</Link>
      <Link to="/admin/builders/event">Events</Link>
      <Link to="/admin/trusted-partners">Trusted partners</Link>
      <Link to="/admin/library">Library</Link>
      <Link to="/admin/contact-question-info">Contact question info</Link>
      <Link to="/admin/contact-questions">Contact questions</Link>
      <Link to="/admin/batch-emails">Batch emails</Link>
      <Link to="/admin/mailing-lists">Mailing lists</Link>
      <Link to="/admin/configuration">Configuration</Link>
      <Link to="/admin/user-logs">User logs</Link>
      <button className="button-admin submit-button" onClick={logout}>
        Log out
      </button>
    </Flex>
  );
};

export default AdminNavigation;

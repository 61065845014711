import React from 'react';
import { deletePost } from 'services/post';
import './styles.css';

const PreviewPost = ({ post, setEditingId, setPosts }) => {
  const handleDeletePost = () => {
    if (window.confirm(`Are you sure you want to delete ${post.title} ${post.year}?`))
      deletePost(post.id)
        .catch(error => alert('Failed to delete a post'))
        .finally(() => setPosts());
  };

  return (
    <div className="list__post list__preview">
      <div className="preview__content">
        <span className="post__info">
          {post.title} - {post.year} - {post.quoteMember.email}
        </span>
      </div>
      <div className="user__preview__controls">
        <button className="button edit-button" onClick={() => setEditingId(post.id)}>
          Edit
        </button>
        <button className="button deactivate-button" onClick={() => handleDeletePost()}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default PreviewPost;

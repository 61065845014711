import React, { useState, useEffect } from 'react';
import Flex from 'components/styled/Flex';
import styled from 'styled-components';
import { allTrustedPartners } from '../../services/trustedPartner';

const TrustedPartnerContainer = styled.span`
  display: inline-block;
  width: 142px;
  height: 142px;
  margin: 0 30px;
  background-image: url(${({ backgroundImgUrl }) => '/images/' + backgroundImgUrl});
  background-repeat: no-repeat;
  background-position: center;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");

  :hover {
    filter: none;
  }

  @media screen and (max-width: 1000px) {
    margin: 0 10px;
    height: 100px;
  }
`;

const TrustedPartners = () => {
  const [trustedPartners, setTrustedPartners] = useState([]);

  useEffect(() => {
    allTrustedPartners().then(response => setTrustedPartners(response.data));
  }, []);

  if (trustedPartners.length === 0) return <></>;

  return (
    <Flex alignItems="center" direction="column" className="trusted-partners__section">
      <h3 className="heading--tiny margin0">Uspješne suradnje</h3>
      <Flex justifyContent="center" wrap="wrap">
        {trustedPartners.map((trustedPartner, index) => (
          <a
            className="trusted-partner"
            target="_blank"
            key={index}
            rel="noopener noreferrer"
            href={`http://${trustedPartner.linkToWebsite}`}
            aria-label={`${trustedPartner.name}`}
          >
            <TrustedPartnerContainer
              key={index}
              alt={`${trustedPartner.name}`}
              backgroundImgUrl={
                trustedPartner.name
                  .toLowerCase()
                  .split(' ')
                  .join('_') + '.png'
              }
            />
          </a>
        ))}
      </Flex>
    </Flex>
  );
};

export default TrustedPartners;

import React from 'react';

import Flex from 'components/styled/Flex';
import Project from 'components/common/Project';

const ProjectList = ({ projects, isPreview, setEditingIndex, isEdit, refreshProjects }) => {
  return (
    <Flex justifyContent="space-around" wrap="wrap" margin="10px 0">
      {projects.map((project, index) => (
        <Project
          key={project.id}
          project={project}
          isPreview={isPreview}
          index={isEdit && index}
          setEditingIndex={isEdit && setEditingIndex}
          refreshProjects={isEdit && refreshProjects}
          isEdit={isEdit}
          isFirst={index === 0}
        />
      ))}
    </Flex>
  );
};

export default ProjectList;

import React, { useState, useEffect } from 'react';
import { allConfigurations } from 'services/configuration';
import ConfigurationRow from './ConfigurationRow';
import AddConfiguration from './AddConfiguration';
import Flex from 'components/styled/Flex';
import Loading from 'components/styled/Loading';

const Configuration = () => {
  const [configurations, setConfigurations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setLoading(true);
    allConfigurations()
      .then(res => setConfigurations(res && res.data))
      .catch(() => alert('Oops, an error occurred while fetching configurations'))
      .finally(() => setLoading(false));
  }, [trigger]);

  return (
    <Flex direction="column">
      <AddConfiguration trigger={setTrigger} currentConfigurations={configurations} />
      {loading && <Loading />}
      {!loading &&
        configurations.map(configuration => (
          <ConfigurationRow key={configuration.id} configuration={configuration} trigger={setTrigger} />
        ))}
    </Flex>
  );
};

export default Configuration;

import React, { useState, useEffect } from 'react';
import { newContactQuestion, getContactQuestionInfos } from 'services/admin';
import { setConfiguration } from 'services/configuration';

import ctaImage from 'assets/visuals/internship/cta.png';
import ctaImageMobile from 'assets/visuals/internship/cta-mobile.png';

import {
  StyledContainer,
  StyledDesktopImage,
  StyledMobileImage,
  StyledAbsoluteWrapper,
  StyledFormContainer,
  StyledTitle,
  StyledSubtitle,
  StyledButton,
  StyledEmailInput,
  StyledMessage,
} from './index.styled';
import { StyledSpacing } from '../index.styled';

const SignUpCta = ({ internshipUrl }) => {
  const [areInputsDisabled, setAreInputsDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [internshipContactQuestionId, setInternshipContactQuestionId] = useState('');
  const [isInternshipQuestionActive, setIsInternshipQuestionActive] = useState(false);

  useEffect(() => {
    setConfiguration('internshipContactQuestionId', setInternshipContactQuestionId);
  }, []);

  useEffect(() => {
    if (internshipContactQuestionId) {
      getContactQuestionInfos().then(({ data }) => {
        const internshipQuestion = data.find(question => question.id === internshipContactQuestionId);
        setIsInternshipQuestionActive(
          Boolean(internshipQuestion && internshipQuestion.isActive && internshipQuestion.doesHaveNewsletterOption),
        );
      });
    }
  }, [internshipContactQuestionId]);

  const onClick = e => {
    e.preventDefault();
    setConfirmation('');
    setError('');

    if (!email || !e.target.checkValidity()) {
      setError('Nevažeća email adresa!');

      return;
    }

    setAreInputsDisabled(true);
    setConfirmation('Zahtjev se šalje...');

    const contactQuestion = {
      senderMail: email,
      senderName: 'AUTOMATED',
      content: '',
      newsletterAgreed: true,
      contactQuestionInfoId: internshipContactQuestionId,
    };

    newContactQuestion(contactQuestion)
      .then(() => {
        setEmail('');
        setConfirmation('Vaša prijava je uspješno zabilježena.');
        setTimeout(() => setConfirmation(''), 2000);
        setAreInputsDisabled(false);
      })
      .catch(() => {
        setConfirmation('');
        setError('Dogodila se greška :( Molimo pokušajte ponovo.');
        setTimeout(() => setError(''), 5000);
        setAreInputsDisabled(false);
      });
  };

  return (
    <StyledContainer id="sign-up-cta">
      <StyledDesktopImage src={ctaImage} alt="Prijavi se" />
      <StyledMobileImage src={ctaImageMobile} alt="Prijavi se" />
      <StyledAbsoluteWrapper>
        <StyledSpacing>
          <StyledFormContainer>
            {internshipUrl ? (
              <>
                <StyledTitle>Prijavi se</StyledTitle>
                <StyledSubtitle>
                  Sve što ti je preostalo je prijaviti se, ispuniti formu i mi ćemo te kontaktirati s daljnjim uputama.
                </StyledSubtitle>
                <a href={internshipUrl} target="_blank" rel="noopener noreferrer">
                  <StyledButton>Pridruži nam se</StyledButton>
                </a>
              </>
            ) : (
              <>
                <StyledTitle>Prijave nisu otvorene</StyledTitle>
                {isInternshipQuestionActive ? (
                  <>
                    <StyledSubtitle>
                      Možeš zapisati svoj e-mail ovdje ako želiš da te obavijestimo o otvaranju prijava za idući
                      Internship.
                    </StyledSubtitle>
                    <StyledEmailInput
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      disabled={areInputsDisabled}
                      aria-label="Email za Internship"
                    />
                    <StyledButton disabled={areInputsDisabled} onClick={onClick}>
                      Pošalji
                    </StyledButton>
                  </>
                ) : (
                  <StyledSubtitle>
                    Prati nas na društvenim mrežama oko novosti o novom izdanju Internshipa.
                  </StyledSubtitle>
                )}
              </>
            )}
            {error && <StyledMessage>{error}</StyledMessage>}
            {confirmation && <StyledMessage>{confirmation}</StyledMessage>}
          </StyledFormContainer>
        </StyledSpacing>
      </StyledAbsoluteWrapper>
    </StyledContainer>
  );
};

export default SignUpCta;

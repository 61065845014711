import styled from 'styled-components';

export const StyledWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledContainer = styled.div`
  margin-left: 112px;
  margin-right: 112px;
  padding-bottom: 256px;

  @media screen and (max-width: 1440px) {
    margin-left: 64px;
    margin-right: 64px;
  }

  @media screen and (max-width: 1250px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 767px) {
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 144px;
  }
`;

export const StyledSubtitle = styled.h4`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  color: white;
  margin-top: 0px;
  margin-bottom: 4px;
`;

export const StyledTitle = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 56px;
  line-height: 60px;
  color: white;
  margin-top: 0px;
  margin-bottom: 40px;
`;

export const StyledCardsListWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledCardWrapper = styled.button`
  background-color: unset;
  border: unset;
  position: relative;
  padding: 56px 0 56px 34px;
  width: 386px;
  margin-right: 12px;
  height: 156px;
  text-align: left;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-top: 32px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    height: 127px;
    padding: 0 0 20px 20px;
  }
  
  &:hover {
    transition: transform 125ms ease;
    transform: scale(1.02);
  }
`;

export const StyledCardLabel = styled.h4`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  color: white;
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (max-width: 767px) {
    font-size: 28px;
  }
`;

export const StyledCardImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  z-index: -1;
  border-radius: 16px;
  height: auto;

  @media (max-width: 500px) {
    height: 100%;
  }
`;

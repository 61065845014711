import React, { useState, useMemo, useCallback } from 'react';
import { useParams, Link, Prompt, Redirect } from 'react-router-dom';
import '../styles.css';

// import { getAllProjectSections, saveAllProjectSections } from 'services/admin';
import { saveAllProjectSections, getProject } from 'services/admin';
import { serializeProjectSection, deserializeProjectSection } from 'services/projectSectionLogic';

import Flex from 'components/styled/Flex';
import ProjectSectionsBuildingContainer from './ProjectSectionsBuildingContainer';
import ProjectSectionCreator from './ProjectSectionCreator';
import ProjectSection from 'services/projectSectionLogic';

const ProjectSectionsBuilder = () => {
  const { projectId } = useParams();
  const [projectSections, _setProjectSections] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [redirect, setReditect] = useState(false);
  const [addedIndex, setAddedIndex] = useState(undefined);

  const setProjectSections = useCallback(value => {
    setHasChanges(true);
    _setProjectSections(value);
  }, []);

  const itemDroppedCallback = item => {
    const currentOrder = projectSections.length;
    const newSection = new ProjectSection(currentOrder, item.sectionType, projectId);
    setProjectSections([...projectSections, newSection]);
    setAddedIndex(currentOrder);
  };

  const refreshProjectSections = useCallback(projectId => {
    getProject({ projectId }).then(({ data: { projectSections } }) => {
      const deserliazedProjectSections = projectSections.map(projectSection => {
        deserializeProjectSection(projectSection);
        return projectSection;
      });
      _setProjectSections([...deserliazedProjectSections]);
    });
  }, []);

  useMemo(() => {
    refreshProjectSections(projectId);
  }, [projectId, refreshProjectSections]);

  const runSave = () => {
    const anyProjectWithoutTitle = projectSections.filter(projectSection => projectSection.title.length === 0)[0];
    if (anyProjectWithoutTitle) {
      window.alert('All project sections need to have a title');
      return;
    }
    const serializedProjectSections = [...projectSections].map((section, index) => {
      serializeProjectSection(section);
      section.sectionOrder = index;
      return section;
    });
    saveAllProjectSections({ projectSections: serializedProjectSections, projectId }).then(() => {
      window.alert('Case study sections successfully saved');
      setReditect(true);
      setHasChanges(false);
    });
  };

  if (redirect) {
    return <Redirect to="../" />;
  }

  return (
    <div>
      <Prompt when={hasChanges} message="Are you sure you want to leave? You have unsaved progress" />
      <Flex justifyContent="space-around" wrap="wrap" margin="10px 0">
        <button className="button-admin submit-button" onClick={runSave}>
          Save
        </button>
        <Link rel="noopener" to="/admin/builders/project">
          <button className="button-admin cancel-button">Back to Project Builder</button>
        </Link>
      </Flex>
      <Flex justifyContent="space-between" wrap="no-wrap" direction={'row-reverse'}>
        <ProjectSectionCreator itemDroppedCallback={itemDroppedCallback} />
        <ProjectSectionsBuildingContainer
          projectSections={projectSections}
          setProjectSections={setProjectSections}
          addedIndex={addedIndex}
          setAddedIndex={setAddedIndex}
        />
      </Flex>
    </div>
  );
};

export default ProjectSectionsBuilder;

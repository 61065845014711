import React, { useEffect, useState } from 'react';
import 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getNewsletterPreferences, setNewsletterPreferences, getContactQuestionInfos } from 'services/admin';
import TextUnderline from 'components/styled/TextUnderline';
import illustrationIcons from 'assets/illustrations/illustration_contact-icons.svg';
import illustrationCharacters from 'assets/illustrations/illustration_contact-characters.svg';
import shapeBackground from 'assets/shapes/shape_green-contact.svg';
import './styles.css';

const NewsletterPreferences = () => {
  const params = useParams();
  const [newsletterOptions, setNewsletterOptions] = useState([]);
  const [contactQuestionInfos, setContactQuestionInfos] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [confirmation, setConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    localStorage.setItem('token', params.token);
    getContactQuestionInfos() //getting ContactQuestionInfos subscribable to newsletter
      .then(({ data }) => {
        const newContactQuestionInfos = [];
        data.forEach(contactQuestionInfo => {
          if (contactQuestionInfo.doesHaveNewsletterOption)
            newContactQuestionInfos.push(contactQuestionInfo);
        });
        setContactQuestionInfos(newContactQuestionInfos);
      })
      .then(() => {
        getNewsletterPreferences() //getting user's newsletter preferences, then removing doubles
          .then(({ data }) => {
            let newNewsletterOptions = data.map(newsletterOption => {
              return newsletterOption.contactQuestionInfoId;
            });
            newNewsletterOptions = newNewsletterOptions.filter((item, pos) => {
              return newNewsletterOptions.indexOf(item) === pos;
            });
            setNewsletterOptions(newNewsletterOptions);
          });
      });
  }, [params.token]);

  useEffect(() => {
    let newCheckedItems = contactQuestionInfos.map((item, key) => {
      return { 'contactQuestionInfoId': item.id, 'subscribed': newsletterOptions.indexOf(item.id) !== -1 };
    });
    setCheckedItems(newCheckedItems);
  }, [contactQuestionInfos, newsletterOptions]);

  const handleCheckedItem = event => {
    const targetButton = event.target;
    let newCheckedItems = [...checkedItems];
    newCheckedItems[targetButton.name].subscribed = targetButton.checked;
    setCheckedItems(newCheckedItems);
  }

  const submitChanges = () => {
    setConfirmation('Poruka se šalje, molim pričekajte...');
    setNewsletterPreferences(checkedItems).then(() => {
      setConfirmation('Postavke newslettera su uspješno spremljene!');
      setTimeout(() => setConfirmation(''), 5000);
    })
    .catch(() => {
      setConfirmation('');
      setErrorMessage('Nažalost, došlo je do greške :(');
      setTimeout(() => setErrorMessage(''), 2000);
    })
  }

  return <div className="newsletter-preferences-container">
    <span className="newsletter-preferences-title heading--normal"><TextUnderline>Vaše pretplate</TextUnderline></span>
    <div className="newsletter-preferences">
      { checkedItems.length > 0 && contactQuestionInfos.map((item, key) =>
        <div className="contact-form__newsletter newsletter-preference" key={key}>
          <label className="newsletter__checkbox-container">
            <input className="checkbox__input" type="checkbox"
              name={key}
              id={item.id}
              onChange={handleCheckedItem}
              checked={checkedItems[key] && checkedItems[key].subscribed}
              value={checkedItems[key] && checkedItems[key].subscribed} />
            <span className="checkbox__style contact-checkbox__style">
            </span>
            <div>{item.displayText}</div>
          </label>
        </div>
      )}
    </div>
    <div className="contact__illustration newsletter-contact__illustration">
      <img className="contact__illustration__icons" src={illustrationIcons} alt="illustration icons" />
      <img className="contact__illustration__characters" src={illustrationCharacters} alt="illustration characters" />
      <img className="contact__illustration__background" src={shapeBackground} alt="illustration background" />
    </div>
    <button className="button newsletter-preference__button" onClick={submitChanges} >Potvrdi</button>
    {errorMessage && <p className=" newsletter-preference__error heading--tiny text--error">{errorMessage}</p>}
    {confirmation && <p className=" newsletter-preference__confirmation heading--tiny text--confirmation">{confirmation}</p>}
  </div>;
}

export default NewsletterPreferences;


import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { caseStudyEventPush } from 'services/gtm';
import './styles.css';

import ProjectDetailsDisplay from 'components/common/ProjectDetailsDisplay';

import { getProject } from 'services/admin';
import { deserializeProjectSection } from 'services/projectSectionLogic';
import CountersWithContactForm from 'components/common/CountersWithContactForm';
import ProjectLoading from 'components/common/Loading/Loading.js';

const ProjectDetails = () => {
  let { projectId } = useParams();
  projectId = projectId.split('-')[0];
  const [projectSections, setProjectSections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProject({ projectId })
      .then(({ data: project }) => {
        const deserializedProjectSections = project.projectSections.map(projectSection => {
          deserializeProjectSection(projectSection);
          return projectSection;
        });
        setProjectSections([...deserializedProjectSections]);
        setLoading(false);

        caseStudyEventPush(project.name, projectId);
      })
      .catch(() => setLoading(false));
  }, [projectId]);

  if (!projectSections.length && !loading) {
    return <Redirect to="/not-found" />;
  }

  return (
    <div>
      {loading && <ProjectLoading />}
      <ProjectDetailsDisplay projectSections={projectSections} />
      <CountersWithContactForm />
    </div>
  );
};

export default ProjectDetails;

import { ImagePathLogic } from './imagePathLogic';
import * as dayjs from 'dayjs';

export class EventLogic {
  id;
  name;
  dateOfBeginning;
  dateOfEnd;
  shortDescription;
  targetAudience;
  theme;
  isActive;
  eventSections;
  coverImagePath;
  posterImagePath;

  constructor(
    id,
    name,
    dateOfBeginning,
    dateOfEnd,
    targetAudience,
    theme
  ) {
    this.id = id ? id : undefined;
    this.name = name ? name : '';
    this.dateOfBeginning = dayjs(new Date()).format('YYYY-MM-DD');
    this.dateOfEnd = dayjs(new Date()).format('YYYY-MM-DD');
    this.shortDescription = 'Opiši što će se raditi';
    this.targetAudience = targetAudience ? targetAudience : 'Everyone';
    this.theme = '';
    this.isActive = false;
    this.eventSections = [];
    this.coverImagePath = new ImagePathLogic().imagePath;
    this.posterImagePath = new ImagePathLogic().imagePath;
  }
}

export const deserializeEvent = event => {
  event.dateOfBeginning = dayjs(new Date(event.dateOfBeginning)).format('YYYY-MM-DD');
  event.dateOfEnd = dayjs(new Date(event.dateOfEnd)).format('YYYY-MM-DD');
}
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 216px;
  margin-right: 112px;

  @media screen and (max-width: 1440px) {
    margin-left: 112px;
    margin-right: 64px;
  }

  @media screen and (max-width: 1250px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-left: unset;
    margin-right: unset;
  }
`;

export const StyledImage = styled.img`
  border-radius: 32px;

  @media screen and (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    border-radius: unset;
    width: 100%;
  }
`;

export const StyledTextWrapper = styled.div`
  max-width: 420px;
  margin-right: 20px;

  @media (max-width: 767px) {
    max-width: 380px;
    margin-bottom: 56px;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledTitle = styled.h3`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 56px;
  line-height: 60px;
  color: white;
  margin-top: 0;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const StyledDescription = styled.p`
  font-family: 'Fuse V.2 Display';
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-top: 0;
  margin-bottom: 56px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
`;

export const StyledTagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledTag = styled.span`
  font-family: 'Fuse V.2 Display';
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: white;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-bottom: 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 20px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

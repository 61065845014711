import React, { useEffect, useRef, useState } from 'react';
import { FieldLabel, ProgressBarDot } from './index.styled';
import multiImageOne from 'assets/visuals/internship/multi-image-1.png';
import multiImageTwo from 'assets/visuals/internship/multi-image-2.png';
import multiImageThree from 'assets/visuals/internship/multi-image-3.png';
import multiImageFour from 'assets/visuals/internship/multi-image-4.png';
import multiImageOneMobile from 'assets/visuals/internship/multi-image-1-mobile.png';
import multiImageTwoMobile from 'assets/visuals/internship/multi-image-2-mobile.png';
import multiImageThreeMobile from 'assets/visuals/internship/multi-image-3-mobile.png';
import multiImageFourMobile from 'assets/visuals/internship/multi-image-4-mobile.png';
import './styles.css';
import { StyledSpacing } from '../index.styled';

const FIRST_CHECKPOINT_OFFSET = 0;
const SECOND_CHECKPOINT_OFFSET = 800;
const THIRD_CHECKPOINT_OFFSET = 1600;
const FOURTH_CHECKPOINT_OFFSET = 2400;

const Perks = () => {
  const containerRef = useRef();
  const [activeHeight, setActiveHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const top = containerRef.current.offsetTop;
      const firstCheckpointTrigger = top - 200;
      const secondCheckpointTrigger = top + 600;
      const thirdCheckpointTrigger = top + 1400;

      if (window.scrollY < firstCheckpointTrigger) {
        setActiveHeight(FIRST_CHECKPOINT_OFFSET);
      } else if (window.scrollY > firstCheckpointTrigger && window.scrollY < secondCheckpointTrigger) {
        setActiveHeight(SECOND_CHECKPOINT_OFFSET);
      } else if (window.scrollY > secondCheckpointTrigger && window.scrollY < thirdCheckpointTrigger) {
        setActiveHeight(THIRD_CHECKPOINT_OFFSET);
      } else if (window.scrollY > thirdCheckpointTrigger) {
        setActiveHeight(FOURTH_CHECKPOINT_OFFSET);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <StyledSpacing>
      <div className="perks__title">
        Koji smo đir?
      </div>
      <div className="perks__container">
        <div className="perks__progress-bar__wrapper">
          <div ref={containerRef} className="perks__progress-bar__container">
            <div className="perks__progress-bar" style={{ height: `${activeHeight}px` }} />
            <ProgressBarDot
              top={FIRST_CHECKPOINT_OFFSET}
              color={activeHeight >= FIRST_CHECKPOINT_OFFSET ? 'rgba(92, 194, 160, 0.8)' : '#F0F0F0'}
            />
            <ProgressBarDot
              top={SECOND_CHECKPOINT_OFFSET}
              color={activeHeight >= SECOND_CHECKPOINT_OFFSET ? 'rgba(92, 194, 160, 0.8)' : '#F0F0F0'}
            />
            <ProgressBarDot
              top={THIRD_CHECKPOINT_OFFSET}
              color={activeHeight >= THIRD_CHECKPOINT_OFFSET ? 'rgba(92, 194, 160, 0.8)' : '#F0F0F0'}
            />
            <ProgressBarDot
              top={FOURTH_CHECKPOINT_OFFSET}
              color={activeHeight >= FOURTH_CHECKPOINT_OFFSET ? 'rgba(92, 194, 160, 0.8)' : '#F0F0F0'}
            />
          </div>
        </div>
        <div className="perks__first-section-text">
          <h3>4 područja</h3>
          <p>
            Nemoj da te naše ime zavara; Iako smo započeli kao isključivo programeri, danas se Udruga sastoji od četiri
            osnovna područja.
          </p>
          <p>
            Prijavom na Internship odabireš područje (ili više njih) o kojem želiš učiti i kojim se želiš bavit u
            Udruzi.
          </p>
          <div className="perks__label-container">
            <FieldLabel backgroundColor="rgba(68, 199, 155, 0.16)" color="#44C79B">
              Multimedija
            </FieldLabel>
            <FieldLabel backgroundColor="rgba(109, 165, 227, 0.16)" color="#6DA5E3">
              Dizajn
            </FieldLabel>
            <FieldLabel backgroundColor="rgba(242, 159, 104, 0.16)" color="#F29F68">
              Marketing
            </FieldLabel>
            <FieldLabel backgroundColor="rgba(176, 135, 237, 0.16)" color="#B087ED">
              Programiranje
            </FieldLabel>
          </div>
        </div>
        <div className="perks__first-section-picture">
          <img src={multiImageOne} className="perks__image" alt="podrucja" />
          <img src={multiImageOneMobile} className="perks__image-mobile" alt="podrucja" />
        </div>
        <div className="perks__second-section-text">
          <h3>Predavanja i radionice</h3>
          <p>
            DUMP Internship se sastoji od predavanja i radionica na kojima obrađujemo teme specifične za područja te
            buduće članove obučavamo za daljnji rad u Udruzi.
          </p>
          <p>
            Želimo ti prenijeti što više znanja iz odabranog područja. Zato ćemo se pobrinuti da razumiješ osnove i
            upoznati ćemo te s najčešće korištenim tehnologijama i alatima te popularnim trendovima.
          </p>
        </div>
        <div className="perks__second-section-picture">
          <img src={multiImageTwo} className="perks__image" alt="predavanja" />
          <img src={multiImageTwoMobile} className="perks__image-mobile" alt="predavanja" />
        </div>
        <div className="perks__third-section-text">
          <h3>Praktično znanje</h3>
          <p>
            Nakon svake završene cjeline, imat ćeš priliku pokazati svoje novo znanje i vještine, ali i demonstirati
            volju i predanost.
          </p>
          <p>
            Surađivat ćeš s ostalim sudionicima na brojnim projektima. U samom finalu DUMP Internshipa tu je i interno
            natjecanje u kojem, sa sudionicima iz ostalih područja, stvaraš pravi projekt.
          </p>
        </div>
        <div className="perks__third-section-picture">
          <img src={multiImageThree} className="perks__image" alt="praktično znanje" />
          <img src={multiImageThreeMobile} className="perks__image-mobile" alt="praktično znanje" />
        </div>
        <div className="perks__fourth-section-text">
          <h3>Druženja</h3>
          <p>
            Iako ćemo puno vremena provesti učeći, uvijek pronađemo vremena i za druženje. Već od početka Internshipa,
            stvorit ćeš vrhunska poznanstva, ali i dugotrajna prijateljstva.
          </p>
        </div>
        <div className="perks__fourth-section-picture">
          <img src={multiImageFour} className="perks__image" alt="teambuilding" />
          <img src={multiImageFourMobile} className="perks__image-mobile" alt="teambuilding" />
        </div>
      </div>
    </StyledSpacing>
  );
};

export default Perks;

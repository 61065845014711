import React, { useState } from 'react';
import './styles.css';
import { parseActivity } from 'utils/ActivityParser';
import { setIsUserActive } from 'services/admin';
import Loading from 'components/styled/Loading';

const PreviewUser = ({ user, edit }) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(user.isActive);

  const setUserIsActive = () => {
    if (!window.confirm(`Do you really want to ${isActive ? 'Deactivate' : 'Activate'} user ${user.email}`)) {
      return;
    }

    setLoading(true);
    setIsUserActive({ id: user.id, isActive: !isActive })
      .then(() => {
        setIsActive(prev => !prev);
      })
      .catch(() => {
        window.alert('Oops, an error occurred');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="user__preview__container">
      {loading && <Loading />}
      {!loading && (
        <div className="user__preview">
          <span className="user__preview__email">{user.email}</span>
          <div className="user__preview__controls">
            <button className={`button ${parseActivity(isActive)}-button`} value={!isActive} onClick={setUserIsActive}>
              {parseActivity(isActive)}
            </button>
            <button className="button edit-button" onClick={() => edit(user.id)}>
              Edit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewUser;
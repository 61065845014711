import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import TextUnderline from 'components/styled/TextUnderline';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1 className="heading--big">
        <TextUnderline color="#282d30" underlineColor="#5cc2a0cc">
          Oops
        </TextUnderline>
        !
      </h1>
      <h2 className="heading--big">Tražena stranica ne postoji</h2>
      <div>
        <Link rel="noopener" to="/" className="contact-button">
          Vrati se
        </Link>
      </div>
    </div>
  );
};

export default NotFound;

import React from 'react';
import './styles.css';

import Component from './Component';
import illustrationIcons from 'assets/illustrations/illustration_contact-icons.svg';
import illustrationCharacters from 'assets/illustrations/illustration_contact-characters.svg';
import shapeBackground from 'assets/shapes/shape_green-contact.svg';
import logo from 'assets/icons/dump.svg';

const Modal = props => {
  return (
    <div className="contact__modal">
      <div className="contact__modal--top-section">
        <img className="contact__modal--logo" alt="dump" src={logo} />
        <div className="contact__modal--hamburger" onClick={props.onModalToggle}>
          <div className="bar-1 bar-1-animation"></div>
          <div className="bar-2 bar-2-animation"></div>
        </div>
      </div>
      <div className="contact__modal--form-wrapper">
        <Component dropdown />
      </div>
      <div className="contact__modal--illustration-wrapper">
        <div className="contact__illustration">
          <img className="contact__illustration__icons" src={illustrationIcons} alt="illustration icons" />
          <img
            className="contact__illustration__characters"
            src={illustrationCharacters}
            alt="illustration characters"
          />
          <img className="contact__illustration__background" src={shapeBackground} alt="illustration background" />
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React, { useState } from 'react';

import Feature from './Feature';

import {
  StyledWrapper,
  StyledContainer,
  StyledTitle,
  StyledContentWrapper,
  StyledFeaturesListWrapper,
  StyledImage,
} from './index.styled';

const HowItLooksSection = ({ title, features, imageSrc }) => {
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);

  const onFeatureClick = index => () => {
    setActiveFeatureIndex(prev => {
      if (prev === index) {
        return null;
      }

      return index;
    });
  };

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledContentWrapper>
          <StyledFeaturesListWrapper>
            {features.map(({ title: featureTitle, description }, index) => (
              <div key={index}>
                <Feature
                  index={index}
                  title={featureTitle}
                  description={description}
                  isActive={index === activeFeatureIndex}
                  onClick={onFeatureClick(index)}
                />
              </div>
            ))}
          </StyledFeaturesListWrapper>
          <StyledImage src={imageSrc} alt={title} width={592} height={581} />
        </StyledContentWrapper>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default HowItLooksSection;

export const filterEventsByName = (events, name) => {
  if (events) {
    const filteredEvents = events.filter(event => {
      return event.name.toLowerCase().includes(name);
    });
    return filteredEvents;
  }

  return null;
};

import styled from 'styled-components';

const ToolbarMenuItemStyled = styled.div`
  outline: 0;
  border: none;
  background-color: unset;
  margin-left: 5px;
  display: flex;
  align-self: center;
  border-radius: 2px;
  position: relative;
  padding: 2px;

  ${({ active }) => active && `background-color: #F3F6F8`}
`;

export default ToolbarMenuItemStyled;

import { useLayoutEffect, useState } from 'react';

export const mediaLessThan = width => {
  return window.matchMedia(`(max-width: ${width}px)`).matches;
};

export const mediaGreaterThan = width => {
  return window.matchMedia(`(min-width: ${width}px)`).matches;
};

export const mediaHeightLessThan = height => {
  return window.matchMedia(`(max-height: ${height}px)`).matches;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};

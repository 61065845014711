import Nino from 'assets/visuals/nino.png';
import NinoHover from 'assets/visuals/nino-hover.png';
import Lucia from 'assets/visuals/lucia.png';
import LuciaHover from 'assets/visuals/lucia-hover.png';
import Alessia from 'assets/visuals/alessia.png';
import AlessiaHover from 'assets/visuals/alessia-hover.png';
import Josip from 'assets/visuals/josip.png';
import JosipHover from 'assets/visuals/josip-hover.png';

import { Discipline } from '../consts';

export const disciplineCardsConfig = {
  [Discipline.development]: {
    label: 'Programiranje',
    image: Nino,
    hoverImage: NinoHover,
  },
  [Discipline.marketing]: {
    label: 'Marketing',
    image: Lucia,
    hoverImage: LuciaHover,
  },
  [Discipline.design]: {
    label: 'Dizajn',
    image: Alessia,
    hoverImage: AlessiaHover,
  },
  [Discipline.multimedia]: {
    label: 'Multimedija',
    image: Josip,
    hoverImage: JosipHover,
  },
};

import React from 'react';
import styled from 'styled-components';
import './styles.css';

import { ElementType } from 'constants/elementType';
import { LeafType } from 'constants/leafType';

import MarkButton from './Controls/MarkButton';
import LinkButton from './Controls/LinkButton';
import ListButton from './Controls/ListButton';
import H2Button from './Controls/H2Button';
import ColorButton from './Controls/ColorButton';
import ImageButton from './Controls/ImageTagButton';

const CharacterCounter = styled.div`
  ${({ hasExceeded }) => hasExceeded && 'color: #cb5353;'}
  margin: 0 10px 0 auto;
`;

const Toolbar = ({ isEmail, libraryAttachments, fileAttachments, isLinkDisabled, charCount, maxCharCount }) => {
  const listTypesValues = [ElementType.orderedList, ElementType.unorderedList];
  const leafTypeValues = Object.values(LeafType);

  return (
    <div className="rich-text-editor__toolbar">
      {listTypesValues.map((key, index) => (
        <ListButton key={index} format={key} />
      ))}
      <H2Button />
      {!isLinkDisabled && <LinkButton />}
      {leafTypeValues.map((key, index) => (
        <MarkButton key={index} format={key} />
      ))}
      <ColorButton />
      {isEmail && (
        <ImageButton libraryAttachments={libraryAttachments} fileAttachments={fileAttachments} isEmail={isEmail} />
      )}
      {maxCharCount && (
        <CharacterCounter hasExceeded={charCount > maxCharCount}>
          character counter: {charCount}/{maxCharCount}
        </CharacterCounter>
      )}
    </div>
  );
};

export default Toolbar;

import React from 'react';
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from 'react-transition-group';

import { StyledFeatureDescription, StyledArrow, StyledFeatureHeader } from './index.styled';

import ArrowDownIcon from 'assets/icons/arrow-down-white.svg';
import useIntersect from 'utils/intersect';

const Feature = ({ index: indexProp, title, description, isActive, onClick }) => {
  const [ref, entry, unsubscribe] = useIntersect({ threshold: 1 });
  const { isIntersecting } = entry;

  if (isIntersecting) {
    unsubscribe();
  }

  const index = indexProp + 1 < 10 ? `0${indexProp + 1}` : indexProp + 1;

  return (
    <CSSTransition in={isIntersecting} timeout={100}>
      <div ref={ref} onClick={onClick}>
        <StyledFeatureHeader>
          <h4>{index}</h4>
          <h4>{title}</h4>
          <StyledArrow src={ArrowDownIcon} isActive={isActive} alt="" />
        </StyledFeatureHeader>

        <AnimateHeight duration={200} height={!isActive ? 0 : 'auto'}>
          <StyledFeatureDescription>{description}</StyledFeatureDescription>
        </AnimateHeight>
      </div>
    </CSSTransition>
  );
};

export default Feature;

import React from 'react';

import { base } from 'services/common';
import { deleteFileFromLibrary } from 'services/admin';

const ImagePreview = ({ name, images, setAllImages }) => {
  const deleteFile = () => {
    if (window.confirm(`Are you sure you want to delete picture ${name} from library`))
      deleteFileFromLibrary(name).then(() => {
        setAllImages(images.filter(image => image !== name));
      });
  };

  return (
    <div className="image-preview">
      <button className="button-admin cancel-button" onClick={() => deleteFile(name)}>
        Delete image
      </button>
      <img src={`${base}\\images\\library\\${name}`} alt={name} />
      <div>{name}</div>
    </div>
  );
};

export default ImagePreview;
